import { RESET_VENDOR } from "./vendor_reducer";

export const SET_SELECTED_BID = "SET_SELECTED_BID";
export const REFRESH_SELECTED_BID = "REFRESH_SELECTED_BID";
export const CLEAR_SELECTED_BID = "CLEAR_SELECTED_BID";

const selectedBidReducer = (state = null, action) => {
  switch (action.type) {
    case SET_SELECTED_BID:
      return {
        ...state,
        ...action.payload,
      };
    case REFRESH_SELECTED_BID:
      return action.payload;
    case CLEAR_SELECTED_BID:
      return null;
    case RESET_VENDOR:
      return null;
    default:
      return state;
  }
};

export default selectedBidReducer;
