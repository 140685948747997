import PropTypes from "prop-types";
import { SIZE_HEIGHT_TABLE_ROW } from "@capterra/arubaito/dist/variables.js";
import { LoadingOverlay } from "~/components";
import s from "./TableLoader.styles.ts";
import { useTranslation } from "~/utils";

const TableLoader = ({
  isError = false,
  notFound,
  errorMessage = "",
  rowLength = 1,
  colSpan = 1,
  ...props
}) => {
  const rowCount = isError ? 2 : rowLength || 1;
  const { t } = useTranslation();
  return (
    <tr className={s["table-loader-row"]} {...props}>
      <td className="table-loader-column" colSpan={colSpan}>
        <div
          className="gdm-relative"
          style={{ height: `${rowCount * SIZE_HEIGHT_TABLE_ROW}px` }}
        >
          {isError ? (
            <div className={s["error-message"]}>
              <span className="gdm-label">
                {errorMessage || t("PPL_BIDDING_TABLE-ERROR_MESSAGE")}
              </span>
            </div>
          ) : notFound ? (
            <div className={s["error-message"]}>
              <span className="gdm-label">
                {t("PPL_BIDDING_TABLE-NO_PRODUCT")}
              </span>
            </div>
          ) : (
            <LoadingOverlay fullPage={false} />
          )}
        </div>
      </td>
    </tr>
  );
};

TableLoader.propTypes = {
  rowLength: PropTypes.number,
  colSpan: PropTypes.number,
  isError: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default TableLoader;
