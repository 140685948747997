import { authAxios as axios } from "~/utils";

export const updatePpcAccountSettings = async (body, method = "patch") => {
  try {
    const response = await axios[method]("/vp/api/ppc/account_settings", body);
    const settings = response.data || {};
    return settings;
  } catch (err) {
    return [400, 422].includes(err.response?.status) ? err.response.data : null;
  }
};
