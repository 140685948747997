const defaultLocale = "en-us";
const defaultOption = { day: "2-digit", month: "2-digit", year: "2-digit" };

export const formatDate = (
  date,
  locale = defaultLocale,
  option = defaultOption,
) => {
  try {
    return (
      new Intl.DateTimeFormat(locale, option).format(new Date(date || "")) ||
      "-"
    );
  } catch (err) {
    return "-";
  }
};
