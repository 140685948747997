import { RESET_VENDOR } from "./vendor_reducer";

export const SET_SELECTED_PRODUCT_FILTERS = "SET_SELECTED_PRODUCT_FILTERS";

const selectedProductFiltersReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_SELECTED_PRODUCT_FILTERS:
      return action.payload;
    case RESET_VENDOR:
      return {};
    default:
      return state;
  }
};

export default selectedProductFiltersReducer;
