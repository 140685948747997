import { Columns, Stack } from "@capterra/vendor-ui-components-layout";
import { TextField } from "@capterra/vendor-ui-components-textfield";
import { Anchor, Para, SubHeading } from "~/components/Text";
import { Trans, useTranslation } from "~/utils";
import { css } from "ui/css";

export const CorporateInformation = ({
  corporateIdentificationNumber,
  gstId,
  withholdingTax,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={css({
        marginBlockStart: "16px",
        paddingBlockStart: "24px",
        borderTop: "1px solid {colors.gray}",
      })}
    >
      <Stack gap="24px">
        <Stack>
          <SubHeading>
            {t("ACCOUNT-SETTINGS_CORPORATE-INFORMATION_TITLE")}
          </SubHeading>
          <Para>
            <Trans
              i18nKey="ACCOUNT-SETTINGS_CORPORATE-INFORMATION_UPDATE"
              components={{
                a: (
                  <Anchor data-tracking="ppc_payment_corporate_info_request_update" />
                ),
              }}
            />
          </Para>
        </Stack>
        <Columns gap="40px">
          <Columns.Column span="8">
            <TextField
              id="corporate_identification_number"
              label={t("ACCOUNT-SETTINGS_CORPORATE-INFORMATION_CIN-LABEL")}
              placeholder={t(
                "ACCOUNT-SETTINGS_CORPORATE-INFORMATION_CIN-PLACEHOLDER",
              )}
              tooltip={t("ACCOUNT-SETTINGS_CORPORATE-INFORMATION_CIN-TOOLTIP")}
              value={corporateIdentificationNumber}
              disabled
            />
          </Columns.Column>
          <Columns.Column span="8">
            <TextField
              id="withholding_tax"
              label={t(
                "ACCOUNT-SETTINGS_CORPORATE-INFORMATION_WITHHOLDING-TAX-LABEL",
              )}
              placeholder={t(
                "ACCOUNT-SETTINGS_CORPORATE-INFORMATION_WITHHOLDING-TAX-PLACEHOLDER",
              )}
              tooltip={t(
                "ACCOUNT-SETTINGS_CORPORATE-INFORMATION_WITHHOLDING-TAX-TOOLTIP",
              )}
              value={`${withholdingTax}%`}
              disabled
            />
          </Columns.Column>
          <Columns.Column span="8">
            <TextField
              id="gst_id"
              label={t("ACCOUNT-SETTINGS_CORPORATE-INFORMATION_GST-ID-LABEL")}
              placeholder={t(
                "ACCOUNT-SETTINGS_CORPORATE-INFORMATION_GST-ID-PLACEHOLDER",
              )}
              tooltip={t(
                "ACCOUNT-SETTINGS_CORPORATE-INFORMATION_GST-ID-TOOLTIP",
              )}
              value={gstId}
              disabled
            />
          </Columns.Column>
        </Columns>
      </Stack>
    </div>
  );
};
