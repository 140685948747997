const SvgJcb = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={70} height={50} {...props}>
    <defs>
      <linearGradient
        id="jcb_svg__a"
        x1="-.654%"
        x2="99.344%"
        y1="47.861%"
        y2="47.861%"
      >
        <stop offset="0%" stopColor="#007B40" />
        <stop offset="100%" stopColor="#55B330" />
      </linearGradient>
      <linearGradient
        id="jcb_svg__b"
        x1="-1.645%"
        x2="98.355%"
        y1="50.957%"
        y2="50.957%"
      >
        <stop offset="0%" stopColor="#1D2970" />
        <stop offset="100%" stopColor="#006DBA" />
      </linearGradient>
      <linearGradient
        id="jcb_svg__c"
        x1="-.032%"
        x2="99.968%"
        y1="48.831%"
        y2="48.831%"
      >
        <stop offset="0%" stopColor="#6E2B2F" />
        <stop offset="100%" stopColor="#E30138" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="nonzero">
      <path
        fill="#FFF"
        d="M67.901 39.905C67.901 45.48 63.383 50 57.806 50H3V10.097C3 4.52 7.518 0 13.096 0H67.9z"
      />
      <path
        fill="url(#jcb_svg__a)"
        d="M47.034 25.875c1.565.035 3.14-.069 4.698.054 1.58.295 1.96 2.685.557 3.468-.957.516-2.094.192-3.132.283h-2.123zm5.604-4.306c.347 1.228-.836 2.33-2.018 2.161h-3.586c.025-1.158-.05-2.414.036-3.51 1.437.04 2.886-.083 4.315.064.613.154 1.127.658 1.253 1.285m8.63-18.205c.067 2.345.01 4.813.029 7.205-.005 9.725.01 19.45-.008 29.175-.063 3.644-3.293 6.811-6.912 6.884-3.623.015-7.247.002-10.87.006V31.933c3.948-.02 7.898.04 11.844-.031 1.83-.115 3.836-1.323 3.921-3.338.216-2.023-1.692-3.422-3.503-3.644-.696-.018-.676-.202 0-.283 1.727-.373 3.084-2.161 2.576-3.952-.434-1.883-2.515-2.612-4.246-2.608-3.53-.024-7.061-.004-10.591-.01.022-2.745-.048-5.493.038-8.235.28-3.58 3.59-6.53 7.16-6.467z"
        transform="translate(3)"
      />
      <path
        fill="url(#jcb_svg__b)"
        d="M3.615 10.202c.09-3.638 3.334-6.78 6.949-6.833 3.61-.01 7.22-.001 10.829-.004-.01 12.174.02 24.35-.015 36.525-.14 3.594-3.348 6.675-6.923 6.739-3.616.013-7.233.002-10.85.005V31.437c3.513.83 7.197 1.183 10.78.633 2.143-.345 4.487-1.397 5.212-3.62.534-1.9.233-3.901.313-5.852v-4.531h-6.202c-.028 2.996.057 5.998-.045 8.992-.167 1.84-1.99 3.008-3.725 2.946-2.151.023-6.416-1.56-6.416-1.56-.01-5.614.063-12.646.093-18.242"
        transform="translate(3)"
      />
      <path
        fill="url(#jcb_svg__c)"
        d="M23.706 19.894c-.326.07-.065-1.112-.149-1.56.022-2.833-.046-5.67.038-8.5.279-3.594 3.616-6.553 7.199-6.47h10.551c-.01 12.176.02 24.352-.015 36.526-.139 3.595-3.348 6.675-6.923 6.74-3.616.013-7.233.001-10.85.005V29.984c2.47 2.026 5.827 2.342 8.904 2.347 2.32 0 4.627-.358 6.88-.893v-3.05c-2.54 1.265-5.524 2.069-8.338 1.342-1.964-.49-3.389-2.386-3.357-4.413-.228-2.107 1.008-4.331 3.078-4.958 2.57-.804 5.373-.189 7.783.858.516.27 1.04.605.833-.258v-2.397c-4.03-.96-8.319-1.312-12.368-.27-1.172.332-2.314.833-3.266 1.603"
        transform="translate(3)"
      />
    </g>
  </svg>
);
export default SvgJcb;
