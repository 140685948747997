import { css } from "ui/css";

const styles = {
  "footer-wrapper": css({
    display: "flex",
    justifyContent: "space-between",
    "& button": { width: "205px" },
  }),
};

export default styles;
