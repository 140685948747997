import PropTypes from "prop-types";
import {
  Button,
  Inline,
  Stack,
  Text,
  Drawer as VendorUiDrawer,
} from "~/components/vendorUI";
import { useConfirmation } from "~/context";
import { useTranslation } from "~/utils";
import { css } from "ui/css";
import { anchorCSS } from "../Text";

export const Drawer = ({
  show,
  onCancel,
  onSubmit,
  isSubmitting,
  isSaveDisabled,
  saveButtonTracking,
}) => {
  const confirm = useConfirmation();
  const { t } = useTranslation();

  const cancel = () => {
    confirm({
      open: true,
      type: "error",
    }).then(
      () => onCancel(),
      () => "",
    );
  };

  const drawerHeaderCSS = css({
    padding: "8px",
    height: "106px",
    justifyContent: "center",
  });
  return (
    <VendorUiDrawer show={show}>
      <Inline className={drawerHeaderCSS} align="center" distribute="between">
        <Inline gap="16px" align="center">
          <Text size="20px">{t("DRAWER_PENDING_TITLE")}</Text>
          <Text size="14px">{t("DRAWER_PENDING_DESCRIPTION")}</Text>
        </Inline>

        <Stack align="center">
          <Button
            variant="primary"
            onClick={onSubmit}
            disabled={isSaveDisabled || isSubmitting}
            isLoading={isSubmitting}
            data-tracking={saveButtonTracking}
            data-testid={isSubmitting ? "submitting-btn" : "save-btn"}
          >
            {t("DRAWER_ACTION_SAVE")}
          </Button>

          <button
            type="submit"
            onClick={cancel}
            className={anchorCSS({ variant: "inverted" })}
            disabled={isSubmitting}
          >
            {t("DRAWER_ACTION_CANCEL")}
          </button>
        </Stack>
      </Inline>
    </VendorUiDrawer>
  );
};

Drawer.propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isSaveDisabled: PropTypes.bool,
};
