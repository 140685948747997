import { useEffect, useState } from "react";
import { getStripeCard } from "../../../actions/get_stripe_card";

function useGetStripeCard() {
  const [card, setCard] = useState(null);
  const [isLoadingCard, setIsLoadingCard] = useState(true);

  useEffect(() => {
    getStripeCard()
      .then(({ stripe_card }) => {
        setCard(stripe_card);
      })
      .catch(() => {
        setCard(null);
      })
      .finally(() => {
        setIsLoadingCard(false);
      });
  }, []);

  return [card, setCard, isLoadingCard];
}

export { useGetStripeCard };
