import PropTypes from "prop-types";
import cx from "classnames";
import { Modal } from "@capterra/arubaito";
import { useTranslation } from "~/utils";
import { css, cva } from "ui/css";
import { Stack, Button, Inline } from "~/components/vendorUI";

const modalCSS = cva({
  base: { border: "3px solid #ffffff" },
  variants: {
    type: {
      error: { border: "3px solid #de0a01" },
      notification: { border: "3px solid #f5ab23" },
      success: { border: "3px solid #00a76d" },
    },
  },
});

export const ConfirmationModal = ({
  onAccept = () => {},
  onDecline = () => {},
  onClosed,
  title,
  description,
  declineBtn,
  acceptBtn,
  type = "",
  hideSecondaryBtn = false,
  hideCloseText = false,
}) => {
  const { t } = useTranslation();
  const handleClose = (close) => {
    close();
    onDecline();
  };

  const handleAccept = (close) => {
    close();
    onAccept();
  };

  return (
    <Modal open onClosed={onClosed}>
      {(close) => (
        <div className={modalCSS({ type })}>
          <Modal.Header>
            {type ? (
              <span
                className={cx(
                  "gdm-m-right-sm gdm-icon gdm-icon-lg gdm-align-bottom",
                  {
                    "gdm-icon-alert-error": type === "error",
                    "gdm-icon-alert-notification": type === "notification",
                    "gdm-icon-alert-success": type === "success",
                  },
                )}
              />
            ) : null}
            <h2 className="gdm-subtitle gdm-inline-block gdm-m-none gdm-p-right-sm">
              {title || t("CONFIRMATION-MODAL_TITLE")}
            </h2>
            <Modal.Close
              {...(hideCloseText && { children: "" })}
              onClick={() => handleClose(close)}
            />
          </Modal.Header>
          <Modal.Body>
            <p className="gdm-paragraph-sm">
              {description || t("CONFIRMATION-MODAL_DESCRIPTION")}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Inline distribute="end" align="end">
              <Stack align="center">
                <Button variant="primary" onClick={() => handleAccept(close)}>
                  {acceptBtn || t("CONFIRMATION-MODAL_ACTION_ACCEPT")}
                </Button>
                {!hideSecondaryBtn && (
                  <button
                    type="button"
                    className="gdm-link-dark gdm-btn-override"
                    onClick={() => handleClose(close)}
                  >
                    {declineBtn || t("CONFIRMATION-MODAL_ACTION_DECLINE")}
                  </button>
                )}
              </Stack>
            </Inline>
          </Modal.Footer>
        </div>
      )}
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  onAccept: PropTypes.func,
  onDecline: PropTypes.func,
  onClosed: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  declineBtn: PropTypes.string,
  acceptBtn: PropTypes.string,
  type: PropTypes.string,
  hideSecondaryBtn: PropTypes.bool,
};

export default ConfirmationModal;
