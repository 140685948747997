import { Inline, Stack, Text } from "~/components/vendorUI";
import { useTranslation } from "~/utils/i18n";
import ICONS from "../../../assets/icons";
import { CARD_TYPES_MAPPINGS } from "../../../assets/static";
import { css } from "ui/css";

const cardIconContainerCSS = css({
  "& > svg": {
    maxHeight: "42px",
    maxWidth: "72px",
  },
});

const CurrentCreditCard = ({ card, resetCard }) => {
  const { t } = useTranslation();
  const type = card.type || card.brand;

  return (
    <Inline align="center" gap="16px" data-testid="current-credit-card">
      <div className={cardIconContainerCSS}>
        {ICONS[CARD_TYPES_MAPPINGS[type]]}
      </div>
      <Stack gap="8px">
        <Text weight="bold">
          {t("PPC-UPGRADE_CARD_ENDING_IN")} {card.last4}
        </Text>
        <Text color="secondary">
          {t(
            `ACCOUNT-SETTINGS_BILLING-FORM_DROPDOWN_CREDIT-CARD-TYPE-${type?.toUpperCase()}`,
          )}{" "}
          - {`${card.exp_month}`.padStart(2, "0")}/{card.exp_year}
        </Text>
      </Stack>
      <div>
        <a className="gdm-link-default" onClick={resetCard}>
          {t("ACCOUNT-SETTINGS_BILLING-FORM_CHECKBOX")}
        </a>
      </div>
    </Inline>
  );
};

export { CurrentCreditCard };
