import Client from "braintree-web/client";
import VaultManager from "braintree-web/vault-manager";
import { catchError } from "~/utils/catchError";

const deleteBraintreeCard = async (token, nonce) => {
  try {
    const client = await Client.create({
      authorization: token,
      vaultManager: true,
    });
    const vaultManager = await VaultManager.create({ client });
    await vaultManager.deletePaymentMethod(nonce);
  } catch (error) {
    catchError(error, { reason: "Error deleting Braintree Card" });
    return {};
  }
};

const mapAddressToBraintreeBilling = (address) => {
  if (!address) return {};

  return {
    billingAddress: {
      streetAddress: address.address,
      extendedAddress: address.address2,
      postalCode: address.zip_code,
      countryCodeAlpha2: address.country,
    },
  };
};

export const saveBraintreeCard = async (
  fieldsInstance,
  token,
  nonce = null,
  address = null,
) => {
  try {
    if (nonce) await deleteBraintreeCard(token, nonce);
    const card = await fieldsInstance.tokenize({
      vault: true,
      ...mapAddressToBraintreeBilling(address),
      options: {
        makeDefault: true,
      },
    });
    return card;
  } catch (error) {
    catchError(error, {
      reason: "Error saving Braintree Card",
      error: JSON.stringify(error),
    });
    return null;
  }
};
