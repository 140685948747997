import { useSelector } from "react-redux";
import { LoadingOverlay } from "~/components";

const withLoader = (Component) => (props) => {
  const isLoading = useSelector((state) => state.ppl.isLoading);

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <Component {...props} />
    </>
  );
};

export default withLoader;
