import { useEffect, useState } from "react";
import { Route } from "./route";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { useCurrentUser } from "../../shared/contexts/currentUserContext";
import { useCurrentCampaign } from "../../../context/CurrentCampaignContext";
import { LoadingOverlay } from "~/components";

export const PrivateRoute = ({
  component: Component,
  campaignSwitcher,
  ...rest
}) => {
  const [isLoadingRoute, setIsLoadingRoute] = useState(true);
  const { enableSwitcher, disableSwitcher } = useCurrentCampaign();
  const { user, selectedPPLCampaign, initialized } = useCurrentUser();

  useEffect(() => {
    if (initialized || user.challengeName) {
      setIsLoadingRoute(false);
    }
  }, [initialized, user.challengeName]);

  useEffect(() => {
    if (campaignSwitcher) {
      enableSwitcher(campaignSwitcher);
    } else {
      disableSwitcher();
    }
  }, [campaignSwitcher]);

  if (
    campaignSwitcher === "ppl" &&
    (!selectedPPLCampaign?.id || selectedPPLCampaign?.status === "inactive")
  )
    return <Redirect to="/404" />;

  if (isLoadingRoute) return <LoadingOverlay />;

  if (user.isLoggedIn && !user.challengeName)
    return <Route {...rest} component={Component} />;

  return <Redirect to="/login" {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.element,
};
