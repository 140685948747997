import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  Button,
  Inline,
  useToast,
  Form,
  TextField,
  Stack,
} from "~/components/vendorUI";
import { CARD_TYPES_MAPPINGS } from "../../../assets/static";
import { createStripeSubscription } from "../../../actions/create_stripe_subscription";
import { isValidEmail, isEmpty } from "~/pages/AccountSettings/utils";
import { removeStripeCard } from "../../../actions/remove_stripe_card";
import { saveStripeDefaultPaymentMethod } from "../../../actions/save_stripe_default_payment_method";
import { updatePpcAccountSettings } from "../../../actions/update_ppc_account_settings";
import { useAccountsContext } from "../../../index";
import { useTranslation } from "~/utils/i18n";
import { useValidCards, STRIPE_ELEMENT_OPTIONS } from "~/components/Stripe";
import { css } from "ui/css";
import { LEVEL, catchError } from "~/utils/catchError";
import { useUserData } from "~/context/UserData";

const fieldCSS = css({ width: "400px" });

const Col = (props) => <Inline {...props} className={css({ gap: "40px" })} />;

export const CreditCardForm = ({
  cardTypeOptions,
  setCard,
  onDisabled,
  onSubmit,
  onSubmitEnd,
  onSuccess,
  withWrapper = false,
  hideSaveButton = false,
}) => {
  const [cardholder, setCardholder] = useState({ name: "", billingEmail: "" });
  const [isSaving, setIsSaving] = useState(false);
  const [validations, setValidations] = useState({});
  const elements = useElements();
  const stripe = useStripe();

  const { addToast } = useToast();
  const { handlePPCCreditCard } = useAccountsContext();
  const { t } = useTranslation();
  const {
    campaignSummary: { ppcCampaignProfileAttributes },
  } = useUserData();
  const Wrapper = withWrapper ? Fragment : Col;
  const disabled =
    !validations.billingEmail ||
    !validations.cardholderName ||
    !validations.card;

  const validCardBrands = useValidCards(
    ppcCampaignProfileAttributes?.billingTaxCountryCode,
    cardTypeOptions,
  );

  useEffect(() => {
    onDisabled?.(disabled);
    return () => onDisabled?.(true);
  }, [disabled]);

  const isCardTypeInvalid = (type) =>
    !validCardBrands
      .map(({ value }) => value)
      .includes(CARD_TYPES_MAPPINGS[type]);

  const handleChange = (event) => {
    if (event.complete) {
      if (isCardTypeInvalid(event.brand)) {
        const cardTypes = validCardBrands.map(({ label }) => label).join(", ");

        addToast(
          `${t("ACCOUNT-SETTINGS_BILLING-FORM_ERROR_CREDIT-CARD-TYPE")}. ${t(
            "ACCOUNT-SETTINGS_BILLING-FORM_TOOLTIP_CREDIT-CARD-TYPE",
            { cardTypes },
          )}`,
          { type: "error" },
        );

        setValidations({ ...validations, card: false });
      } else {
        setValidations({ ...validations, card: true });
      }
    }
  };

  const handleCardholderNameChange = ({ target }) => {
    if (isEmpty(target.value)) {
      setValidations({ ...validations, cardholderName: false });
    } else {
      setCardholder({ ...cardholder, name: target.value });
      setValidations({ ...validations, cardholderName: true });
    }
  };

  const handleBillingEmailChange = ({ target }) => {
    if (isValidEmail(target.value)) {
      setCardholder({ ...cardholder, email: target.value });
      setValidations({ ...validations, billingEmail: true });
    } else {
      setValidations({ ...validations, billingEmail: false });
    }
  };

  const handleSaveCard = async () => {
    if (
      !validations.billingEmail ||
      !validations.cardholderName ||
      !validations.card
    ) {
      return;
    }

    setIsSaving(true);

    try {
      const card = elements.getElement("card");
      const {
        stripe_subscription: { payment_method, pending_setup_intent },
      } = await createStripeSubscription();

      if (payment_method) {
        removeStripeCard().catch((error) => {
          catchError(error, {
            reason: "Campaign had no card in stripe",
            level: LEVEL.WARNING,
          });
          catchError(error, {
            reason: "Campaign had no card in stripe",
            level: LEVEL.WARNING,
          });
        });
      }

      if (pending_setup_intent) {
        const { client_secret } = pending_setup_intent;

        const cardConfirmation = await stripe.confirmCardSetup(client_secret, {
          payment_method: {
            card,
            billing_details: {
              name: cardholder.name,
              email: cardholder.email,
            },
          },
        });

        if (cardConfirmation.error) {
          addToast(cardConfirmation.error.message, { type: "error" });
        } else {
          const { setupIntent } = cardConfirmation;
          const {
            stripe_subscription: { payment_method },
          } = await saveStripeDefaultPaymentMethod({
            paymentMethodId: setupIntent.payment_method,
          });

          await updatePpcAccountSettings({
            ppc: {
              billing_information: {
                cc_exp_date: `${payment_method.exp_year}-${payment_method.exp_month}-01`,
                cc_four_digits: payment_method.last4,
                cc_name_on_card: cardholder?.name,
                cc_type: CARD_TYPES_MAPPINGS[payment_method.brand],
              },
            },
          });

          setCard(payment_method);
          handlePPCCreditCard("paymentMethod", payment_method);
          onSuccess?.();
        }
      }
    } catch (error) {
      addToast(t("PPC-UPGRADE_ERROR_SAVING_CARD"), { type: "error" });
      catchError(error, { reason: "Error saving stripe card" });
    } finally {
      setIsSaving(false);
      onSubmitEnd?.();
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    onSubmit?.();
    handleSaveCard();
  };

  return (
    <form
      aria-label="form"
      id="stripe-form"
      onSubmit={handleOnSubmit}
      data-tracking="ppc_payment_save_credit_card"
    >
      <Stack gap="24px">
        <Wrapper>
          <TextField
            className={fieldCSS}
            required
            id="cardholderName"
            disabled={isSaving}
            label={t("ACCOUNT-SETTINGS_BILLING-FORM_LABEL_CREDIT-CARD-NAME")}
            onChange={handleCardholderNameChange}
          />
          <TextField
            className={fieldCSS}
            required
            id="billingEmail"
            disabled={isSaving}
            label={t("PPC-UPGRADE_BILLING-EMAIL")}
            onChange={handleBillingEmailChange}
            type="email"
          />
        </Wrapper>
        <Inline data-testid="credit-card-form">
          <Form.Field
            htmlFor="card_details"
            label={t("PPC-UPGRADE_CARD-DETAILS")}
            required
          >
            <CardElement
              onChange={handleChange}
              options={{ ...STRIPE_ELEMENT_OPTIONS, disabled: isSaving }}
            />
          </Form.Field>
        </Inline>
        {hideSaveButton ? null : (
          <Inline>
            <Button
              disabled={disabled}
              variant="secondary"
              size="32px"
              type="button"
              isLoading={isSaving}
              onClick={handleSaveCard}
              data-testid="save-card-button"
            >
              {t("PPC-UPGRADE_SAVE-CARD")}
            </Button>
          </Inline>
        )}
      </Stack>
    </form>
  );
};

CreditCardForm.propTypes = {
  cardTypeOptions: PropTypes.array.isRequired,
};
