import { css } from "ui/css";

const styles = {
  contentTableOverview: css({
    width: "100%",
    tableLayout: "fixed",
    borderCollapse: "collapse",
    "& col.current": { background: "{colors.white/8}" },
    "& thead": {
      borderBottom: "2px solid #fff",
      "& tr:nth-child(2)": {
        "& th": {
          textAlign: "center",
          paddingTop: "0px",
          paddingBottom: "0px",
        },
      },
      "& tr:nth-child(3)": {
        "& th:nth-child(1)": { textAlign: "left", paddingLeft: "0px" },
      },
    },
    "& th:first-child": { textAlign: "left", width: "40%" },
    "& tbody tr": { borderBottom: "1px solid #5a95b7" },
    "& th,& td": {
      textAlign: "center",
      position: "relative",
      padding: "12px 24px",
      fontWeight: "normal",
      "&.strong": { fontWeight: "bold" },
    },
    "& tbody": {
      "& th": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingLeft: "32px",
        transition: "padding-left 0.2s",
        "& button.remove": {
          position: "absolute",
          top: "0",
          bottom: "0",
          left: "0",
          transform: "translateX(calc(-100% - 1px))",
          padding: "0 12px 0 32px",
          backgroundColor: "red.6",
          fontSize: "1em",
          color: "white",
          border: "0",
          cursor: "pointer",
          transition: "transform 0.2s",
        },
        "&:hover": {
          paddingLeft: "115px",
          "& button.remove": { transform: "translateX(0%)" },
        },
        "& .icon-t": {
          position: "absolute",
          left: "8px",
          zIndex: 1,
          pointerEvents: "none",
          opacity: 0.8,
        },
      },
    },
  }),
};

export default styles;
