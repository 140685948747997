import { SET_VENDOR } from "~/pages/PPL/reducers/vendor_reducer";

export default function setVendor(vendor) {
  return (dispatch) => {
    dispatch({
      type: SET_VENDOR,
      payload: vendor,
    });
  };
}
