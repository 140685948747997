import cx from "classnames";
import PropTypes from "prop-types";
import { Modal } from "@capterra/arubaito";
import { Button } from "~/components/vendorUI";
import s from "../connected/pre_purchase.module.scss";
import { useTranslation } from "~/utils";

const RemoveModal = ({ isOpen, body = <></>, isError, handleClose }) => {
  const { t } = useTranslation();

  return (
    <Modal open={isOpen} onClosed={handleClose}>
      {(close) => (
        <div className={cx(s.modal, { [s.error]: isError })}>
          <Modal.Header>
            <h2
              className={cx(
                isError ? "gdm-subtitle" : "gdm-heading-lg",
                s.header,
              )}
            >
              {isError ? (
                <>
                  <span className="gdm-icon gdm-icon-alert-error gdm-icon-md gdm-align-bottom gdm-m-right-xs" />
                  <span>{t("PPL_EXPANSION-LEADS_MODAL_REMOVE-TITLE")}</span>
                </>
              ) : (
                <span>{t("PPL_EXPANSION-LEADS_PAGE_TITLE")}</span>
              )}
            </h2>
          </Modal.Header>
          <Modal.Body>
            <p className="gdm-paragraph-sm">{body}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="gdm-m-left-sm gdm-m-top-sm"
              variant="primary"
              onClick={close}
            >
              {t("ACTION_CLOSE")}
            </Button>
          </Modal.Footer>
        </div>
      )}
    </Modal>
  );
};

RemoveModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  body: PropTypes.node,
};

export default RemoveModal;
