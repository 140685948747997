import { useRef } from "react";
import { getGlobalObject } from "../utils/getGlobalObject";

export const useRum = (props: any) => {
  useRef(
    (() => {
      if (!props?.component && !props?.children && !props?.render) {
        return;
      }

      const globalObj = getGlobalObject<Window>();

      // @ts-expect-error datadog config
      if (!globalObj.DD_RUM) {
        console.warn(
          "@datadog/rum-react-integration: Datadog RUM SDK is not initialized.",
        );
        return;
      }

      // @ts-expect-error datadog config
      if (!globalObj.DD_RUM?.startView) {
        console.warn(
          "@datadog/rum-react-integration: Manual tracking not supported. Try updating the Datadog RUM SDK.",
        );
        return;
      }

      const manualTracking =
        // @ts-expect-error datadog config
        !!globalObj.DD_RUM?.getInitConfiguration()?.trackViewsManually;
      if (!manualTracking) {
        console.warn(
          "@datadog/rum-react-integration: The trackViewsManually flag in RUM initialization must be set to %ctrue%c.",
          "color:green",
          "color:default",
        );
        return;
      }

      // @ts-expect-error datadog config
      globalObj.DD_RUM.startView(props?.path || props?.match?.path);
    })(),
  );
};
