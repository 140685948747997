import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PUSH_ALERT } from "~/pages/PPL/reducers/alerts";
import saveBids from "~/pages/PPL/actions/edit_bids/save_bids";
import CancelModal from "~/pages/PPL/pages/edit_bids/components/cancel_modal";
import { Button } from "~/components/vendorUI";
import { useTranslation } from "~/utils";
import { NavigationGuard } from "~/components";
import { ConfirmationServiceProvider } from "~/context";
import { CLEAR_CHANGED_BID } from "~/pages/PPL/reducers/changed_bids_reducer";
import { css, cx } from "ui/css";
import { anchorCSS } from "~/components/Text";

const changedBidsHeaderCSS = css({
  height: "100px",
  width: "100%",
  "& .changed-bids-header-wrapper": {
    height: "100%",
    alignItems: "center",
    "& .button-stack": {
      textAlign: "end",
      "& .overlay-wrapper": { position: "relative", display: "inline-block" },
    },
  },
});
const cancelBidsButtonCss = css({ marginBlockStart: "8px" });

const ChangedBidsHeader = ({ children, isBidChanged }) => {
  const { t } = useTranslation();
  const isLoading = useSelector((state) => state.ppl.isLoading);
  const dispatch = useDispatch();
  const { productId } = useParams();

  const handleApplyBid = () => {
    dispatch(saveBids(productId));
  };
  const onReset = async () => dispatch({ type: CLEAR_CHANGED_BID });

  return (
    <ConfirmationServiceProvider>
      <div className={cx("gdm-grid", changedBidsHeaderCSS)}>
        <div className={cx("gdm-row", "changed-bids-header-wrapper")}>
          <div className="gdm-col gdm-col-12">{children}</div>
          <div className="gdm-col gdm-col-12">
            <div className={cx("gdm-grid", "button-stack")}>
              <div className="gdm-row">
                <div className="gdm-col gdm-col-24">
                  <div
                    className="overlay-wrapper"
                    data-tracking="ppl_bidding_apply_bid_change"
                  >
                    <Button
                      isLoading={isLoading}
                      data-gtm="pplbidding-editbid-applybidsbutton"
                      variant="primary"
                      onClick={handleApplyBid}
                      data-tracking="ppl_bidding_apply_bid_change"
                    >
                      {t("PPL_EDIT-BIDDING_SUMMARY_ACTION_APPLY-BIDS")}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="gdm-row">
                <div
                  className={cx("gdm-col", "gdm-col-24", cancelBidsButtonCss)}
                >
                  <button
                    disabled={isLoading}
                    type="button"
                    data-gtm="pplbidding-editbid-cancelbidsbutton"
                    tabIndex="0"
                    className={anchorCSS({ variant: "inverted" })}
                    onKeyDown={() => {}}
                    onClick={() =>
                      dispatch({ type: PUSH_ALERT, component: <CancelModal /> })
                    }
                  >
                    {t("PPL_EDIT-BIDDING_SUMMARY_ACTION_CANCEL-BID-CHANGES")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NavigationGuard hasPendingChanges={isBidChanged} onConfirm={onReset} />
    </ConfirmationServiceProvider>
  );
};

ChangedBidsHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default ChangedBidsHeader;
