import { SET_SELECTED_PRODUCT_FILTERS } from "~/pages/PPL/reducers/selected_product_filters_reducer";

export default function setSelectedProductFilters(product) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_PRODUCT_FILTERS,
      payload: product,
    });
  };
}
