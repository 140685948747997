import AmericanExpressIcon from "./Amex.icon";
import VisaIcon from "./Visa.icon";
import MasterCardIcon from "./Mastercard.icon";
import JcbCardIcon from "./Jcb.icon";
import DiscoverCardIcon from "./Discover.icon";

const ICONS = {
  amex: <AmericanExpressIcon viewBox="0 0 70 50" />,
  mc: <MasterCardIcon viewBox="0 0 70 50" />,
  visa: <VisaIcon viewBox="0 0 70 50" />,
  jcb: <JcbCardIcon viewBox="0 0 70 50" />,
  discover: <DiscoverCardIcon viewBox="0 0 70 50" />,
};

export default ICONS;
