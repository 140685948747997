import { useEffect } from "react";
import { useCurrentUser } from "~/app/shared/contexts/currentUserContext";
import { useDispatch } from "react-redux";
import { PrivateRoute } from "./private_route";
import fetchVendor from "~/pages/PPL/actions/fetch_vendor";
import fetchProducts from "~/pages/PPL/actions/fetch_products";
import fetchMarkets from "~/pages/PPL/actions/fetch_markets";
import setIsLoading from "~/pages/PPL/actions/set_is_loading";

export const PrivatePPLRoute = ({ component: Component, ...rest }) => {
  const { selectedPPLCampaign } = useCurrentUser();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedPPLCampaign?.id) return;

    (async () => {
      try {
        await dispatch(setIsLoading(true));
        await dispatch(fetchVendor(true));
        await dispatch(fetchProducts(true));
        await dispatch(fetchMarkets(true));
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(setIsLoading(false));
      }
    })();
  }, [selectedPPLCampaign?.id?.toString()]);

  return (
    <PrivateRoute component={Component} campaignSwitcher="ppl" {...rest} />
  );
};
