import { RESET_VENDOR } from "./vendor_reducer";

export const SET_SIZES = "SET_SIZES";

const sizesReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_SIZES:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_VENDOR:
      return {};
    default:
      return state;
  }
};

export default sizesReducer;
