import PropType from "prop-types";
import { useDispatch } from "react-redux";
import { useNavigate } from "@remix-run/react";
import { CLEAR_CHANGED_BID } from "~/pages/PPL/reducers/changed_bids_reducer";
import { Modal } from "@capterra/arubaito";
import { Button } from "~/components/vendorUI";
import s from "./save_bid_modal.styles.ts";
import { useTranslation } from "~/utils";

export default function SaveBidModal({ onClosed = () => {} }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleManageBids = () => {
    dispatch({ type: CLEAR_CHANGED_BID });
    navigate("/ppl/bids");
    onClosed();
  };

  return (
    <Modal open onClosed={onClosed}>
      {(close) => (
        <div>
          <Modal.Header>
            <Modal.Close onClick={close} />
            <h2 className="gdm-heading-lg">
              {t("PPL_EDIT-BIDDING_SAVED-BIDS_TITLE")}
            </h2>
          </Modal.Header>
          <Modal.Body>
            <p className="gdm-paragraph-lg" />
          </Modal.Body>
          <Modal.Footer>
            <div className={s["footer-wrapper"]}>
              <Button
                data-gtm="pplbidding-savedmodal-returnmanagebutton"
                variant="secondary"
                onClick={handleManageBids}
              >
                {t("PPL_EDIT-BIDDING_SAVED-BIDS_ACTION_RETURN-TO-MANAGE")}
              </Button>
              <Button
                data-gtm="pplbidding-savedmodal-returneditbutton"
                variant="secondary"
                onClick={close}
              >
                {t("PPL_EDIT-BIDDING_SAVED-BIDS_ACTION_RETURN-TO-EDIT")}
              </Button>
            </div>
          </Modal.Footer>
        </div>
      )}
    </Modal>
  );
}

SaveBidModal.propTypes = {
  onClosed: PropType.func,
};
