import { createContext, useContext, useState } from "react";

type Context = [
  status: "enabled" | "disabled",
  setContext: React.Dispatch<React.SetStateAction<any>>,
];
const campaignSwitcherContext = createContext<Context>(["enabled", () => null]);

export const useCampaignSwitcher = () => {
  const [status, setStatus] = useContext(campaignSwitcherContext);

  return {
    isDisabled: status === "disabled",
    enable: () => setStatus("enabled"),
    disable: () => setStatus("disabled"),
  };
};

export const CampaignSwitcherProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const state = useState<Context[0]>("enabled");

  return (
    <campaignSwitcherContext.Provider value={state}>
      {children}
    </campaignSwitcherContext.Provider>
  );
};
