import { createSelector } from "reselect";
import getProduct from "~/pages/PPL/selectors/get_product";

export function getEditPageData(segments, sizes, changedBids, activeProduct) {
  return {
    segments,
    sizes,
    changedBids,
    activeProduct,
  };
}

export default createSelector(
  (state) => state.ppl.segments,
  (state) => state.ppl.sizes,
  (state) => state.ppl.changedBids,
  getProduct,
  getEditPageData,
);
