const filterCards = (cards, validCards) =>
  cards.filter(({ value }) => validCards.includes(value));

const acceptedCardsPerCountry = {
  IN: (cards) => filterCards(cards, ["visa", "mc"]),
  JP: (cards) => filterCards(cards, ["visa", "amex", "mc", "jcb"]),
  default: (cards) =>
    filterCards(cards, ["visa", "mc", "amex", "jcb", "discover"]),
};

function useValidCards(countryId = "", cards) {
  const upperID = countryId?.toUpperCase();
  if (upperID in acceptedCardsPerCountry) {
    return acceptedCardsPerCountry[upperID](cards);
  }

  return acceptedCardsPerCountry.default(cards);
}

export { useValidCards };
