import { memo } from "react";
import { useTranslation } from "~/utils";
import { Button, Stack, Text } from "~/components/vendorUI";
import { css } from "ui/css";
import { Heading } from "~/components/Text";

const RepInfo = ({ representative }) => {
  const { t } = useTranslation();

  return (
    <Stack
      gap="24px"
      className={css({ background: "{colors.gray.1}", padding: "24px" })}
    >
      <Heading>{t("ACCOUNT-SETTINGS_ENROLL-INFO_FAQ-TITLE")}</Heading>
      <Text>{t("ACCOUNT-SETTINGS_ENROLL-INFO_FAQ-CONTACT-REP")}</Text>
      <Stack gap="16px">
        <Text weight="semibold" color="brand">
          {representative.name}
        </Text>
        {representative.phone && <Text>{representative.phone}</Text>}
      </Stack>
      <div>
        <Button
          variant="tertiary"
          isFullWidth={false}
          data-tracking="ppc_payment_email_rep"
        >
          <a
            href={`mailto:${representative.email}`}
            className={css({ color: "black", textDecoration: "none" })}
          >
            {t("ACCOUNT-SETTINGS_ENROLL-INFO_FAQ-ACTION-CONTACT-REP")}
          </a>
        </Button>
      </div>
    </Stack>
  );
};

export default memo(
  RepInfo,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.representative) ===
    JSON.stringify(nextProps.representative),
);
