import PropType from "prop-types";
import cx from "classnames";
import { useDispatch } from "react-redux";
import { CLEAR_CHANGED_BID } from "~/pages/PPL/reducers/changed_bids_reducer";
import { Modal } from "@capterra/arubaito";
import { Button, Stack } from "~/components/vendorUI";
import s from "./error_bid_modal.styles.ts";
import { useTranslation } from "~/utils";
import { anchorCSS } from "~/components/Text";

export default function ErrorBidModal({ onClosed = () => {} }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Modal open onClosed={onClosed}>
      {(close) => (
        <div className={s["modal-wrapper"]}>
          <Modal.Header>
            <span className="gdm-icon gdm-icon-md gdm-icon-alert-error" />
            <span className={cx("gdm-heading-lg", "heading-label")}>
              {t("PPL_EDIT-BIDDING_ERROR_UNSAVED-BIDS_TITLE")}
            </span>
            <Modal.Close onClick={close} />
          </Modal.Header>
          <Modal.Body>
            <p className="gdm-paragraph-sm">
              {t("PPL_EDIT-BIDDING_ERROR_UNSAVED-BIDS_DESCRIPTION")}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className={s["footer-wrapper"]}>
              <Stack gap="16px">
                <Button variant="primary" onClick={close}>
                  {t("PPL_EDIT-BIDDING_ERROR_UNSAVED-BIDS_ACTION_EDIT")}
                </Button>
                <button
                  type="button"
                  className={anchorCSS({ variant: "dark" })}
                  tabIndex="0"
                  onKeyDown={() => {
                    dispatch({ type: CLEAR_CHANGED_BID });
                    close();
                  }}
                  onClick={() => {
                    dispatch({ type: CLEAR_CHANGED_BID });
                    close();
                  }}
                >
                  {t("DRAWER_ACTION_CANCEL")}
                </button>
              </Stack>
            </div>
          </Modal.Footer>
        </div>
      )}
    </Modal>
  );
}

ErrorBidModal.propTypes = {
  onClosed: PropType.func,
};
