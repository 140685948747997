import { RESET_VENDOR } from "./vendor_reducer";

export const SET_SELECTED_MARKET = "SET_SELECTED_MARKET";

const selectedMarketReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_SELECTED_MARKET:
      return action.payload;
    case RESET_VENDOR:
      return {};
    default:
      return state;
  }
};

export default selectedMarketReducer;
