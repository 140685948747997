import "./stripe_styles.scss";
import { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useTranslation } from "~/utils/i18n";
import { ConfirmationModal, CrossButton } from "~/components";
import { Button, Text, Modal, Loading, Inline } from "~/components/vendorUI";
import { CurrentCreditCard } from "./CurrentCreditCard";
import { CreditCardForm } from "./CreditCardForm";
import { useGetStripeCard } from "./useGetStripeCard";
import { removeStripeCard } from "../../../actions/remove_stripe_card";
import { css } from "ui/css";

loadStripe.setLoadParameters({ advancedFraudSignals: false });

const cancelCSS = css({
  backgroundColor: "transparent",
  color: "white",
  border: "none",
  cursor: "pointer",
  textDecoration: "underline",
  display: "inline",
  margin: 0,
  padding: 0,
});

function FormModal({
  children,
  open,
  onCancel,
  onOpen,
  disabled,
  isSubmitting,
  withTrigger,
}) {
  const { t } = useTranslation();
  const [consent, setConsent] = useState(false);
  const handleConsent = () => setConsent(!consent);
  const handleOnOpenChange = () => !isSubmitting && onCancel();
  const isDisabled = disabled || !consent;

  return (
    <Modal open={open} onOpenChange={handleOnOpenChange}>
      {withTrigger ? (
        <Button
          variant="secondary"
          onClick={onOpen}
          data-tracking="ppc_payment_add_credit_card"
        >
          {t("PPC-UPGRADE_ADD_CREDIT_CARD")}
        </Button>
      ) : null}
      <Modal.Content>
        <Modal.Header>
          <Text size="20px" color="brand" weight="bold">
            {t("ACCOUNT-SETTINGS_BILLING-FORM_CREDIT-CARD_TITLE")}
          </Text>
        </Modal.Header>
        <Modal.Body className={css({ maxWidth: "500px" })}>
          {children()}
          <Inline align="start">
            <input
              id="consent"
              type="checkbox"
              disabled={isSubmitting}
              onClick={handleConsent}
            />
            <Text asChild size="14px">
              <label htmlFor="consent">
                {t("PPC-UPGRADE_ADD_CREDIT_CARD-CONSENT")}
              </label>
            </Text>
          </Inline>
        </Modal.Body>
        <Modal.Footer distribute="end">
          <Modal.Close asChild>
            <button
              className={cancelCSS}
              disabled={isSubmitting}
              variant="tertiary"
            >
              {t("PPC-UPGRADE_CANCEL_CREDIT_CARD")}
            </button>
          </Modal.Close>
          <Button
            form="stripe-form"
            disabled={isDisabled || isSubmitting}
            isLoading={isSubmitting}
            data-tracking="ppc_payment_save_credit_card"
          >
            {t("PPC-UPGRADE_SAVE_CREDIT_CARD")}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}

export function StripeCreditCardFormModal({ cardTypeOptions, stripeKey }) {
  const { i18n, t } = useTranslation();
  const [card, setCard, isLoadingCard] = useGetStripeCard();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [stripe] = useState(() =>
    loadStripe(stripeKey, { locale: i18n.language }).finally(() =>
      setIsLoading(false),
    ),
  );

  if (isLoading || isLoadingCard)
    return <Loading data-testid="loading-stripe" size="24px" />;
  if (card)
    return (
      <Inline>
        <CrossButton onClick={() => setShowCancelModal(true)} />
        <CurrentCreditCard card={card} resetCard={() => setShowModal(true)} />
        <FormModal
          open={showModal}
          disabled={isDisabled}
          isSubmitting={isSubmitting}
          onCancel={() => setShowModal(false)}
          key={showModal}
        >
          {() => (
            <Elements stripe={stripe}>
              <CreditCardForm
                cardTypeOptions={cardTypeOptions}
                withWrapper
                hideSaveButton
                setCard={setCard}
                withSaveButton={false}
                onDisabled={setIsDisabled}
                onSubmit={() => setIsSubmitting(true)}
                onSubmitEnd={() => setIsSubmitting(false)}
                onSuccess={() => setShowModal(false)}
              />
            </Elements>
          )}
        </FormModal>
        {showCancelModal ? (
          <ConfirmationModal
            hideCloseText
            description=""
            hideSecondaryBtn
            onAccept={() => {
              removeStripeCard();
              setCard(null);
              setShowModal(false);
              setShowCancelModal(false);
            }}
            onDecline={() => setShowCancelModal(false)}
            onClosed={() => setShowCancelModal(false)}
            acceptBtn={t("PPC-UPGRADE_STRIPE_MODAL_DELETE-CARD")}
            title={t("PPC-UPGRADE_STRIPE_MODAL_DELETE-CARD_TITLE")}
          />
        ) : null}
      </Inline>
    );

  return (
    <FormModal
      withTrigger
      open={showModal}
      onOpen={() => setShowModal(true)}
      disabled={isDisabled}
      isSubmitting={isSubmitting}
      onCancel={() => setShowModal(false)}
      key={showModal}
    >
      {() => (
        <Elements stripe={stripe}>
          <CreditCardForm
            cardTypeOptions={cardTypeOptions}
            withWrapper
            hideSaveButton
            setCard={setCard}
            withSaveButton={false}
            onDisabled={setIsDisabled}
            onSubmit={() => setIsSubmitting(true)}
            onSubmitEnd={() => setIsSubmitting(false)}
            onSuccess={() => setShowModal(false)}
          />
        </Elements>
      )}
    </FormModal>
  );
}
