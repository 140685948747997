import {
  SET_CHANGED_BID,
  DELETE_CHANGED_BID,
} from "~/pages/PPL/reducers/changed_bids_reducer";
import {
  getNewMaxCostFromAuctionBids,
  isBidValid,
} from "~/pages/PPL/utils/business";

export default function setChangedBid(bid) {
  return (dispatch, getState) => {
    const {
      ppl: { auctionBids, selectedBid },
    } = getState();

    if (bid.key !== selectedBid.changedBidKey) return;

    const selectedBidOriginalAmount =
      selectedBid.type === "size"
        ? selectedBid.bid.bidAmount
        : selectedBid.bid.bid;
    if (bid.amount === selectedBidOriginalAmount) {
      dispatch({
        type: DELETE_CHANGED_BID,
        key: bid.key,
      });
      return;
    }

    let newMaxCost;

    const sizeSegmentAuctionBids =
      auctionBids[`${selectedBid.type}-${selectedBid.bid.id}`];
    if (sizeSegmentAuctionBids) {
      newMaxCost = getNewMaxCostFromAuctionBids(
        sizeSegmentAuctionBids.bids,
        bid.amount,
      );
    }

    dispatch({
      type: SET_CHANGED_BID,
      payload: {
        [bid.key]: {
          type: selectedBid.type,
          id: selectedBid.bid.id,
          sizeId: selectedBid.sizeBid && selectedBid.sizeBid.id,
          invalid: !isBidValid(bid.amount, bid.baseCpl),
          label:
            selectedBid.type === "segment"
              ? `${selectedBid.sizeBid.label} - ${selectedBid.bid.label}`
              : selectedBid.bid.label,
          newValues: {
            bidAmount: bid.amount,
            maxCost: newMaxCost,
          },
          oldValues: {
            bidAmount: selectedBidOriginalAmount,
            maxCost: selectedBid.bid.maxCost,
          },
        },
      },
    });
  };
}
