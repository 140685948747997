import { cloneElement } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SHIFT_ALERT } from "~/pages/PPL/reducers/alerts";

export default function Alerts() {
  const alerts = useSelector((state) => state.ppl.alerts);
  const dispatch = useDispatch();
  const topAlert = alerts[0];

  if (!topAlert) return null;

  function handleClosed() {
    dispatch({
      type: SHIFT_ALERT,
    });
  }

  return cloneElement(topAlert, { onClosed: handleClosed });
}
