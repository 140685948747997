import {
  FEATURE_MATCHERS,
  FEATURE_NAME_MAP,
  FEATURE_BREADCRUMBS,
} from "../constants";

const getFeatureBreadcrumbs = (featureName) => FEATURE_BREADCRUMBS[featureName];

const pushBreadcrumbs = (currentBreadcrumbs, newBreadcrumb) => {
  const isPushUnique = !Boolean(
    currentBreadcrumbs.find(
      (breadcrumb) => breadcrumb.label === newBreadcrumb.label,
    ),
  );
  if (isPushUnique) currentBreadcrumbs.push(newBreadcrumb);
};

const updateBreadcrumbs = (existingBreadcrumbs, conditionalBreadcrumbs) => {
  const isBreadcrumbMultiple = Array.isArray(conditionalBreadcrumbs);
  if (isBreadcrumbMultiple) {
    conditionalBreadcrumbs.forEach((conditionalBreadCrumb) =>
      pushBreadcrumbs(existingBreadcrumbs, conditionalBreadCrumb),
    );
  } else {
    pushBreadcrumbs(existingBreadcrumbs, conditionalBreadcrumbs);
  }
};

const updateConditionalBreadcrumbs = (matchedKey, breadcrumbs) => {
  if (FEATURE_MATCHERS.includes(matchedKey)) {
    const featureName = FEATURE_NAME_MAP[matchedKey];

    const conditionalBreadCrumbs = getFeatureBreadcrumbs(featureName);

    updateBreadcrumbs(breadcrumbs, conditionalBreadCrumbs);
  }
};

export {
  getFeatureBreadcrumbs,
  pushBreadcrumbs,
  updateBreadcrumbs,
  updateConditionalBreadcrumbs,
};
