import { RESET_VENDOR } from "./vendor_reducer";

export const SET_SELECTED_PRODUCT = "SET_SELECTED_PRODUCT";

const selectedProductReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_SELECTED_PRODUCT:
      return action.payload;
    case RESET_VENDOR:
      return {};
    default:
      return state;
  }
};

export default selectedProductReducer;
