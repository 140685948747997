import { useCallback, useEffect } from "react";
import { useCurrentUser } from "~/app/shared/contexts/currentUserContext";
import {
  gtmInitialize,
  clearGtmDataLayer,
  pendoInitialize,
  clearPendoDataLayer,
} from "~/utils";

const Tracking = () => {
  const {
    user,
    selectedPPCCampaign,
    selectedPPLCampaign,
    visiblePPCCampaigns,
    visiblePPLCampaigns,
  } = useCurrentUser();

  const updateTrackingContext = useCallback(() => {
    if (window._DATADOG_SYNTHETICS_BROWSER === undefined) {
      clearGtmDataLayer();
      clearPendoDataLayer();
      pendoInitialize({
        user,
        selectedPPCCampaign,
        selectedPPLCampaign,
        visiblePPCCampaigns,
        visiblePPLCampaigns,
      });
      gtmInitialize(
        !user.isLoggedIn
          ? {}
          : {
              user,
              selectedPPCCampaign,
              selectedPPLCampaign,
              visiblePPCCampaigns,
              visiblePPLCampaigns,
            },
      );
    }
  }, [
    user,
    selectedPPCCampaign,
    selectedPPLCampaign,
    visiblePPCCampaigns,
    visiblePPLCampaigns,
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    updateTrackingContext();
    pendoInitialize({});
  }, []);

  return <></>;
};

export default Tracking;
