import { useUserData } from "~/context/UserData";

export const useIsCampaignType = (): {
  isAutobidder: boolean;
  isOneBid: boolean;
  isPPCServices: boolean;
} => {
  const { selectedPPCCampaign } = useUserData();

  return {
    isAutobidder: Boolean(selectedPPCCampaign?.isAutobidder),
    isOneBid: Boolean(selectedPPCCampaign?.isOneBid),
    isPPCServices: Boolean(selectedPPCCampaign?.isPPCServices),
  };
};
