import { css } from "ui/css";

const styles = {
  "table-loader-row": css({
    display: "flex !important",
    "& > td.table-loader-column": {
      borderBottom: "none !important",
      width: "100% !important",
    },
  }),
  "error-message": css({
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "gray.1",
  }),
};

export default styles;
