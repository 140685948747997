import "./stripe_styles.scss";
import { useState } from "react";
import PropTypes from "prop-types";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useTranslation } from "~/utils/i18n";
import { Loading } from "~/components/vendorUI";
import { CurrentCreditCard } from "./CurrentCreditCard";
import { CreditCardForm } from "./CreditCardForm";
import { useGetStripeCard } from "./useGetStripeCard";

loadStripe.setLoadParameters({ advancedFraudSignals: false });

const StripeCreditCardForm = ({ cardTypeOptions, stripeKey }) => {
  const { i18n } = useTranslation();
  const [card, setCard, isLoadingCard] = useGetStripeCard();
  const [isLoading, setIsLoading] = useState(true);
  const [stripe] = useState(() =>
    loadStripe(stripeKey, { locale: i18n.language }).finally(() =>
      setIsLoading(false),
    ),
  );

  const handleResetCard = () => setCard(null);

  if (isLoading || isLoadingCard)
    return <Loading data-testid="loading-stripe" size="24px" />;
  if (card)
    return <CurrentCreditCard card={card} resetCard={handleResetCard} />;

  return (
    <Elements stripe={stripe}>
      <CreditCardForm cardTypeOptions={cardTypeOptions} setCard={setCard} />
    </Elements>
  );
};

StripeCreditCardForm.propTypes = {
  cardTypeOptions: PropTypes.array.isRequired,
  stripeKey: PropTypes.string.isRequired,
};

export { StripeCreditCardForm };
