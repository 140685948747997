import PropTypes from "prop-types";
import { useLocation, matchPath } from "react-router-dom";
import { Stack, Alert } from "~/components/vendorUI";
import { useFlashMessage } from "~/app/shared/contexts/flashMessageContext";
import { css } from "ui/css";

const FLASH_MESSAGE_ORDER = {
  error: 1,
  notification: 2,
  success: 3,
};

const AlertTypePropMap = {
  error: "warning",
  notification: "pending",
  success: "notification",
};

const flashWrapperCSS = css({
  "& a, & [role='button']": {
    color: "actionBlue",
    textDecoration: "underline",
    fontWeight: "medium",
    fontSize: "14px !",
    cursor: "pointer",
  },
});

export const FlashMessage = () => {
  const { flashMessages } = useFlashMessage();
  const location = useLocation();

  if (!getFlashMessages(flashMessages, location).length)
    return (
      <div
        id="vp-banner"
        data-testid="no-flash"
        className={css({ "&:empty": { display: "none" } })}
      />
    );

  return (
    <Stack className={flashWrapperCSS} gap="8px">
      {getFlashMessages(flashMessages, location).map((message) => (
        <Alert type={AlertTypePropMap[message.type]} key={message.id}>
          {message.innerHtml ? (
            <span
              dangerouslySetInnerHTML={{
                __html: message.copy,
              }}
            ></span>
          ) : (
            message.copy
          )}
        </Alert>
      ))}
    </Stack>
  );
};

// filter out the messages as per the path
// sorting the flash message by priority
export const getFlashMessages = (messages, { pathname }) => {
  const matchRoute = (path) => matchPath(pathname, { path, exact: true });

  return messages
    .filter((message) => {
      const { excludePaths, includePaths } = message;

      if (Array.isArray(excludePaths) && excludePaths.length > 0)
        return !excludePaths.some(matchRoute);
      if (Array.isArray(includePaths)) return includePaths.some(matchRoute);

      return true;
    })
    .sort((msg1, msg2) => {
      if (FLASH_MESSAGE_ORDER[msg1.type] < FLASH_MESSAGE_ORDER[msg2.type])
        return -1;
      if (FLASH_MESSAGE_ORDER[msg1.type] > FLASH_MESSAGE_ORDER[msg2.type])
        return 1;
      return 0;
    });
};

export default FlashMessage;

FlashMessage.propTypes = {
  initialMessages: PropTypes.array,
};
