import { RESET_VENDOR } from "./vendor_reducer";

export const SET_AUCTION_BIDS = "SET_AUCTION_BIDS";
export const CLEAR_AUCTION_BIDS = "CLEAR_AUCTION_BIDS";

const auctionBidsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_AUCTION_BIDS:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_AUCTION_BIDS:
      return {};
    case RESET_VENDOR:
      return {};
    default:
      return state;
  }
};

export default auctionBidsReducer;
