import authFetch from "../auth_fetch";

const deleteExpansionLead = async (leadID) => {
  try {
    const response = await authFetch(`/expansion_leads/${leadID}/decline`, {
      method: "PATCH",
    });

    if (response.ok) {
      return {
        errors: [],
        data: response.json().expansion_lead || {
          id: leadID,
        },
      };
    }
    return {
      errors: Array.isArray(response.data.errors)
        ? response.data.errors
        : ["Unexpected Error"],
      data: {},
    };
  } catch (err) {
    console.error(err);
    return {
      errors: ["Unexpected Error"],
      data: {},
    };
  }
};

export default deleteExpansionLead;
