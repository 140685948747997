import PropTypes from "prop-types";
import { Modal } from "@capterra/arubaito";
import mStyles from "./alert_modal.styles.ts";
import { useTranslation } from "~/utils";
import { Button } from "~/components/vendorUI";

export const AlertModal = ({
  open = false,
  closeOnBackdropClick = true,
  onAccept = () => {},
  successTitle,
  successMessage,
  warningTitle,
  warningMessage,
  errorTitle,
  errorMessage,
  acceptButton,
  type = "",
  modalContainerProps = {},
}) => {
  const { t } = useTranslation();
  const handleClose = (close) => {
    close();
    onAccept();
  };

  const alertTitleMapper = {
    error: errorTitle || t("ALERT-MODAL_ERROR-TITLE"),
    warning: warningTitle || t("ALERT-MODAL_WARNING-TITLE"),
    success: successTitle || t("ALERT-MODAL_SUCCESS-TITLE"),
  };

  const alertMessageMapper = {
    error: errorMessage || t("ALERT-MODAL_ERROR-DESCRIPTION"),
    warning: warningMessage || t("ALERT-MODAL_WARNING-DESCRIPTION"),
    success: successMessage || t("ALERT-MODAL_SUCCESS-DESCRIPTION"),
  };

  return (
    <Modal
      open={open}
      closeOnBackdropClick={closeOnBackdropClick}
      {...modalContainerProps}
    >
      {(close) => (
        <div className={mStyles[`modal-container-${type}`]}>
          <Modal.Header>
            {(type === "error" || type === "warning") && (
              <span className="gdm-m-right-sm gdm-icon gdm-icon-lg gdm-align-bottom gdm-icon-alert-error" />
            )}
            <h2
              className={`gdm-inline-block gdm-m-none gdm-color-${
                type === "error" || type === "warning"
                  ? "alert-error gdm-heading-lg"
                  : "base-brand-primary gdm-subtitle"
              }`}
            >
              {alertTitleMapper[type]}
            </h2>
            <Modal.Close onClick={() => handleClose(close)} />
          </Modal.Header>
          <Modal.Body
            className="gdm-paragraph-sm"
            data-testid="alert_modal_body"
          >
            {alertMessageMapper[type]}
          </Modal.Body>
          <Modal.Footer>
            {type === "error" || type === "warning" ? (
              <div className="gdm-inline-block gdm-text-center">
                <Button variant="primary" onClick={() => handleClose(close)}>
                  {acceptButton || t("ALERT-MODAL_ACTION_ACCEPT")}
                </Button>
              </div>
            ) : (
              <span />
            )}
          </Modal.Footer>
        </div>
      )}
    </Modal>
  );
};

AlertModal.propTypes = {
  open: PropTypes.bool,
  closeOnBackdropClick: PropTypes.bool,
  onAccept: PropTypes.func,
  successTitle: PropTypes.string,
  successMessage: PropTypes.node,
  warningTitle: PropTypes.string,
  warningMessage: PropTypes.node,
  errorTitle: PropTypes.string,
  errorMessage: PropTypes.node,
  acceptButton: PropTypes.string,
  type: PropTypes.string,
  modalContainerProps: PropTypes.shape({}),
};

export default AlertModal;
