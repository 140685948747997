import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { unstable_batchedUpdates } from "react-dom";
import moment from "moment";
import { LoadingOverlay } from "~/components";
import { Text, Modal, Button, useToast, Stack } from "~/components/vendorUI";
import { ConfirmationServiceProvider } from "~/context";
import { useTranslation } from "~/utils";
import {
  validateEmail,
  isValidAddress,
  isValidCity,
  isValidZip,
  isEmpty,
} from "~/pages/AccountSettings/utils";

import isEqual from "lodash.isequal";
import cx from "classnames";
import s from "~/pages/AccountSettings/settings.module.scss";

import PPCBilling from "./sections/PPCBilling";
import RepInfo from "./sections/RepInfo";
import PPCOptIns from "./sections/PPCOptIns";
import { CorporateInformation } from "./sections/CorporateInformation";
import DrawerWrapper from "~/components/DrawerWrapper";
import { CARD_TYPES_MAPPINGS } from "~/pages/AccountSettings/assets/static";

import { fetchPpcAccountSettings } from "~/pages/AccountSettings/actions/fetch_ppc_account_settings";
import { updatePpcAccountSettings } from "~/pages/AccountSettings/actions/update_ppc_account_settings";
import { saveBraintreeCard } from "~/pages/AccountSettings/actions/update_braintree_card";
import { AccountsContext } from "~/pages/AccountSettings";
import { useCurrentUser } from "~/app/shared/contexts/currentUserContext";
import { useIsCampaignType } from "~/utils/useIsCampaignType";
import { css } from "ui/css";
import { useUserData } from "~/context/UserData";

const sideBoxCSS = css({
  position: "absolute",
  top: 0,
  right: 0,
});

const padCSS = css({ minHeight: "285px" });

const basicPPCBoxCSS = css({
  maxWidth: "750px",
  textAlign: "center",
  padding: "50px 180px !important",
  marginTop: "60px",
});

const getPpcToken = (settings) =>
  settings.payment_gateway === "braintree"
    ? settings.braintree_token
    : settings.stripe_token;

const AlertBox = ({
  isAlertOpen,
  closeModal,
  type,
  errorTitle,
  errorMessage,
  successTitle,
  successMessage,
  warningTitle,
  warningMessage,
}) => {
  const { t } = useTranslation();
  const alertTitleMapper = {
    error: errorTitle || t("ALERT-MODAL_ERROR-TITLE"),
    warning: warningTitle || t("ALERT-MODAL_WARNING-TITLE"),
    success: successTitle || t("ALERT-MODAL_SUCCESS-TITLE"),
  };

  const alertMessageMapper = {
    error: errorMessage || t("ALERT-MODAL_ERROR-DESCRIPTION"),
    warning: warningMessage || t("ALERT-MODAL_WARNING-DESCRIPTION"),
    success: successMessage || t("ALERT-MODAL_SUCCESS-DESCRIPTION"),
  };
  if (type === "success") return;
  return (
    <Modal open={isAlertOpen} onOpenChange={closeModal}>
      <Modal.Content>
        <Modal.Header type={type}>
          <Text size="20px" color="brand" weight="bold">
            {alertTitleMapper[type]}
          </Text>
        </Modal.Header>
        <Modal.Body> {alertMessageMapper[type]}</Modal.Body>
        <Modal.Footer className={css({ justifyContent: "end !" })}>
          <Button variant="primary" onClick={closeModal}>
            {t("ALERT-MODAL_ACTION_ACCEPT")}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export const PPCPaymentDetails = () => {
  const { t } = useTranslation();
  const { isOneBid, isPPCServices } = useIsCampaignType();
  const {
    user: { permissions },
    selectedPPCCampaign,
  } = useCurrentUser();
  const {
    campaignSummary: { ppcCampaignProfileAttributes, representative },
  } = useUserData();
  const [ppcSettings, setPPCSettings] = useState({}); // API state for reset
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isPageSubmitting, setIsPageSubmitting] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertState, setAlertState] = useState({
    type: "success",
    title: t("ALERT-TITLE_SUCCESS"),
    message: t("ALERT-MESSAGE_SUCCESS"),
  });
  const submitBtnRef = useRef();
  const isIndianCompany =
    ppcCampaignProfileAttributes.billingTaxCountryCode?.toUpperCase() === "IN";

  const [payBy, setPayBy] = useState("check");

  const [ppcForm, setPpcForm] = useState({
    billing_email: "",
    isUnlimited: false,
    channels: [],
  });
  const [ppcAddr, setPpcAddr] = useState({
    address: "",
    city: "",
    country: "",
    state: "",
    zip_code: "",
  });

  const [corporateInformation, setCorporateInformation] = useState({
    corporateIdentificationNumber: "",
    gstId: "",
    withholdingTax: "",
  });

  const [errors, setErrors] = useState({
    ppcForm: {},
    ppcAddr: {},
  });

  const [touched, setTouched] = useState({
    ppcForm: {},
    ppcAddr: {},
  });

  const [ppcCreditCardForm, setPpcCreditCard] = useState({
    showCCErrors: false,
    fieldsInstance: null,
    cardValidation: {},
    creditCardType: "",
    paymentMethod: null,
    saveButtonDisabled: false,
    disableCardEdit: false,
  });
  const { addToast } = useToast();

  const handlePPCForm = (key, val) => setPpcForm((s) => ({ ...s, [key]: val }));
  const handlePPCAddr = (key, val) => setPpcAddr((s) => ({ ...s, [key]: val }));
  const handlePayBy = (value) => setPayBy(value);

  const handleErrors = (key, name, value) =>
    setErrors((s) => ({ ...s, [key]: { ...s[key], [name]: value } }));
  const handleTouched = (key, name, value) =>
    setTouched((s) => ({ ...s, [key]: { ...s[key], [name]: value } }));

  const handleOptedBidChannels = (channelCode) => {
    setPpcForm((s) => ({
      ...s,
      channels: s["channels"].includes(channelCode)
        ? s["channels"].filter((code) => code !== channelCode)
        : [...s["channels"], channelCode].sort(),
    }));
  };

  const handlePPCCreditCard = (key, val) =>
    setPpcCreditCard((s) => ({ ...s, [key]: val }));

  const handleCardEdit = () =>
    setPpcCreditCard((s) => ({
      ...s,
      disableCardEdit: !s["disableCardEdit"],
    }));

  const isPPCBillingVisible = useMemo(
    () =>
      permissions?.ppc?.hasAccess &&
      (selectedPPCCampaign?.isUpgraded || ppcSettings?.upgrade_pending),
    [permissions, selectedPPCCampaign, ppcSettings],
  );

  const isPpcFormChanged = useMemo(
    () =>
      Object.keys(ppcSettings).length !== 0 &&
      Object.values(touched.ppcForm).some((v) => v) &&
      !isEqual(ppcForm, {
        billing_email: ppcSettings.billing_email || "",
        channels: ppcForm.channels,
      }),

    [ppcSettings, touched.ppcForm, ppcForm, selectedPPCCampaign],
  );
  const isPPCChannelsChanged = useMemo(
    () =>
      Object.keys(ppcSettings).length !== 0 &&
      touched.ppcForm.channels &&
      !isEqual(ppcForm.channels, ppcSettings.channels || []),

    [
      ppcForm.channels,
      ppcSettings,
      touched.ppcForm.channels,
      selectedPPCCampaign,
    ],
  );
  const isPpcAddrChanged = useMemo(
    () =>
      Object.keys(ppcSettings).length !== 0 &&
      payBy === "cc" &&
      Object.values(touched.ppcAddr).some((v) => v) &&
      !isEqual(ppcAddr, {
        address: ppcSettings.billing_information.billing_address || "",
        city: ppcSettings.billing_information.billing_city || "",
        country: ppcSettings.billing_information.billing_country || "",
        state: ppcSettings.billing_information.billing_state || "",
        zip_code: ppcSettings.billing_information.billing_zip_code || "",
      }),

    [ppcSettings, payBy, touched.ppcAddr, ppcAddr, selectedPPCCampaign],
  );

  const isPpcCardChanged = useMemo(
    () =>
      Object.keys(ppcSettings).length !== 0 &&
      payBy === "cc" &&
      !ppcCreditCardForm.disableCardEdit &&
      Object.keys(ppcCreditCardForm.cardValidation).length !== 0 &&
      Object.values(ppcCreditCardForm.cardValidation).some((v) => !v),

    [
      ppcSettings,
      payBy,
      ppcCreditCardForm.disableCardEdit,
      ppcCreditCardForm.cardValidation,
      selectedPPCCampaign,
    ],
  );

  const isChanged =
    isPPCChannelsChanged ||
    isPpcFormChanged ||
    isPpcAddrChanged ||
    isPpcCardChanged;

  // helper functions
  const setPageData = useCallback(
    (settings) => {
      setPPCSettings({ ...settings.campaign });
      setPayBy(settings.campaign.payment_type);
      setPpcForm({
        billing_email: settings.campaign.billing_email || "",
        channels: settings.campaign.channels || [],
      });
      setPpcAddr({
        address: settings.campaign.billing_information.billing_address || "",
        city: settings.campaign.billing_information.billing_city || "",
        country: settings.campaign.billing_information.billing_country || "",
        state: settings.campaign.billing_information.billing_state || "",
        zip_code: settings.campaign.billing_information.billing_zip_code || "",
      });
      setPpcCreditCard((prev) => ({
        ...prev,
        creditCardType: settings?.campaign?.billing_information?.cc_type || "",
      }));
      setTouched({
        ppcForm: {},
        ppcAddr: {},
      });
      setErrors({
        ppcForm: {},
        ppcAddr: {},
      });
      setIsPageLoading(false);
      setCorporateInformation({
        corporateIdentificationNumber:
          settings.corporate_information.corporate_identification_number,
        gstId: settings.corporate_information.gst_id,
        withholdingTax: settings.corporate_information.withholding_tax,
      });
      handlePPCCreditCard("showCCErrors", false);
    },

    [selectedPPCCampaign],
  );

  const getSubmitValidationMsgs = () => {
    const getFormErrors = {
      ppcForm: {
        billing_email:
          payBy === "check" && !validateEmail(ppcForm.billing_email).isValid,
      },
      ppcAddr:
        payBy === "cc"
          ? {
              country: !ppcAddr.country,
              address: !isValidAddress(ppcAddr.address),
              state: ppcAddr.country === "US" && isEmpty(ppcAddr.state),
              city: !isValidCity(ppcAddr.city),
              zip_code: !isValidZip(ppcAddr.zip_code),
            }
          : {},
      ppcCCForm:
        payBy === "cc" && !ppcCreditCardForm.disableCardEdit
          ? {
              credit_card_type: !ppcCreditCardForm.creditCardType,
              ppc_credit_card_information:
                ppcCreditCardForm.cardValidation &&
                Object.values(ppcCreditCardForm.cardValidation).some((v) => v),
            }
          : {},
    };

    const MSG_MAP = {
      ppcForm: t("ACCOUNT-SETTINGS_PPC-BILLING-FORM_TITLE"),
      ppcAddr: t("ACCOUNT-SETTINGS_PPC-BILLING-FORM_TITLE"),
      ppcCCForm: t("ACCOUNT-SETTINGS_ERROR_PPC-CREDIT-CARD-FORM"),
    };

    const messages = Object.entries(getFormErrors)

      .filter(([_, v]) => Object.values(v).filter((value) => value).length > 0)
      .reduce((total, [k, v]) => {
        const fields = Object.entries(v)

          .filter(([_, value]) => value)
          .map(([key]) => {
            // logic to convert `invoice_notification_email` to `Invoice Notification Email`
            const snakeToTitleCase = (keyName) => {
              const str = keyName.replace(/[_][a-z]/g, (group) =>
                group.toUpperCase().replace("_", " "),
              );
              return str[0].toUpperCase() + str.slice(1);
            };

            const getCreditCardKeys = (cardValidation) =>
              Object.entries(cardValidation)

                .filter(([_, v]) => v)
                .map(
                  ([k, _]) =>
                    ({
                      card_holder_name: t(
                        "ACCOUNT-SETTINGS_BILLING-FORM_LABEL_CREDIT-CARD-NAME",
                      ),
                      card_number: t(
                        "ACCOUNT-SETTINGS_ERROR_CREDIT-CARD-NUMBER",
                      ),
                      cvv: t("ACCOUNT-SETTINGS_ERROR_CREDIT-CARD_CVV"),
                      expiration_date: t(
                        "ACCOUNT-SETTINGS_BILLING-FORM_LABEL_CREDIT-CARD-EXPIRATION-DATE",
                      ),
                    })[k],
                )
                .join(", ");

            const keyObj = {
              ppc_credit_card_information: getCreditCardKeys(
                ppcCreditCardForm.cardValidation,
              ),
            };
            const keyName = keyObj[key] || snakeToTitleCase(key);
            return keyName;
          });

        return [
          ...total,
          t("ACCOUNT-SETTINGS_ERROR_FORM-SUMMARY", {
            formName: MSG_MAP[k],
            fields: fields.join(", "),
            count: fields.length,
          }),
        ];
      }, []);

    return { messages, getFormErrors };
  };

  const getSubmitPayload = ({ ppcCreditCard }) => {
    const appendData = (changedFlag, body) => !!changedFlag && body;
    const body = {
      ...appendData(
        isChanged ||
          (ppcCreditCard && Object.keys(ppcCreditCard.details).length > 0),
        {
          ppc: {
            ...appendData(
              payBy === "check" &&
                ppcSettings.billing_email !== ppcForm.billing_email,
              { billing_email: ppcForm.billing_email },
            ),
            ...appendData(
              payBy === "cc" &&
                (isPpcAddrChanged ||
                  (ppcCreditCard &&
                    Object.keys(ppcCreditCard.details).length > 0)),
              {
                billing_information: {
                  ...appendData(isPpcAddrChanged, {
                    billing_address: ppcAddr.address,
                    billing_city: ppcAddr.city,
                    billing_country: ppcAddr.country,
                    billing_state: ppcAddr.state || "",
                    billing_zip_code: ppcAddr.zip_code,
                  }),
                  ...appendData(
                    ppcCreditCard &&
                      Object.keys(ppcCreditCard.details).length > 0,
                    {
                      cc_exp_date: moment(
                        `${ppcCreditCard.details.expirationYear}-${ppcCreditCard.details.expirationMonth} Z`,
                        "YYYY-MM Z",
                      )
                        .utc()
                        .endOf("month")
                        .toISOString(),
                      cc_four_digits: ppcCreditCard.details.lastFour,
                      cc_name_on_card: ppcCreditCard.details.cardholderName,
                      cc_type:
                        CARD_TYPES_MAPPINGS[ppcCreditCard.details.cardType],
                    },
                  ),
                },
              },
            ),
            ...appendData(!isEqual(ppcSettings.channels, ppcForm.channels), {
              channels: ppcForm.channels,
            }),
          },
        },
      ),
    };
    return body;
  };

  const handleSubmit = async () => {
    const { messages, getFormErrors } = getSubmitValidationMsgs();

    // if (error messages show error messages in a modal else send POST/PATCH request)
    if (messages.length > 0) {
      const { ppcCCForm, ...restFormErr } = getFormErrors;
      unstable_batchedUpdates(() => {
        setAlertState({
          type: "error",
          title: t("ALERT-TITLE_ERROR"),
          message: messages.map((m, idx) => (
            <p key={idx} className="gdm-paragraph-sm">
              {m}
            </p>
          )),
        });
        setTouched(restFormErr);
        setErrors(restFormErr);
        if (payBy === "cc") handlePPCCreditCard("showCCErrors", true);
      });
    } else {
      // make API call
      setIsPageSubmitting(true);
      let ppcCreditCard = {
        details: {},
      };
      const isPPCCardEdited =
        payBy === "cc" && !ppcCreditCardForm.disableCardEdit;
      const isBrainTree = !["IN", "JP"].includes(
        ppcCampaignProfileAttributes.billingTaxCountryCode?.toUpperCase(),
      );

      if (isPPCCardEdited && isBrainTree) {
        ppcCreditCard = await saveBraintreeCard(
          ppcCreditCardForm.fieldsInstance,
          ppcSettings.braintree_token,
          ppcCreditCardForm.paymentMethod?.nonce || null,
        );
        if (ppcCreditCard) handlePPCCreditCard("paymentMethod", ppcCreditCard);
      }

      if (isPPCCardEdited && !ppcCreditCard) {
        unstable_batchedUpdates(() => {
          setIsPageSubmitting(false);
          setAlertState({
            type: "error",
            title: t("ALERT-TITLE_ERROR"),
            message: t("ACCOUNT-SETTINGS_ERROR_CREDIT-CARD-SAVE"),
          });
          setIsAlertOpen(true);
        });
        return;
      }
      const payload = getSubmitPayload({ ppcCreditCard });
      const res = await updatePpcAccountSettings(payload);
      if (res === null) {
        unstable_batchedUpdates(() => {
          setAlertState({
            type: "error",
            title: t("ALERT-TITLE_ERROR"),
            message: t("ALERT-MESSAGE_ERROR"),
          });
          setIsPageSubmitting(false);
        });
      } else if (res.errors) {
        // set the serverside validation error
        const expiration_date =
          res.errors.campaign?.cc_exp_date &&
          res.errors.campaign.cc_exp_date[0] &&
          t("ACCOUNT-SETTINGS_ERROR_CREDIT-CARD-EXPIRED");
        unstable_batchedUpdates(() => {
          setTouched((s) => ({
            ...s,
            ppcForm: {
              ...s.ppcForm,
            },
          }));
          setErrors((s) => ({
            ...s,
            ppcForm: {
              ...s.ppcForm,
            },
          }));
          if (expiration_date) {
            const expiration_date = res.errors.campaign.cc_exp_date[0];
            setPpcCreditCard((s) => ({
              ...s,
              cardValidation: { ...s.cardValidation, expiration_date },
            }));
          }
          setAlertState({
            type: "error",
            title: t("ALERT-TITLE_ERROR"),
            message: <p className="gdm-paragraph-sm">{expiration_date}</p>,
          });
          setIsPageSubmitting(false);
        });
      } else {
        addToast(t("ALERT-MESSAGE_SUCCESS"), { type: "success" });
        const newSettings = await fetchPpcAccountSettings();
        unstable_batchedUpdates(() => {
          if (Object.keys(newSettings).length > 0) {
            setPageData(newSettings);
            setAlertState({
              type: "success",
              title: t("ALERT-TITLE_SUCCESS"),
              message: t("ALERT-MESSAGE_SUCCESS"),
            });
          } else {
            setAlertState({
              type: "error",
              title: t("ALERT-TITLE_ERROR"),
              message: t("ALERT-MESSAGE_ERROR-SERVER"),
            });
          }
          setIsPageSubmitting(false);
        });
      }
    }
    setIsAlertOpen(true);
  };
  const handleFormReset = () => setPageData({ campaign: ppcSettings });

  useEffect(() => {
    async function fetchData() {
      setIsPageLoading(true);
      const settings = await fetchPpcAccountSettings();
      if (Object.keys(settings).length > 0) {
        setPageData(settings);
      } else {
        setIsPageLoading(false);
        setAlertState({
          type: "error",
          title: t("ALERT-TITLE_ERROR"),
          message: t("ALERT-MESSAGE_ERROR-SERVER"),
        });
        setIsAlertOpen(true);
      }
    }
    fetchData();
  }, [setPageData, selectedPPCCampaign]);

  if (isPageLoading || Object.keys(ppcSettings).length === 0)
    return <LoadingOverlay />;
  const closeModal = () => setIsAlertOpen(false);

  return (
    <ConfirmationServiceProvider>
      <AccountsContext.Provider
        value={{
          ppcToken: getPpcToken(ppcSettings),
          ppcPaymentGateway: ppcSettings.payment_gateway,
          payBy,
          ppcForm,
          ppcAddr,
          errors,
          touched,
          ppcCreditCardForm,
          handlePayBy,
          handlePPCForm,
          handleOptedBidChannels,
          handlePPCAddr,
          handleErrors,
          handleTouched,
          handlePPCCreditCard,
          handleCardEdit,
        }}
      >
        <div className={s.accountSettingsPage}>
          {isPageSubmitting && <LoadingOverlay />}
          <section className="gdm-relative">
            <Stack gap="48px" className={padCSS}>
              <Text size="28px" weight="semibold" color="brand" asChild>
                <h1>{t("ACCOUNT-SETTINGS_PPC-BILLING-FORM_TITLE")}</h1>
              </Text>
              {isPPCBillingVisible ? (
                <PPCBilling />
              ) : (
                <div className={basicPPCBoxCSS}>
                  <Text color="brand" weight="medium">
                    {t("ACCOUNT-SETTINGS_MESSAGE_BASIC-PPC-VENDOR")}
                  </Text>
                </div>
              )}
            </Stack>
            {!isEmpty(representative) ? (
              <div className={sideBoxCSS}>
                <RepInfo representative={representative} />
              </div>
            ) : null}
          </section>

          <section className={cx(s.flex, s.form, "gdm-m-top-sm")}>
            {!(isOneBid || isPPCServices) && (
              <PPCOptIns
                handleChange={handleOptedBidChannels}
                state={ppcForm.channels.filter(
                  ({ name }) => name !== t("CAPTERRA"),
                )}
              />
            )}
          </section>
          {isIndianCompany && (
            <CorporateInformation {...corporateInformation} />
          )}

          <DrawerWrapper
            show={
              Object.keys(ppcSettings).length > 0 &&
              isChanged &&
              !(isPageLoading || isPageSubmitting)
            }
            onCancel={handleFormReset}
            onSubmit={() => {
              submitBtnRef.current.click();
            }}
            isLoading={isPageLoading || isPageSubmitting}
            dataTrackingId="ppc_payment_save_changes"
          />

          <AlertBox
            isAlertOpen={isAlertOpen}
            closeModal={closeModal}
            type={alertState.type}
            errorTitle={alertState.title}
            errorMessage={alertState.message}
            successTitle={alertState.title}
            successMessage={alertState.message}
          />

          <Button
            ref={submitBtnRef}
            variant="primary"
            onClick={handleSubmit}
            className="gdm-hidden"
            disabled={isPageLoading || isPageSubmitting}
            data-tracking="ppc_payment_save_changes"
          >
            {t("ACTION_SUBMIT")}
          </Button>
        </div>
      </AccountsContext.Provider>
    </ConfirmationServiceProvider>
  );
};

export default PPCPaymentDetails;
