export default function debounce(func, wait, immediate) {
  let timeout;
  return (value) => {
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(this, [...arguments, value]);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(this, [...arguments, value]);
  };
}
