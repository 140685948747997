import setVendor from "./set_vendor";
import authFetch from "./auth_fetch";
import setIsLoading from "./set_is_loading";

const fetchVendor =
  (shouldRefresh = false) =>
  async (dispatch, getState) => {
    const {
      ppl: { vendor },
    } = getState();
    if (!shouldRefresh && vendor && vendor.name) return vendor;
    dispatch(setIsLoading(true));

    const vendorResponse = await authFetch("/vendors");
    if (!vendorResponse.ok) throw new Error(vendorResponse.statusText);

    const vendorInfo = (await vendorResponse.json()) || {};
    dispatch(setIsLoading(false));
    dispatch(setVendor(vendorInfo));

    return null;
  };

export default fetchVendor;
