import { TIMEZONES } from "~/pages/AccountSettings/assets/static";
import { DEPARTMENT, FIELD_NAMES, JOB_LEVEL } from "../config/constants";

export const TIMEZONES_OPTIONS = Object.entries(TIMEZONES).map(([k, v]) => ({
  value: k,
  label: v,
}));

export const checkPasswordValidity = (password) => {
  const firstCase = /^(?=.*?[A-Z])(?=.*?[0-9]).{8,30}$/;
  const secondCase = /^(?=.*?[A-Z_])(?=.*?[#?!@$%^&*-]).{8,30}$/;
  const thirdCase = /^(?=.*?[0-9_])(?=.*?[#?!@$%^&*-]).{8,30}$/;

  const passwordRegex = new RegExp(
    [firstCase, secondCase, thirdCase].map((regex) => regex.source).join("|"),
  );

  return passwordRegex.test(password);
};

export const isCurrentPasswordInvalid = (field, value) =>
  value.length > 0 &&
  !checkPasswordValidity(value) &&
  field === FIELD_NAMES.currentPassword;

export const hasNewPasswordSpaces = (field, value) =>
  value.indexOf(" ") >= 0 && field === FIELD_NAMES.newPassword;

export const isNewPasswordInvalid = (field, value) =>
  value.length > 0 &&
  !checkPasswordValidity(value) &&
  field === FIELD_NAMES.newPassword;

export const isPasswordSame = (field, currentPassword, newPassword) =>
  currentPassword.length > 0 &&
  newPassword.length > 0 &&
  currentPassword === newPassword &&
  field === FIELD_NAMES.newPassword;

export const isPasswordMismatched = (
  field,
  value,
  confirmPassword,
  newPassword,
) =>
  value.length > 0 &&
  confirmPassword !== newPassword &&
  field === FIELD_NAMES.confirmPassword;

export const getDepartmentIds = (departments = []) =>
  departments.map((r) => (DEPARTMENT.find((d) => d.value === r) || {}).id);

export const getTimeZoneHash = (timezone = "") =>
  TIMEZONES_OPTIONS.find((tz) => tz.value === timezone) || "";

export const getJobLevelHash = (jobLevel = "") =>
  JOB_LEVEL.find((jl) => jl.value === jobLevel) || "";
