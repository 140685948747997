import App from "~/app/app";
import { Provider as ReactReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "~/components/Error";
import { ToastProvider } from "~/components/vendorUI";
import store from "~/store";
import { CurrentCampaignProvider } from "../context/CurrentCampaignContext";
import { CampaignSwitcherProvider } from "~/context/CampaignSwitcher/CampaignSwitcher";
import { CurrentUserProvider } from "~/app/shared/contexts/currentUserContext";
import { FlashMessageProvider } from "~/app/shared/contexts/flashMessageContext";
import { Tooltip, DatePicker } from "~/components/vendorUI";
import { useTranslation } from "~/utils";
import { setAxiosFEConfig } from "~/utils/auth_axios";
import { useEffect } from "react";

const AppWrapper = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    setAxiosFEConfig();
  }, []);

  return (
    <BrowserRouter>
      <ReactReduxProvider store={store}>
        <ErrorBoundary>
          <ToastProvider locale={i18n.language}>
            <CurrentUserProvider>
              <CurrentCampaignProvider>
                <CampaignSwitcherProvider>
                  <FlashMessageProvider>
                    <Tooltip.Provider>
                      <DatePicker.Provider locale={i18n.language}>
                        <App />
                      </DatePicker.Provider>
                    </Tooltip.Provider>
                  </FlashMessageProvider>
                </CampaignSwitcherProvider>
              </CurrentCampaignProvider>
            </CurrentUserProvider>
          </ToastProvider>
        </ErrorBoundary>
      </ReactReduxProvider>
    </BrowserRouter>
  );
};

export default AppWrapper;
