export const SET_VENDOR = "SET_VENDOR";
export const RESET_VENDOR = "RESET_VENDOR";

const vendorReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_VENDOR:
      return action.payload;
    case RESET_VENDOR:
      return {};
    default:
      return state;
  }
};

export default vendorReducer;
