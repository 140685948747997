import { useState, useId } from "react";
import { Heading, Para, anchorCSS } from "~/components/Text";
import {
  Stack,
  Inline,
  Text,
  TextareaField,
  Icon,
  useToast,
} from "~/components/vendorUI";
import { Modal } from "@capterra/arubaito";
import { useTranslation, authAxios } from "~/utils";
import PropTypes from "prop-types";
import { css } from "ui/css";
import { Button } from "~/components/vendorUI";

const wrapperCSS = css({
  maxWidth: "350px",
  padding: "24px",
  background: "{colors.gray.1}",
});

const closeBtnCSS = css({
  "@layer base": { all: "unset" },
  cursor: "pointer",
});

export const PPLRaiseFreshDeskRequest = ({ type = "question" }) => {
  const reasonId = useId();
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requirement, setRequirement] = useState("");
  const { t } = useTranslation();
  const { addToast } = useToast();

  const heading = t(`SIDEBOX_PPL_TITLE${type === "support" ? "-SUPPORT" : ""}`);
  const text = t(`SIDEBOX_PPL_BODY${type === "support" ? "-SUPPORT" : ""}`);
  const cta = t(
    `SIDEBOX_PPL_REQUEST-ACTION${type === "support" ? "-SUPPORT" : ""}`,
  );

  const handleCancel = () => {
    setIsOpen(false);
    setRequirement("");
    setIsSubmitting(false);
  };

  const handleRaiseFreshdeskRequest = async () => {
    try {
      setIsSubmitting(true);
      await authAxios.post("/vp/api/ppl/generic_changes", {
        body: requirement,
      });
      addToast(t("SIDEBOX_PPL_REQUEST_MSG-SUCCESS"), {
        type: "success",
      });
      setRequirement("");
    } catch (err) {
      console.error(err);
      addToast(t("SIDEBOX_PPL_REQUEST_MSG-FAILURE"), {
        type: "error",
      });
    } finally {
      setIsSubmitting(false);
      setIsOpen(false);
    }
  };

  return (
    <Stack className={wrapperCSS} gap="24px">
      <Heading>{heading}</Heading>
      <Para>{text}</Para>
      <Button
        variant="tertiary"
        onClick={() => setIsOpen(true)}
        data-tracking={
          type === "question"
            ? "ppl_expansion_leads_have_question"
            : "ppl_refunds_need_support"
        }
      >
        {cta}
      </Button>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        {(close) => (
          <>
            <Modal.Body className={modalContentCSS}>
              <Stack gap="24px">
                <Inline distribute="between">
                  <Text size="20px" weight="regular" color="brand" asChild>
                    <h2>{t("SIDEBOX_PPL_REQUEST-TITLE")}</h2>
                  </Text>
                  <button type="button" className={closeBtnCSS} onClick={close}>
                    <Icon name="Cross" size="12px" color="steel" />
                  </button>
                </Inline>
                <TextareaField
                  id={reasonId}
                  label={t("SIDEBOX_PPL_REQUEST-LABEL")}
                  required={true}
                  value={requirement}
                  onChange={(e) => setRequirement(e.target.value)}
                />
              </Stack>
            </Modal.Body>
            <Modal.Footer className={modalFooterCSS}>
              <Inline distribute="end" gap="24px">
                <button
                  type="button"
                  onClick={handleCancel}
                  className={anchorCSS({ variant: "inverted" })}
                >
                  {t("ACTION_CANCEL")}
                </button>
                <Button
                  variant="primary"
                  onClick={handleRaiseFreshdeskRequest}
                  disabled={requirement.trim().length < 5}
                  isLoading={isSubmitting}
                  data-tracking={
                    type === "question"
                      ? "ppl_expansion_leads_have_question_submit"
                      : "ppl_refunds_need_support_submit"
                  }
                >
                  {t("ACTION_SUBMIT")}
                </Button>
              </Inline>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </Stack>
  );
};

const modalFooterCSS = css({
  background: "brandBlue",
  padding: "24px !important",
});

const modalContentCSS = css({
  padding: "24px !important",
});

PPLRaiseFreshDeskRequest.propTypes = {
  type: PropTypes.oneOf(["question", "support"]),
};
