import ExpansionLeadsTable from "../components/ExpansionLeadsTable";
import { Trans, useTranslation } from "~/utils";
import { Inline, Stack } from "~/components/vendorUI";
import { Title, SubHeading, List } from "~/components/Text";
import { PPLRaiseFreshDeskRequest } from "~/components";
import { css } from "ui/css";

const ExpansionLeads = () => {
  const { t } = useTranslation();

  return (
    <Stack gap="40px">
      <Inline distribute="between" align="start">
        <Stack className={halfStackCSS} gap="40px">
          <Title>{t("PPL_EXPANSION-LEADS_PAGE_TITLE")}</Title>
          <Stack gap="24px">
            <SubHeading>{t("PPL_EXPANSION-LEADS_PAGE_SUBTITLE")}</SubHeading>
            <List>
              {t("PPL_EXPANSION-LEADS_ADVANTAGES-LIST").map((item, idx) => (
                <List.Item key={idx}>{<Trans i18nKey={item} />}</List.Item>
              ))}
            </List>
          </Stack>
        </Stack>
        <PPLRaiseFreshDeskRequest />
      </Inline>
      <ExpansionLeadsTable />
    </Stack>
  );
};

const halfStackCSS = css({
  flexBasis: "55%",
});

export default ExpansionLeads;
