import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { fetchStates } from "./actions/fetch_states";
import { List } from "@capterra/arubaito";
import { useToast } from "~/components/vendorUI";
import { useTranslation } from "~/utils";
import { catchError } from "~/utils/catchError";

export const StatesDropdown = ({
  countryCode,
  onChange,
  selectedStateCode,
  className = "",
  includeCountryPrefix = true,
}) => {
  const [states, setStates] = useState([]);

  const { addToast } = useToast();
  const { t } = useTranslation();

  const selectedState = useMemo(() => {
    const state = states.find(({ value }) =>
      [selectedStateCode, `${countryCode}-${selectedStateCode}`].includes(
        value,
      ),
    );

    if (state) return { label: state.label, value: state.value };
  }, [states, selectedStateCode]);

  useEffect(() => {
    fetchStates(countryCode, includeCountryPrefix)
      .then(setStates)
      .catch((error) => {
        addToast(t("STATES_DROPDOWN-LOAD_ERROR"), { type: "error" });

        catchError(error, {
          reason: "[StatesDropdown] States could not be fetched",
        });
      });
  }, [countryCode]);

  return (
    <List
      className={className}
      isClearable
      onChange={onChange}
      options={states}
      placeholder={t("STATES_DROPDOWN-SELECT_A_STATE")}
      value={selectedState}
    />
  );
};

StatesDropdown.propTypes = {
  countryCode: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  selectedStateCode: PropTypes.string,
  includeCountryPrefix: PropTypes.bool,
};
