import { createContext, useContext, useRef, useState } from "react";
import { ConfirmationModal } from "~/components";

const ConfirmationServiceContext = createContext(
  Promise.reject.bind(Promise, ""),
);

export const useConfirmation = () => useContext(ConfirmationServiceContext);

export const ConfirmationServiceProvider = ({ children = <></> }) => {
  const [confirmationState, setConfirmationState] = useState({ open: false });

  const awaitingPromiseRef = useRef({
    resolve: () => null,
    reject: () => null,
  });

  const openConfirmation = (options) => {
    setConfirmationState(options);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleDecline = () =>
    awaitingPromiseRef.current && awaitingPromiseRef.current.reject("");

  const handleAccept = () =>
    awaitingPromiseRef.current && awaitingPromiseRef.current.resolve();

  const handleClosed = () => {
    setConfirmationState({ open: false });
  };

  return (
    <>
      <ConfirmationServiceContext.Provider value={openConfirmation}>
        {children}
      </ConfirmationServiceContext.Provider>
      {Boolean(confirmationState.open) && (
        <ConfirmationModal
          onAccept={handleAccept}
          onDecline={handleDecline}
          onClosed={handleClosed}
          {...confirmationState}
        />
      )}
    </>
  );
};

export default ConfirmationServiceProvider;
