import Client from "braintree-web/client";
import VaultManager from "braintree-web/vault-manager";

export const getBraintreeClient = async (token) => {
  try {
    const client = await Client.create({
      authorization: token,
      vaultManager: true,
    });
    const vaultManager = await VaultManager.create({ client });
    const paymentMethods = await vaultManager.fetchPaymentMethods();
    const card = paymentMethods.length && paymentMethods[0];

    return { client, card };
  } catch (err) {
    return {};
  }
};
