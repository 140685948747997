import { css } from "ui/css";

const styles = {
  "pending-panel": css({
    display: "flex",
    fontSize: "1.25em",
    fontWeight: "bold",
  }),
  "pending-number": css({
    backgroundColor: "tangerine",
    boxShadow: "0px 0px 0px 5px {colors.brandBlue.10}",
    textAlign: "center",
    width: "40px",
    height: "40px",
    lineHeight: 2,
    borderRadius: "50%",
  }),
  "pending-text": css({ lineHeight: 2, paddingLeft: "10px" }),
};

export default styles;
