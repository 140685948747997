import { RESET_VENDOR } from "./vendor_reducer";

export const SET_PRODUCTS = "SET_PRODUCTS";

const productsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return action.payload;
    case RESET_VENDOR:
      return [];
    default:
      return state;
  }
};

export default productsReducer;
