import { css } from "ui/css";

const styles = {
  "ppl-wrapper": css({
    width: "100%",
    maxWidth: "1200px",
    margin: "auto",
    position: "relative",
  }),
};

export default styles;
