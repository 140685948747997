import FilterSizes from "../components/filter_sizes";
import FilterIndustries from "../components/filter_industries";
import FilterFunctionalities from "../components/filter_functionalities";
import FilterDeploymentOptions from "../components/filter_deployment_options";
import FilterCountries from "../components/filter_countries";

export const bidFiltersConfig = (t) => [
  {
    heading: t("PPL_BID-FILTERS_SIZE-TITLE"),
    headingNote: t("PPL_BID-FILTERS_SIZE-PREFACE"),
    getComponent: ({ sizes }) => <FilterSizes sizes={sizes} />,
  },
  {
    heading: t("PPL_BID-FILTERS_INDUSTRIES-TITLE"),
    headingNote: t("PPL_BID-FILTERS_INDUSTRIES-PREFACE"),
    getComponent: ({ industries }) => (
      <FilterIndustries industries={industries} />
    ),
  },
  {
    heading: t("PPL_BID-FILTERS_FUNCTIONALITIES-TITLE"),
    headingNote: t("PPL_BID-FILTERS_FUNCTIONALITIES-PREFACE"),
    getComponent: ({ functionalities }) => (
      <FilterFunctionalities functionalities={functionalities} />
    ),
  },
  {
    heading: t("PPL_BID-FILTERS_DEPLOYMENT-TITLE"),
    headingNote: t("PPL_BID-FILTERS_DEPLOYMENT-PREFACE"),
    getComponent: ({ deploymentOptions }) => (
      <FilterDeploymentOptions deploymentOptions={deploymentOptions} />
    ),
  },
  {
    heading: t("PPL_BID-FILTERS_COUNTRIES-TITLE"),
    headingNote: t("PPL_BID-FILTERS_COUNTRIES-PREFACE"),
    getComponent: ({ countries }) => <FilterCountries countries={countries} />,
  },
];
