export const FIELD_NAMES = {
  firstName: "firstName",
  lastName: "lastName",
  jobLevel: "jobLevel",
  timezone: "timezone",
  departments: "departments",
  customDepartment: "customDepartment",
  currentPassword: "currentPassword",
  newPassword: "newPassword",
  confirmPassword: "confirmPassword",
};

export const REQUIRED_PROFILE_FIELDS = [
  "firstName",
  "lastName",
  "jobLevel",
  "departments",
];

export const CURRENT_PASSWORD_ERROR_MATCHER = "current password";
export const PASSWORD_FIELD_MATCHER = "Password";
export const ERROR_ALERT_TYPE = "error";
export const SUCCESS_ALERT_TYPE = "success";

export const DEPARTMENT = [
  {
    name: "Conversion Rate Optimization",
    value: "conversion_rate_optimization",
    id: "1",
  },
  { name: "Customer Success", id: "2", value: "customer_success" },
  { name: "Growth", id: "3", value: "growth" },
  { name: "Marketing", id: "4", value: "marketing" },
  { name: "Product", id: "5", value: "product" },
  { name: "Sales", id: "6", value: "sales" },
  { name: "Other", id: "7", value: "other" },
];

export const OTHER_DEPARTMENT_ID = "7";

export const JOB_LEVEL = [
  { label: "C-level", value: "c_level" },
  { label: "Vice President", value: "vice_president" },
  { label: "Director", value: "director" },
  { label: "Manager/ Head/ Lead", value: "manager_head_lead" },
  {
    label: "Associate/ Specialist / Technical / Junior",
    value: "associate_specialist_technical_junior",
  },
];
