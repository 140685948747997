import PropTypes from "prop-types";
import { Link } from "@remix-run/react";
import { Breadcrumb } from "@capterra/vendor-ui-components-breadcrumb";
import { updateConditionalBreadcrumbs } from "./utils/breadcrumb_utils";
import { useTranslation } from "~/utils";
import URL_TO_BREADCRUMBS_CONFIG from "./config.json";

export const BreadcrumbNav = ({ pathname }) => {
  const { t } = useTranslation();

  const matchedKey = Object.keys(URL_TO_BREADCRUMBS_CONFIG).find((re) =>
    new RegExp(re).test(pathname),
  );
  const breadcrumbs = URL_TO_BREADCRUMBS_CONFIG[matchedKey];

  if (
    !breadcrumbs &&
    !pathname.includes("/help-center") &&
    !pathname.includes("/reviews")
  )
    return null;

  updateConditionalBreadcrumbs(matchedKey, breadcrumbs);

  return (
    <div id="breadcrumbs-header">
      {!pathname.includes("/help-center") && !pathname.includes("/reviews") && (
        <Breadcrumb label={t("BREADCRUMBS_YOU-ARE-HERE_LABEL")}>
          {breadcrumbs.map(({ label, href, ...rest }) => (
            <Breadcrumb.Item key={label} {...rest}>
              {href ? <Link to={href}>{t(label)}</Link> : t(label)}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      )}
    </div>
  );
};

BreadcrumbNav.propTypes = {
  pathname: PropTypes.string,
  withBorder: PropTypes.bool,
};
