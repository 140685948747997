import setProducts from "./set_products";
import authFetch from "./auth_fetch";

const productsSort = (p1, p2) =>
  p1.productLabel.toLowerCase() < p2.productLabel.toLowerCase() ? -1 : 1;

const fetchProducts =
  (shouldRefresh = false) =>
  async (dispatch, getState) => {
    const {
      ppl: { products },
    } = getState();
    if (!shouldRefresh && products && products.length > 0) return products;

    const response = await authFetch("/products");

    if (!response.ok) {
      dispatch(setProducts([]));
      throw new Error(response.status === 404 ? 404 : response.statusText);
    }

    let productList = (await response.json()) || [];
    productList = productList
      .filter((p) => p.productBiddingAvailable)
      .map(({ productNamePpl, ...p }) => ({
        ...p,
        productName: p.productLabel,
        productLabel: productNamePpl,
      }));
    productList.sort(productsSort);

    dispatch(setProducts(productList));

    return null;
  };

export default fetchProducts;
