import cx from "classnames";
import { Link } from "@remix-run/react";
import { useDispatch } from "react-redux";
import { SHIFT_ALERT } from "~/pages/PPL/reducers/alerts";
import { CLEAR_CHANGED_BID } from "~/pages/PPL/reducers/changed_bids_reducer";
import { Modal } from "@capterra/arubaito";
import { Button } from "~/components/vendorUI";
import s from "./cancel_modal.module.scss";

const CancelModal = () => {
  const dispatch = useDispatch();

  return (
    <Modal
      open
      closeOnBackdropClick={false}
      onClosed={() => dispatch({ type: SHIFT_ALERT })}
    >
      {(close) => (
        <div className={s["modal-wrapper"]}>
          <Modal.Header>
            <span className="gdm-icon gdm-icon-md gdm-icon-alert-error" />
            <span className={cx("gdm-heading-lg", s["heading-label"])}>
              Unsaved Bids
            </span>
            <Modal.Close onClick={close} />
          </Modal.Header>
          <Modal.Body>
            <p className="gdm-paragraph-sm">
              If you leave this page without applying your new bids, that data
              will be lost.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              data-gtm="pplbidding-unsavedmodal-returnbutton"
              className="gdm-m-left-sm gdm-m-top-sm"
              variant="primary"
              onClick={close}
            >
              Return to Bids
            </Button>
            <Link
              className="gdm-link-dark gdm-block gdm-m-top-sm"
              to="/ppl/bids"
              data-gtm="pplbidding-unsavedmodal-cancelbutton"
              onClick={() => {
                dispatch({ type: CLEAR_CHANGED_BID });
                close();
              }}
            >
              Leave and abandon any changes
            </Link>
          </Modal.Footer>
        </div>
      )}
    </Modal>
  );
};

export default CancelModal;
