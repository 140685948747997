import { useCurrentUser } from "~/app/shared/contexts/currentUserContext";

const STATUS = {
  ACTIVE: "active",
  ONBOARDING: "onboarding",
};

const withActivePPLCampaign = (Component) => (props) => {
  const currentUser = useCurrentUser();
  const campaignStatus = currentUser?.selectedPPLCampaign?.status;

  if (campaignStatus !== STATUS.ACTIVE) return null;

  return <Component {...props} />;
};

export { withActivePPLCampaign };
