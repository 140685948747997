import { Fragment, useContext } from "react";
import cx from "classnames";
import {
  InputField,
  ToolTip,
  FormControlLabel,
  Checkbox,
} from "@capterra/arubaito";
import { gtmTracking, useTranslation } from "~/utils";
import ICONS from "../../assets/icons";
import s from "../../settings.module.scss";
import { AccountsContext } from "../../index";

const BraintreeCreditCardForm = ({
  id: payFor,
  cardTypeOptions,
  savedCCType,
  creditCardForm,
}) => {
  const { t } = useTranslation();
  const { handleCardEdit } = useContext(AccountsContext);
  const { creditCardType, paymentMethod, cardValidation, showCCErrors } =
    creditCardForm;

  const errors = {
    name: cardValidation.card_holder_name && showCCErrors,
    number: cardValidation.card_number && showCCErrors,
    cvv: cardValidation.cvv && showCCErrors,
    expiration: cardValidation.expiration_date && showCCErrors,
    card_type: !creditCardType && showCCErrors,
  };

  return (
    <form className={cx(s.flex, s.form, s.ccForm)} data-testid="braintree-form">
      {!!paymentMethod && (
        <div className={s.w24}>
          <FormControlLabel
            className={s.radioCb}
            label={t("ACCOUNT-SETTINGS_BILLING-FORM_CHECKBOX")}
            control={
              <Checkbox
                data-testid="account-settings-checkbox"
                name={`update_${payFor}_cc`}
                value={`${payFor}_cc`}
                checked={!creditCardForm.disableCardEdit}
                onChange={() => {
                  handleCardEdit(payFor);
                  if (!creditCardForm.disableCardEdit) {
                    gtmTracking("ppc_payment_update_credit_card");
                  }
                }}
              />
            }
          />
        </div>
      )}

      <InputField
        status={!creditCardForm.disableCardEdit && errors.name ? "error" : ""}
        className={s.w9}
        render={(id, status) => (
          <Fragment>
            <InputField.Label htmlFor={id}>
              {t("ACCOUNT-SETTINGS_BILLING-FORM_LABEL_CREDIT-CARD-NAME") + " "}
              {!creditCardForm.disableCardEdit && (
                <span className="gdm-icon gdm-icon-xs gdm-icon-required" />
              )}
            </InputField.Label>
            {creditCardForm.disableCardEdit && (
              <div className={cx(s.creditCardField, s.disabled)}>
                {paymentMethod?.details?.cardholderName}
              </div>
            )}
            <div
              className={cx(s.creditCardField, `cc${payFor}Name`, {
                [s.invalid]: errors.name,
                "gdm-hidden": creditCardForm.disableCardEdit,
              })}
            />
            <InputField.AlertMessage status={status}>
              {t("ACCOUNT-SETTINGS_BILLING-FORM_ERROR_CREDIT-CARD-NAME")}
            </InputField.AlertMessage>
          </Fragment>
        )}
      />
      <InputField
        status={
          !creditCardForm.disableCardEdit && errors.card_type ? "error" : ""
        }
        className={s.w5}
        render={(id, status) => (
          <Fragment>
            <InputField.Label htmlFor={id}>
              <div className={cx(s.flex, s.yCenter)}>
                <span className="gdm-label gdm-m-bottom-none">
                  {t("ACCOUNT-SETTINGS_BILLING-FORM_LABEL_CREDIT-CARD-TYPE")}
                </span>
                &nbsp;&nbsp;
                <div id={`card_type_${payFor}_lbl`} />
                <ToolTip
                  targetId={`card_type_${payFor}_lbl`}
                  trigger={
                    <span className="gdm-icon gdm-icon-sm gdm-icon-info gdm-align-bottom" />
                  }
                >
                  {t("ACCOUNT-SETTINGS_BILLING-FORM_TOOLTIP_CREDIT-CARD-TYPE", {
                    cardTypes: cardTypeOptions
                      .slice(1)
                      .map((o) => o.label)
                      .join(", "),
                  })}
                </ToolTip>
              </div>
            </InputField.Label>
            <div className={cx(s.creditCardField, s.disabled)}>
              {
                cardTypeOptions.filter(
                  ({ value }) =>
                    value ===
                    (creditCardForm.disableCardEdit
                      ? savedCCType
                      : creditCardType || !!creditCardType),
                )[0]?.label
              }
            </div>
            <InputField.AlertMessage status={status}>
              {t("ACCOUNT-SETTINGS_BILLING-FORM_ERROR_CREDIT-CARD-TYPE")}
            </InputField.AlertMessage>
          </Fragment>
        )}
      />
      <InputField
        status={!creditCardForm.disableCardEdit && errors.number ? "error" : ""}
        className={cx(s.w9, "gdm-relative")}
        render={(id, status) => (
          <Fragment>
            <InputField.Label htmlFor={id}>
              {t("ACCOUNT-SETTINGS_BILLING-FORM_LABEL_CREDIT-CARD-NUMBER") +
                " "}
              {!creditCardForm.disableCardEdit && (
                <span className="gdm-icon gdm-icon-xs gdm-icon-required" />
              )}
            </InputField.Label>
            {creditCardForm.disableCardEdit && (
              <div className={cx(s.creditCardField, s.disabled)}>
                xxxx-xxxx-xxxx-{paymentMethod?.details?.lastFour}
              </div>
            )}
            <div
              className={cx(s.creditCardField, `cc${payFor}Number`, {
                [s.invalid]: errors.number,
                "gdm-hidden": creditCardForm.disableCardEdit,
              })}
            />
            <div id="card-image" className={s.cardImage}>
              {
                ICONS[
                  creditCardForm.disableCardEdit ? savedCCType : creditCardType
                ]
              }
            </div>
            <InputField.AlertMessage status={status}>
              {t("ACCOUNT-SETTINGS_BILLING-FORM_ERROR_CREDIT-CARD-NUMBER")}
            </InputField.AlertMessage>
          </Fragment>
        )}
      />
      <InputField
        status={
          !creditCardForm.disableCardEdit && errors.expiration ? "error" : ""
        }
        className={s.w5}
        render={(id, status) => (
          <Fragment>
            <InputField.Label htmlFor={id}>
              {t(
                "ACCOUNT-SETTINGS_BILLING-FORM_LABEL_CREDIT-CARD-EXPIRATION-DATE",
              ) + " "}
              {!creditCardForm.disableCardEdit && (
                <span className="gdm-icon gdm-icon-xs gdm-icon-required" />
              )}
            </InputField.Label>
            {creditCardForm.disableCardEdit && (
              <div className={cx(s.creditCardField, s.disabled)}>
                {paymentMethod?.details?.expirationMonth +
                  " / " +
                  paymentMethod?.details?.expirationYear.slice(-2)}
              </div>
            )}
            <div
              className={cx(s.creditCardField, `cc${payFor}Expiration`, {
                [s.invalid]: errors.expiration,
                "gdm-hidden": creditCardForm.disableCardEdit,
              })}
            />
            <InputField.AlertMessage status={status}>
              {t(
                "ACCOUNT-SETTINGS_BILLING-FORM_ERROR_CREDIT-CARD-EXPIRATION-DATE",
              )}
            </InputField.AlertMessage>
          </Fragment>
        )}
      />
      <InputField
        status={errors.cvv ? "error" : ""}
        className={cx(s.w5, { "gdm-hidden": creditCardForm.disableCardEdit })}
        render={(id, status) => (
          <Fragment>
            <InputField.Label htmlFor={id}>
              {t("ACCOUNT-SETTINGS_BILLING-FORM_LABEL_CREDIT-CARD-CVV") + " "}
              <span className="gdm-icon gdm-icon-xs gdm-icon-required" />
            </InputField.Label>
            <div
              className={cx(s.creditCardField, `cc${payFor}CVV`, {
                [s.invalid]: errors.cvv,
              })}
            />
            <InputField.AlertMessage status={status}>
              {t("ACCOUNT-SETTINGS_BILLING-FORM_ERROR_CREDIT-CARD-CVV")}
            </InputField.AlertMessage>
          </Fragment>
        )}
      />
    </form>
  );
};

export default BraintreeCreditCardForm;
