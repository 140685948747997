import { RESET_VENDOR } from "./vendor_reducer";

export const SET_MARKETS = "SET_MARKETS";

const marketsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_MARKETS:
      return action.payload;
    case RESET_VENDOR:
      return [];
    default:
      return state;
  }
};

export default marketsReducer;
