import { useEffect, useState } from "react";
import { LoadingOverlay } from "~/components";
import { useSelector, useDispatch } from "react-redux";
import fetchFilters from "~/pages/PPL/actions/bid_filters/fetch_filters";
import BidFiltersHeader from "./components/bid_filters_header";
import { bidFiltersConfig } from "./config/bid_filters_config";
import { UpdateFiltersInfoBox } from "./components/update_filters_info_box";
import { useTranslation } from "~/utils";
import { Loading } from "~/components/vendorUI";
import { css, cx } from "ui/css";

const loadingWrapperCSS = css({
  padding: "8px",
});

const BidFilters = () => {
  const dispatch = useDispatch();
  const {
    markets,
    selectedMarket,
    products,
    selectedProduct,
    selectedProductFilters,
    isLoading,
  } = useSelector((state) => state.ppl);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [isFetchError, setFetchError] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchFilters())
      .then(() => {
        setFetchError(false);
      })
      .catch(() => {
        setFetchError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedProduct?.productId]);

  return (
    <div className="gdm-grid">
      <div className="gdm-row">
        {isLoading && <LoadingOverlay />}
        <div className="gdm-w-24 gdm-p-left-xs gdm-title">
          {t("PPL_BID-FILTERS_PAGE_TITLE")}
        </div>
        <div className="gdm-col gdm-col-16">
          <BidFiltersHeader
            markets={markets}
            selectedMarket={selectedMarket}
            products={products}
            selectedProduct={selectedProduct}
            dispatch={dispatch}
          />
          {isFetchError ? (
            <div className="gdm-w-24 gdm-label">
              {t("PPL_BID-FILTERS_PAGE_ERROR")}
            </div>
          ) : (
            bidFiltersConfig(t).map(
              ({ heading, headingNote, getComponent }) => (
                <div key={heading} className="gdm-m-top-md">
                  <div className="gdm-heading-lg">{heading}</div>
                  <div
                    className={cx(
                      "gdm-paragraph-sm",
                      css({ fontSize: "16px !" }),
                    )}
                  >
                    {headingNote}
                  </div>
                  <div className="gdm-row gdm-m-top-md">
                    {loading ? (
                      <div className={loadingWrapperCSS}>
                        <Loading size="16px" />
                      </div>
                    ) : (
                      getComponent(selectedProductFilters)
                    )}
                  </div>
                </div>
              ),
            )
          )}
        </div>
        <div className="gdm-col gdm-col-8">
          <UpdateFiltersInfoBox />
        </div>
      </div>
    </div>
  );
};

export default BidFilters;
