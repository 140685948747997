import { useEffect } from "react";
import { useNavigate } from "@remix-run/react";

const defaultRoute = "/ppl/bids";
const defaultExecute = () => {};

const useRedirectOnError = (
  execute = defaultExecute,
  toRoute = defaultRoute,
  dependency = [],
) => {
  const navigate = useNavigate();

  useEffect(() => {
    const executeFn = async () => {
      const response = await execute();
      if (!response) {
        navigate(toRoute);
      }
    };
    executeFn();
  }, [...dependency]);
};

export default useRedirectOnError;
