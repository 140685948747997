import PropTypes from "prop-types";
import { css } from "ui/css";

const scrollContentsCSS = css({ overflowY: "scroll", maxHeight: "300px" });

const ChangedBidsBody = ({ children }) => (
  <div className="gdm-w-18">
    <div className={scrollContentsCSS}>{children}</div>
  </div>
);

ChangedBidsBody.propTypes = {
  children: PropTypes.node,
};

export default ChangedBidsBody;
