import { authAxios as instance } from "~/utils";

export const { get } = instance;
export const { patch } = instance;
export const { post } = instance;
export const { put } = instance;
export const remove = instance.delete;
export const runInParallel = instance.all;

export default instance;
