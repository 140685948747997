import PropTypes from "prop-types";
import { useTranslation } from "~/utils";
import { Inline, Text } from "~/components/vendorUI";
import { css } from "ui/css";

const radioCSS = css({
  accentColor: "{colors.steel}",
  opacity: 0.9,
  width: "14px",
  height: "14px",
  marginBlock: "8px",
});

const RadioButtonView = ({ functionality = { weight: null } } = {}) => {
  const { t } = useTranslation();

  const options = [
    { label: t("PPL_BID-FILTERS_RADIO-OPTION-0"), value: 0 },
    { label: t("PPL_BID-FILTERS_RADIO-OPTION-1"), value: 1 },
    { label: t("PPL_BID-FILTERS_RADIO-OPTION-3"), value: 3 },
    { label: t("PPL_BID-FILTERS_RADIO-OPTION-4"), value: 4 },
  ];

  return (
    <div className="gdm-m-top-xs">
      {options.map((option) => (
        <Inline key={`${option.label}:${option.value}`} align="center">
          <input
            className={radioCSS}
            type="radio"
            name={functionality.title}
            id={`${functionality.title}:${option.label}:${option.value}`}
            value={option.value}
            checked={option.value === functionality.weight}
            readOnly
          />
          <Text size="16px" color="secondary" asChild>
            <label
              htmlFor={`${functionality.title}:${option.label}:${option.value}`}
            >
              {option.label}
            </label>
          </Text>
        </Inline>
      ))}
    </div>
  );
};

RadioButtonView.propTypes = {
  functionality: PropTypes.shape({ weight: PropTypes.number }),
};

export default RadioButtonView;
