import { token } from "ui/tokens";

export const STRIPE_ELEMENT_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: token.var("colors.steel"),
      color: token.var("colors.black"),
      fontFamily: token.var("fonts.graphik"),
      fontSize: token.var("fontSize.14px"),
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: token.var("colors.steel"),
      },
      "::placeholder": {
        color: token.var("colors.steel"),
      },
    },
    invalid: {
      iconColor: token.var("colors.red"),
      color: token.var("colors.red"),
    },
  },
  hidePostalCode: true,
};
