export const LABELS = {
  firstName: "LABEL_FIRST-NAME",
  lastName: "LABEL_LAST-NAME",
  jobLevel: "MY-PROFILE_LABEL_JOB-LEVEL",
  timezone: "MY-PROFILE_LABEL_TIMEZONE",
  department: "MY-PROFILE_LABEL_DEPARTMENT",
  customDepartment: "MY-PROFILE_LABEL_CUSTOM-DEPARTMENT",
  currentPassword: "CURRENT_PASSWORD",
  newPassword: "NEW_PASSWORD",
  confirmPassword: "MY-PROFILE_LABEL_CONFIRM-PASSWORD",
};

export const MESSAGES = {
  invalidPassword: "MY-PROFILE_INVALID-PASSWORD",
  invalidPasswordSpaces: "MY-PROFILE_INVALID-PASSWORD-SPACES",
  incorrectCurrentPassword: "MY-PROFILE_INCORRECT-CURRENT-PASSWORD",
  mismatchedPassword: "MY-PROFILE_MISMATCHED-PASSWORD",
  samePassword: "MY-PROFILE_SAME-PASSWORD",
  profileUpdateError: "MY-PROFILE_UPDATE-ERROR",
  profileUpdateSuccess: "MY-PROFILE_UPDATE-SUCCESS",
  validationError: "MY-PROFILE_VALIDATION-ERROR",
};

export const PLACEHOLDERS = {
  firstName: "MY-PROFILE_PLACEHOLDER_FIRST-NAME",
  lastName: "MY-PROFILE_PLACEHOLDER_LAST-NAME",
  jobLevel: "MY-PROFILE_PLACEHOLDER_JOB-LEVEL",
  timezone: "MY-PROFILE_PLACEHOLDER_TIMEZONE",
  department: "MY-PROFILE_PLACEHOLDER_DEPARTMENT",
  customDepartment: "MY-PROFILE_PLACEHOLDER_CUSTOM-DEPARTMENT",
  currentPassword: "MY-PROFILE_PLACEHOLDER_CURRENT-PASSWORD",
  newPassword: "MY-PROFILE_PLACEHOLDER_NEW-PASSWORD",
  confirmPassword: "MY-PROFILE_PLACEHOLDER_CONFIRM-PASSWORD",
};

export const TITLES = {
  myProfile: "MY-PROFILE_PAGE_TITLE",
  changePassword: "CHANGE_PASSWORD",
};
