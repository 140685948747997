const SvgDiscover = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={70} height={50} {...props}>
    <g fill="none" fillRule="nonzero">
      <path
        fill="#F47216"
        d="M36.833 19c2.922 0 5.29 2.227 5.29 4.977v.003c0 2.75-2.368 4.98-5.29 4.98-2.921 0-5.29-2.23-5.29-4.98v-.003c0-2.75 2.369-4.977 5.29-4.977"
      />
      <path
        fill="#000"
        d="M28.526 19.064c.834 0 1.534.169 2.386.575v2.149c-.807-.743-1.507-1.054-2.432-1.054-1.82 0-3.25 1.418-3.25 3.216 0 1.895 1.387 3.229 3.34 3.229.88 0 1.566-.295 2.342-1.025v2.149c-.882.391-1.597.545-2.432.545-2.953 0-5.248-2.133-5.248-4.885 0-2.722 2.355-4.899 5.294-4.899m-9.17.06a4.5 4.5 0 0 1 2.922 1.037l-1.015 1.251c-.505-.533-.983-.758-1.564-.758-.836 0-1.445.448-1.445 1.038 0 .506.342.773 1.506 1.178 2.206.76 2.86 1.433 2.86 2.92 0 1.813-1.414 3.075-3.43 3.075-1.476 0-2.549-.548-3.442-1.782L17 25.947c.446.813 1.191 1.248 2.117 1.248.865 0 1.505-.562 1.505-1.32 0-.393-.194-.73-.581-.969-.195-.112-.582-.28-1.34-.533-1.822-.617-2.447-1.277-2.447-2.567 0-1.532 1.342-2.683 3.101-2.683m22.163.163h2.119l2.652 6.288 2.686-6.288h2.102l-4.295 9.602h-1.044zm-28.441.014h1.94v9.361h-1.94zm38.877 0h5.5v1.586h-3.561v2.078h3.43v1.586h-3.43v2.527h3.562v1.584h-5.5zm10.915 5.418c1.46-.28 2.264-1.22 2.264-2.654 0-1.753-1.281-2.764-3.518-2.764H58.74v9.362h1.937v-3.761h.253l2.685 3.76H66zm-1.626-1.108h-.567v-2.836h.598c1.207 0 1.864.477 1.864 1.388 0 .941-.656 1.448-1.895 1.448M6.847 19.3H4v9.361h2.832c1.506 0 2.593-.334 3.548-1.08 1.134-.884 1.805-2.216 1.805-3.594 0-2.763-2.193-4.687-5.338-4.687m2.266 7.033c-.61.517-1.401.744-2.654.744h-.521v-6.19h.52c1.254 0 2.014.21 2.655.756a3.07 3.07 0 0 1 1.074 2.33c0 .9-.404 1.797-1.074 2.36"
      />
    </g>
  </svg>
);
export default SvgDiscover;
