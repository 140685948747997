import PropTypes from "prop-types";
import cx from "classnames";
import CheckboxView from "./checkbox_view";

const FilterIndustries = ({ industries = [] }) => {
  if (industries.length === 0) return null;

  return (
    <div className={cx("gdm-col gdm-col-24")}>
      <CheckboxView
        items={industries}
        wrapperStyle={{
          columns: "3",
        }}
      />
    </div>
  );
};

FilterIndustries.propTypes = {
  industries: PropTypes.arrayOf(PropTypes.object),
};

export default FilterIndustries;
