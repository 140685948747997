import isEqual from "lodash.isequal";
import { OTHER_DEPARTMENT_ID } from "../config/constants";

export const INITIALIZE = "INITIALIZE";
export const CHANGE_FIELD = "CHANGE_FIELD";
export const CLEAN = "CLEAN";
export const SET_ALERT = "SET_ALERT";
export const SET_ERROR = "SET_ERROR";
export const SET_LOADING = "SET_LOADING";

export const INITIAL_STATE = {
  firstName: {
    initial: "",
    current: "",
    error: false,
  },
  lastName: {
    initial: "",
    current: "",
    error: false,
  },
  jobLevel: {
    initial: "",
    current: "",
    error: false,
  },
  timezone: {
    initial: "",
    current: "",
    error: false,
  },
  departments: {
    initial: [],
    current: [],
    error: false,
  },
  customDepartment: {
    initial: "",
    current: "",
    error: false,
  },
  currentPassword: {
    initial: "",
    current: "",
    error: false,
  },
  newPassword: {
    initial: "",
    current: "",
    error: false,
  },
  confirmPassword: {
    initial: "",
    current: "",
    error: false,
  },
  alert: {
    visible: false,
    type: "",
    message: "",
  },
  isLoading: true,
  hasChanged: false,
};

const setInitialFormState = (state, payload) => {
  const {
    firstName,
    lastName,
    jobLevel,
    timezone,
    departments,
    customDepartment,
  } = payload;

  const newState = {
    ...state,
    firstName: {
      ...state.firstName,
      initial: firstName,
      current: firstName,
    },
    lastName: {
      ...state.lastName,
      initial: lastName,
      current: lastName,
    },
    jobLevel: {
      ...state.jobLevel,
      initial: jobLevel,
      current: jobLevel,
    },
    timezone: {
      ...state.timezone,
      initial: timezone,
      current: timezone,
    },
    departments: {
      ...state.departments,
      initial: departments,
      current: departments,
    },
    customDepartment: {
      ...state.customDepartment,
      initial: customDepartment,
      current: customDepartment,
    },
    isLoading: false,
  };

  return {
    ...newState,
    hasChanged: hasChanged(newState),
  };
};

const resetFormState = (state) => {
  const newState = {
    ...state,
    firstName: {
      ...state.firstName,
      current: state.firstName.initial,
    },
    lastName: {
      ...state.lastName,
      current: state.lastName.initial,
    },
    jobLevel: {
      ...state.jobLevel,
      current: state.jobLevel.initial,
    },
    timezone: {
      ...state.timezone,
      current: state.timezone.initial,
    },
    departments: {
      ...state.departments,
      current: state.departments.initial,
    },
    customDepartment: {
      ...state.customDepartment,
      current: state.customDepartment.initial,
    },
    currentPassword: {
      ...state.currentPassword,
      current: state.currentPassword.initial,
    },
    newPassword: {
      ...state.newPassword,
      current: state.newPassword.initial,
    },
    confirmPassword: {
      ...state.confirmPassword,
      current: state.confirmPassword.initial,
    },
  };

  return {
    ...newState,
    isLoading: false,
    hasChanged: hasChanged(newState),
  };
};

const hasChanged = (state) => {
  return (
    (state.firstName.current !== state.firstName.initial ||
      state.lastName.current !== state.lastName.initial ||
      !isEqual(state.jobLevel.current, state.jobLevel.initial) ||
      !isEqual(state.timezone.current, state.timezone.initial) ||
      !isEqual(state.departments.current, state.departments.initial) ||
      state.customDepartment.current !== state.customDepartment.initial ||
      state.currentPassword.current !== state.currentPassword.initial ||
      state.newPassword.current) !== state.newPassword.initial ||
    state.confirmPassword.current !== state.confirmPassword.initial
  );
};

const hasOtherDepartment = (selectedDepartments = []) =>
  selectedDepartments.indexOf(OTHER_DEPARTMENT_ID) >= 0;

const myProfileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        ...state,
        ...setInitialFormState(state, action.initialProfile),
      };
    case CHANGE_FIELD:
      const newState = {
        ...state,
        [action.field]: {
          ...state[action.field],
          current: action.value,
        },
      };
      return {
        ...newState,
        customDepartment: {
          ...newState.customDepartment,
          initial: hasOtherDepartment(newState.departments.initial)
            ? newState.customDepartment.initial
            : "",
          current: hasOtherDepartment(newState.departments.current)
            ? newState.customDepartment.current
            : "",
        },
        hasChanged: hasChanged(newState),
      };
    case CLEAN:
      return resetFormState(state);
    case SET_ALERT:
      return {
        ...state,
        alert: {
          visible: action.visible,
          type: action.alertType,
          message: action.message,
        },
      };
    case SET_ERROR:
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          error: action.error,
        },
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};

export default myProfileReducer;
