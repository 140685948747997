import PropTypes from "prop-types";
import cx from "classnames";
import CheckboxView from "./checkbox_view";
import RadioButtonView from "./radio_button_view";
import s from "../bid_filters.module.scss";
import { useTranslation } from "~/utils";

const FilterFunctionalities = ({ functionalities = [] }) => {
  const { t } = useTranslation();

  if (functionalities.length === 0) return null;

  return (
    <>
      {functionalities.map(
        (functionality) =>
          Boolean(functionality.title) && (
            <div
              key={`${functionality.tooltip}:${functionality.title}`}
              className="gdm-col gdm-col-8 gdm-m-bottom-sm"
            >
              <CheckboxView
                label={functionality.title}
                tooltipText={functionality.tooltip}
                isChecked={functionality.mustBeSold}
                formControlLabelText={t("PPL_BID-FILTERS_LABEL_MUST-SOLD")}
              />
              <RadioButtonView functionality={functionality} />
              {functionality.features.length > 0 && (
                <>
                  <hr />
                  <div
                    className={cx(
                      "gdm-m-top-xs",
                      s["scrollable-checkbox-view"],
                    )}
                  >
                    <CheckboxView items={functionality.features} />
                  </div>
                </>
              )}
            </div>
          ),
      )}
    </>
  );
};

FilterFunctionalities.propTypes = {
  functionalities: PropTypes.arrayOf(PropTypes.object),
};

export default FilterFunctionalities;
