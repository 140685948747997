import { ErrorBoundary } from "~/components/Error";
import Alerts from "~/pages/PPL/pages/alerts";
import Routes from "./routes/routes";
import s from "./index.styles.ts";

import { Font } from "@react-pdf/renderer";

Font.register({
  family: "GraphikRegular",
  src: "https://cdn0.capterra-static.com/assets/fonts/graphik/Graphik-Regular-Web.ttf",
});

Font.register({
  family: "GraphikItalicRegular",
  src: "https://cdn0.capterra-static.com/assets/fonts/graphik/Graphik-Regular-Italic-Web.ttf",
});

Font.register({
  family: "GraphikMedium",
  src: "https://cdn0.capterra-static.com/assets/fonts/graphik/Graphik-Medium-Web.ttf",
});

Font.register({
  family: "GraphikItalicMedium",
  src: "https://cdn0.capterra-static.com/assets/fonts/graphik/Graphik-Medium-Italic-Web.ttf",
});

Font.register({
  family: "GraphikBold",
  src: "https://cdn0.capterra-static.com/assets/fonts/graphik/Graphik-Bold-Web.ttf",
});

export const PPL = () => {
  return (
    <ErrorBoundary context="ppl">
      <div className={s["ppl-wrapper"]} id="ppl-anchor">
        <Routes />
        <Alerts />
      </div>
    </ErrorBoundary>
  );
};
