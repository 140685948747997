import PropTypes from "prop-types";
import CheckboxView from "./checkbox_view";
import { useTranslation } from "~/utils";

const FilterDeploymentOptions = ({ deploymentOptions = [] }) => {
  const { t } = useTranslation();

  const helperText = {
    "On-premise": t("PPL_BID-FILTERS_DEPLOYMENT_LABEL-HELPER_ON-PREMISE"),
    "Web-based": t("PPL_BID-FILTERS_DEPLOYMENT_LABEL-HELPER_WEB-BASED"),
  };

  if (deploymentOptions.length === 0) return null;

  return (
    <div className="gdm-col">
      {deploymentOptions.map((deploymentOption) => (
        <div key={deploymentOption.id}>
          <CheckboxView
            label={deploymentOption.name}
            labelHelperText={helperText[deploymentOption.name]}
            isChecked={deploymentOption.status === "active"}
            hideLabel
          />
        </div>
      ))}
    </div>
  );
};

FilterDeploymentOptions.propTypes = {
  deploymentOptions: PropTypes.arrayOf(PropTypes.object),
};

export default FilterDeploymentOptions;
