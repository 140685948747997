import { Suspense, lazy } from "react";
import { Switch, Redirect } from "react-router-dom";
import { useLocation } from "@remix-run/react";
import { LoadingOverlay } from "~/components";
import { useCurrentUser } from "~/app/shared/contexts/currentUserContext";
import EditBids from "~/pages/PPL/pages/edit_bids";
import ChangedBids from "~/pages/PPL/pages/changed_bids";
import {
  ExpansionLeads,
  PrePuchaseLeadDetails,
  PrePuchaseLeadConfirm,
} from "~/pages/PPL/pages/expansion_leads";
import BidFilters from "~/pages/PPL/pages/bid_filters";
import { ProtectedRoute } from "~/components/ProtectedRoute";

const CRMIntegration = lazy(() => import("~/pages/PPL/pages/crm_integration"));

export default function Routes() {
  const location = useLocation();
  const presenter = location?.state?.presenter;
  const { user } = useCurrentUser();
  const hasAccessTo = (permission) => user.permissions?.[permission]?.hasAccess;
  const shouldRedirectToLeads =
    !hasAccessTo("ppl_bidding") && hasAccessTo("ppl_leads");

  return (
    <>
      <Switch location={presenter || location}>
        <Redirect from="/" to="/ppl/bids" exact />
        {shouldRedirectToLeads ? (
          <Redirect from="/ppl/bids" to="/ppl/leads" exact />
        ) : null}

        <ProtectedRoute
          exact
          path={["/ppl/expansionleads", "/ppl/expansion-leads"]}
          children={ExpansionLeads}
          hasAccess={hasAccessTo("ppl_bidding")}
        />
        <ProtectedRoute
          exact
          path={[
            "/ppl/expansionleads/:expansionLeadID",
            "/ppl/expansion-leads/:expansionLeadID",
          ]}
          children={PrePuchaseLeadDetails}
          hasAccess={hasAccessTo("ppl_bidding")}
        />
        <ProtectedRoute
          exact
          path={[
            "/ppl/expansionleads/:expansionLeadID/purchase",
            "/ppl/expansion-leads/:expansionLeadID/purchase",
          ]}
          children={PrePuchaseLeadConfirm}
          hasAccess={hasAccessTo("ppl_bidding")}
        />

        <ProtectedRoute
          exact
          path="/ppl/bids/filters"
          children={BidFilters}
          hasAccess={hasAccessTo("ppl_bidding")}
        />
        <ProtectedRoute
          exact
          path="/ppl/bids/edit/:productId"
          children={EditBids}
          hasAccess={hasAccessTo("ppl_bidding")}
        />

        <Suspense fallback={<LoadingOverlay />}>
          <ProtectedRoute
            exact
            path="/ppl/crm-integration"
            children={CRMIntegration}
            hasAccess={hasAccessTo("ppl_leads")}
          />
        </Suspense>

        <Redirect from="*" to="/404" />
      </Switch>
      <Switch>
        <ProtectedRoute
          exact
          path="/ppl/bids/edit/:productId"
          children={ChangedBids}
          hasAccess={hasAccessTo("ppl_bidding")}
        />
      </Switch>
    </>
  );
}
