import thunk from "redux-thunk";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import pplReducers from "~/pages/PPL/reducers";

const rootReducer = combineReducers({
  ppl: combineReducers(pplReducers),
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);

export default store;
