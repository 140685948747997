import { SET_IS_LOADING } from "~/pages/PPL/reducers/loading_reducer";

export default function setIsLoading(isLoading) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_LOADING,
      payload: isLoading,
    });
  };
}
