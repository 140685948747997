import { RESET_VENDOR } from "./vendor_reducer";

export const SET_LEAD_DETAILS = "SET_LEAD_DETAILS";
export const UNSET_LEAD_DETAILS = "UNSET_LEAD_DETAILS";

const leadDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_LEAD_DETAILS:
      return action.payload;
    case UNSET_LEAD_DETAILS:
      return {};
    case RESET_VENDOR:
      return {};
    default:
      return state;
  }
};

export default leadDetailsReducer;
