import { SET_EXP_LEAD_DETAILS } from "~/pages/PPL/reducers/expansion_leads_reducer";
import { authAxios } from "~/utils";
import setIsLoading from "../set_is_loading";

const fetchLeadDetails = (expansionLeadID) => async (dispatch, getState) => {
  const {
    ppl: { expansionLeads },
  } = getState();

  if (
    Object.keys(expansionLeads).includes(expansionLeadID) &&
    !expansionLeads[expansionLeadID].error
  )
    return;

  try {
    dispatch(setIsLoading(true));
    const { data: details } = await authAxios.get(
      `/vp/api/ppl/expansion_leads/${expansionLeadID}`,
    );

    dispatch({
      type: SET_EXP_LEAD_DETAILS,
      payload: {
        expansionLeadID,
        details: details.expansion_lead,
        error: false,
      },
    });
    dispatch(setIsLoading(false));
  } catch (err) {
    console.error(err.message);
    dispatch({
      type: SET_EXP_LEAD_DETAILS,
      payload: {
        expansionLeadID,
        details: {
          error: err.response.status,
        },
      },
    });
  }
};

export default fetchLeadDetails;
