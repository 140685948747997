import { useContext } from "react";
import { useTranslation } from "~/utils";
import { Text } from "~/components/vendorUI";
import { AccountsContext } from "~/pages/AccountSettings/index";
import PayByInvoice from "~/pages/AccountSettings/components/PayByInvoice";
import PayByCreditCard from "~/pages/AccountSettings/components/PayByCreditCard";
import { Stack } from "~/components/vendorUI";

const PPCBilling = () => {
  const {
    payBy,
    ppcForm,
    handlePPCForm,
    ppcAddr,
    handlePPCAddr,
    errors,
    handleErrors,
    touched,
    handleTouched,
    ppcPaymentGateway,
    ppcToken,
    ppcCreditCardForm,
    handlePPCCreditCard,
  } = useContext(AccountsContext);
  const { t } = useTranslation();

  return (
    <Stack gap="24px" asChild>
      <section>
        <Text size="14px" color="brand" weight="regular">
          {t("ACCOUNT-SETTINGS_PPC-BILLING-FORM_PAYMENT-TYPE", {
            paymentType: t(
              `ACCOUNT-SETTINGS_PAYMENT-TYPE_${
                payBy === "check" ? "INVOICE" : "CREDITCARD"
              }`,
            ).toLowerCase(),
          })}
          .
        </Text>

        {payBy === "check" && (
          <PayByInvoice
            email={ppcForm.billing_email}
            handleEmail={(email) => handlePPCForm("billing_email", email)}
            touched={touched.ppcForm.billing_email}
            errors={errors.ppcForm.billing_email}
            handleTouched={(err) =>
              handleTouched("ppcForm", "billing_email", err)
            }
            handleErrors={(err) =>
              handleErrors("ppcForm", "billing_email", err)
            }
          />
        )}
        {payBy === "cc" && (
          <PayByCreditCard
            state={ppcAddr}
            handleChange={handlePPCAddr}
            touched={touched.ppcAddr}
            errors={errors.ppcAddr}
            handleTouched={(name, value) =>
              handleTouched("ppcAddr", name, value)
            }
            handleErrors={(name, value) => handleErrors("ppcAddr", name, value)}
            token={ppcToken}
            paymentGateway={ppcPaymentGateway}
            creditCardForm={ppcCreditCardForm}
            handleCreditCard={handlePPCCreditCard}
            id="PPC"
          />
        )}
      </section>
    </Stack>
  );
};

export default PPCBilling;
