import "@capterra/arubaito/dist/utility.min.css";
import "@capterra/arubaito/dist/arubaito_component.min.css";
import { UserDataProvider } from "~/context/UserData";
import App from "~/app/index.client";
import { useCurrentUser } from "app/hooks/useCurrentUser";

export default function LegacyApp() {
  const session = useCurrentUser();

  return (
    <UserDataProvider value={session}>
      <App />
    </UserDataProvider>
  );
}
