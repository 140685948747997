import PropTypes from "prop-types";
import s from "./pending_changes.styles.ts";
import { useTranslation } from "~/utils";

export default function PendingChanges({ count = 0 }) {
  const { t } = useTranslation();

  return (
    <div className={s["pending-panel"]}>
      <div className={s["pending-number"]}>{count}</div>
      <div className={s["pending-text"]}>
        {t("DRAWER_PENDING_TITLE", { count })}
      </div>
    </div>
  );
}

PendingChanges.propTypes = {
  count: PropTypes.number,
};
