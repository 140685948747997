import { axios } from "~/utils/newVendorPortalAxios";

const removeCountryPrefix = (stateCode = "") => stateCode.split("-")[1];

export const fetchStates = async (countryCode, includeCountryPrefix = true) => {
  const response = await axios.get("/states", {
    params: { countryCode },
  });

  const { states = [] } = response.data || {};

  return states.map(({ code, name }) => ({
    value: includeCountryPrefix ? code : removeCountryPrefix(code),
    label: name,
  }));
};
