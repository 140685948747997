import cx from "classnames";
import PropTypes from "prop-types";
import {
  AlertMessage,
  InputText,
  List,
  MultiSelectGroup,
} from "@capterra/arubaito";
import {
  DEPARTMENT,
  FIELD_NAMES,
  JOB_LEVEL,
  OTHER_DEPARTMENT_ID,
} from "../config/constants";
import { LABELS, PLACEHOLDERS } from "../config/copy";
import { ERROR_ALERT_TYPE } from "../config/constants";
import { useTranslation } from "~/utils";
import mStyles from "../MyProfile.module.scss";

const ProfileForm = ({
  firstName,
  lastName,
  jobLevel,
  timezone,
  timezoneOptions,
  selectedDepartments,
  customDepartment,
  handleChange,
  handleListChange,
  hasError,
  handleBlur,
  isLoading,
}) => {
  const { t } = useTranslation();

  const getAlertMessage = (formKey) => {
    return hasError(formKey) ? (
      <AlertMessage className="gdm-m-top-xs" status={ERROR_ALERT_TYPE}>
        {t("MY-PROFILE_ALERT_IS-REQUIRED", { labelName: t(LABELS[formKey]) })}
      </AlertMessage>
    ) : (
      <div className={mStyles.error_placeholder} />
    );
  };

  return (
    <>
      <div className="gdm-row gdm-m-bottom-md">
        <div className="gdm-col gdm-col-8 gdm-m-right-md">
          <div>
            <span className="gdm-label gdm-inline-block gdm-m-bottom-xs">
              {t(LABELS.firstName)}
            </span>
            <span className="gdm-icon gdm-icon-xs gdm-icon-required gdm-m-left-xxs" />
            <InputText
              value={firstName}
              name={FIELD_NAMES.firstName}
              placeholder={t(PLACEHOLDERS.firstName)}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {getAlertMessage(FIELD_NAMES.firstName)}
          </div>
        </div>
        <div className="gdm-col gdm-col-8 gdm-m-left-md">
          <span className="gdm-label gdm-inline-block gdm-m-bottom-xs">
            {t(LABELS.lastName)}
          </span>
          <span className="gdm-icon gdm-icon-xs gdm-icon-required gdm-m-left-xxs" />
          <InputText
            value={lastName}
            name={FIELD_NAMES.lastName}
            placeholder={t(PLACEHOLDERS.lastName)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {getAlertMessage(FIELD_NAMES.lastName)}
        </div>
      </div>
      <div className="gdm-row gdm-m-bottom-md">
        <div className="gdm-col gdm-col-8 gdm-m-right-md">
          <span className="gdm-label gdm-inline-block gdm-m-bottom-xs">
            {t(LABELS.jobLevel)}
          </span>
          <span className="gdm-icon gdm-icon-xs gdm-icon-required gdm-m-left-xxs" />
          <List
            name={FIELD_NAMES.jobLevel}
            placeholder={t(PLACEHOLDERS.jobLevel)}
            onChange={(value) => handleListChange(FIELD_NAMES.jobLevel, value)}
            value={jobLevel}
            options={JOB_LEVEL}
          />
        </div>
        <div className="gdm-col gdm-col-8 gdm-m-left-md">
          <span className="gdm-label gdm-inline-block gdm-m-bottom-xs">
            {t(LABELS.timezone)}
          </span>
          <List
            name={FIELD_NAMES.timezone}
            placeholder={t(PLACEHOLDERS.timezone)}
            onChange={(value) => handleListChange(FIELD_NAMES.timezone, value)}
            value={timezone}
            options={timezoneOptions}
          />
        </div>
      </div>
      <div className="gdm-row gdm-m-bottom-sm">
        <div
          className={cx("gdm-col gdm-col-9", mStyles.department_multiselect)}
        >
          <MultiSelectGroup
            setSelected={(value) =>
              handleListChange(FIELD_NAMES.departments, value)
            }
            initialList={DEPARTMENT}
            initialSelected={selectedDepartments}
            displayValue="name"
            label={
              <>
                <span className="gdm-label">{t(LABELS.department)}</span>
                <span className="gdm-inline-block gdm-icon gdm-icon-xs gdm-icon-required gdm-m-left-xxs gdm-m-bottom-xxs" />
              </>
            }
            searchableColumns={["name"]}
            renderChild={({ name, value }) => <span id={value}>{name}</span>}
            isListSet={!isLoading}
            setList={() => {}}
            showSelected
          />
        </div>
        {selectedDepartments.indexOf(OTHER_DEPARTMENT_ID) >= 0 && (
          <div className={cx("gdm-col gdm-col-8", mStyles.other_department)}>
            <span className="gdm-label gdm-inline-block gdm-m-bottom-xs">
              {t(LABELS.customDepartment)}
            </span>
            <InputText
              value={customDepartment}
              name={FIELD_NAMES.customDepartment}
              placeholder={t(PLACEHOLDERS.customDepartment)}
              onChange={handleChange}
              onBlur={() => {}}
              autoComplete="off"
            />
          </div>
        )}
      </div>
    </>
  );
};

ProfileForm.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  jobLevel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ]),
  timezone: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ]),
  timezoneOptions: PropTypes.array,
  initialDepartments: PropTypes.array,
  customDepartment: PropTypes.string,
  handleChange: PropTypes.func,
  handleListChange: PropTypes.func,
  selectedDepartments: PropTypes.array,
  isListSet: PropTypes.bool,
  setList: PropTypes.func,
  setSelected: PropTypes.func,
  hasError: PropTypes.func,
  handleBlur: PropTypes.func,
};

export default ProfileForm;
