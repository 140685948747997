import { authAxios as axios } from "~/utils";

export const fetchPpcAccountSettings = async () => {
  try {
    const response = await axios.get("/vp/api/ppc/account_settings");
    const settings = response.data || {};
    return settings;
  } catch (err) {
    return {};
  }
};
