import PropTypes from "prop-types";
import cx from "classnames";
import { useDispatch } from "react-redux";
import { DELETE_CHANGED_BID } from "~/pages/PPL/reducers/changed_bids_reducer";
import { formatCurrency } from "~/utils";
import s from "./changed_bids_table.styles.ts";
import { useTranslation } from "~/utils";

export default function ChangedBidsTable({ product, changedBids }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { name: productName } = product;

  return (
    <div>
      <table className={s.contentTableOverview}>
        <colgroup>
          <col />
          <col span="2" />
          <col span="2" className="current" />
        </colgroup>
        <thead>
          <tr>
            <th />
            <th />
            <th />
            <th />
            <th />
          </tr>
          <tr>
            <th />
            <th colSpan="2">
              <span>{t("PPL_EDIT-BIDDING_SUMMARY_OLD-VALUES")}</span>
            </th>
            <th colSpan="2" className="strong">
              <span>{t("PPL_EDIT-BIDDING_SUMMARY_NEW-VALUES")}</span>
            </th>
          </tr>
          <tr>
            <th>
              {t("PPL_EDIT-BIDDING_SUMMARY_REVIEW-CHANGES-TITLE", {
                productName,
              })}
            </th>
            <th>{t("PPL_EDIT-BIDDING_SUMMARY_BID")}</th>
            <th>{t("PPL_EDIT-BIDDING_SUMMARY_MAX-COST")}</th>
            <th className="strong">{t("PPL_EDIT-BIDDING_SUMMARY_BID")}</th>
            <th className="strong">{t("PPL_EDIT-BIDDING_SUMMARY_MAX-COST")}</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(changedBids).map(([bidKey, bid]) => {
            if (bid.invalid) return undefined;

            return (
              <tr key={bidKey}>
                <th>
                  <button
                    type="button"
                    data-gtm="pplbidding-drawer-bidremoval"
                    className="remove"
                    onClick={() =>
                      dispatch({ type: DELETE_CHANGED_BID, key: bidKey })
                    }
                  >
                    {t("ACTION_REMOVE")}
                  </button>
                  <span
                    className={cx(
                      "gdm-icon gdm-icon-close gdm-icon-sm",
                      "icon-t",
                    )}
                  />
                  {bid.label}
                </th>
                <td>
                  {bid.oldValues && formatCurrency(bid.oldValues.bidAmount)}
                </td>
                <td>
                  {bid.oldValues && formatCurrency(bid.oldValues.maxCost)}
                </td>
                <td className="strong">
                  {bid.newValues && formatCurrency(bid.newValues.bidAmount)}
                </td>
                <td className="strong">
                  {bid.newValues && formatCurrency(bid.newValues.maxCost)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

ChangedBidsTable.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  changedBids: PropTypes.shape({}).isRequired,
};
