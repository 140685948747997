export const CARD_TYPES_MAPPINGS = {
  amex: "amex",
  "american-express": "amex",
  visa: "visa",
  Visa: "visa",
  "American Express": "amex",
  "master-card": "mc",
  "Master Card": "mc",
  MasterCard: "mc",
  Mastercard: "mc",
  mastercard: "mc",
  jcb: "jcb",
  Jcb: "jcb",
  JCB: "jcb",
  Discover: "discover",
  discover: "discover",
};

export const TIMEZONES = {
  "International Date Line West": "(GMT-12:00) International Date Line West",
  "American Samoa": "(GMT-11:00) American Samoa",
  "Midway Island": "(GMT-11:00) Midway Island",
  Hawaii: "(GMT-10:00) Hawaii",
  Alaska: "(GMT-09:00) Alaska",
  "Pacific Time (US & Canada)": "(GMT-08:00) Pacific Time (US & Canada)",
  Tijuana: "(GMT-08:00) Tijuana",
  Arizona: "(GMT-07:00) Arizona",
  Chihuahua: "(GMT-07:00) Chihuahua",
  Mazatlan: "(GMT-07:00) Mazatlan",
  "Mountain Time (US & Canada)": "(GMT-07:00) Mountain Time (US & Canada)",
  "Central America": "(GMT-06:00) Central America",
  "Central Time (US & Canada)": "(GMT-06:00) Central Time (US & Canada)",
  Guadalajara: "(GMT-06:00) Guadalajara",
  "Mexico City": "(GMT-06:00) Mexico City",
  Monterrey: "(GMT-06:00) Monterrey",
  Saskatchewan: "(GMT-06:00) Saskatchewan",
  Bogota: "(GMT-05:00) Bogota",
  "Eastern Time (US & Canada)": "(GMT-05:00) Eastern Time (US & Canada)",
  "Indiana (East)": "(GMT-05:00) Indiana (East)",
  Lima: "(GMT-05:00) Lima",
  Quito: "(GMT-05:00) Quito",
  "Atlantic Time (Canada)": "(GMT-04:00) Atlantic Time (Canada)",
  Caracas: "(GMT-04:00) Caracas",
  Georgetown: "(GMT-04:00) Georgetown",
  "La Paz": "(GMT-04:00) La Paz",
  "Puerto Rico": "(GMT-04:00) Puerto Rico",
  Santiago: "(GMT-04:00) Santiago",
  Newfoundland: "(GMT-03:30) Newfoundland",
  Brasilia: "(GMT-03:00) Brasilia",
  "Buenos Aires": "(GMT-03:00) Buenos Aires",
  Greenland: "(GMT-03:00) Greenland",
  Montevideo: "(GMT-03:00) Montevideo",
  "Mid-Atlantic": "(GMT-02:00) Mid-Atlantic",
  Azores: "(GMT-01:00) Azores",
  "Cape Verde Is.": "(GMT-01:00) Cape Verde Is.",
  Casablanca: "(GMT+00:00) Casablanca",
  Dublin: "(GMT+00:00) Dublin",
  Edinburgh: "(GMT+00:00) Edinburgh",
  Lisbon: "(GMT+00:00) Lisbon",
  London: "(GMT+00:00) London",
  Monrovia: "(GMT+00:00) Monrovia",
  UTC: "(GMT+00:00) UTC",
  Amsterdam: "(GMT+01:00) Amsterdam",
  Belgrade: "(GMT+01:00) Belgrade",
  Berlin: "(GMT+01:00) Berlin",
  Bern: "(GMT+01:00) Bern",
  Bratislava: "(GMT+01:00) Bratislava",
  Brussels: "(GMT+01:00) Brussels",
  Budapest: "(GMT+01:00) Budapest",
  Copenhagen: "(GMT+01:00) Copenhagen",
  Ljubljana: "(GMT+01:00) Ljubljana",
  Madrid: "(GMT+01:00) Madrid",
  Paris: "(GMT+01:00) Paris",
  Prague: "(GMT+01:00) Prague",
  Rome: "(GMT+01:00) Rome",
  Sarajevo: "(GMT+01:00) Sarajevo",
  Skopje: "(GMT+01:00) Skopje",
  Stockholm: "(GMT+01:00) Stockholm",
  Vienna: "(GMT+01:00) Vienna",
  Warsaw: "(GMT+01:00) Warsaw",
  "West Central Africa": "(GMT+01:00) West Central Africa",
  Zagreb: "(GMT+01:00) Zagreb",
  Zurich: "(GMT+01:00) Zurich",
  Athens: "(GMT+02:00) Athens",
  Bucharest: "(GMT+02:00) Bucharest",
  Cairo: "(GMT+02:00) Cairo",
  Harare: "(GMT+02:00) Harare",
  Helsinki: "(GMT+02:00) Helsinki",
  Jerusalem: "(GMT+02:00) Jerusalem",
  Kaliningrad: "(GMT+02:00) Kaliningrad",
  Kyiv: "(GMT+02:00) Kyiv",
  Pretoria: "(GMT+02:00) Pretoria",
  Riga: "(GMT+02:00) Riga",
  Sofia: "(GMT+02:00) Sofia",
  Tallinn: "(GMT+02:00) Tallinn",
  Vilnius: "(GMT+02:00) Vilnius",
  Baghdad: "(GMT+03:00) Baghdad",
  Istanbul: "(GMT+03:00) Istanbul",
  Kuwait: "(GMT+03:00) Kuwait",
  Minsk: "(GMT+03:00) Minsk",
  Moscow: "(GMT+03:00) Moscow",
  Nairobi: "(GMT+03:00) Nairobi",
  Riyadh: "(GMT+03:00) Riyadh",
  "St. Petersburg": "(GMT+03:00) St. Petersburg",
  Tehran: "(GMT+03:30) Tehran",
  "Abu Dhabi": "(GMT+04:00) Abu Dhabi",
  Baku: "(GMT+04:00) Baku",
  Muscat: "(GMT+04:00) Muscat",
  Samara: "(GMT+04:00) Samara",
  Tbilisi: "(GMT+04:00) Tbilisi",
  Volgograd: "(GMT+04:00) Volgograd",
  Yerevan: "(GMT+04:00) Yerevan",
  Kabul: "(GMT+04:30) Kabul",
  Ekaterinburg: "(GMT+05:00) Ekaterinburg",
  Islamabad: "(GMT+05:00) Islamabad",
  Karachi: "(GMT+05:00) Karachi",
  Tashkent: "(GMT+05:00) Tashkent",
  Chennai: "(GMT+05:30) Chennai",
  Kolkata: "(GMT+05:30) Kolkata",
  Mumbai: "(GMT+05:30) Mumbai",
  "New Delhi": "(GMT+05:30) New Delhi",
  "Sri Jayawardenepura": "(GMT+05:30) Sri Jayawardenepura",
  Kathmandu: "(GMT+05:45) Kathmandu",
  Almaty: "(GMT+06:00) Almaty",
  Astana: "(GMT+06:00) Astana",
  Dhaka: "(GMT+06:00) Dhaka",
  Urumqi: "(GMT+06:00) Urumqi",
  Rangoon: "(GMT+06:30) Rangoon",
  Bangkok: "(GMT+07:00) Bangkok",
  Hanoi: "(GMT+07:00) Hanoi",
  Jakarta: "(GMT+07:00) Jakarta",
  Krasnoyarsk: "(GMT+07:00) Krasnoyarsk",
  Novosibirsk: "(GMT+07:00) Novosibirsk",
  Beijing: "(GMT+08:00) Beijing",
  Chongqing: "(GMT+08:00) Chongqing",
  "Hong Kong": "(GMT+08:00) Hong Kong",
  Irkutsk: "(GMT+08:00) Irkutsk",
  "Kuala Lumpur": "(GMT+08:00) Kuala Lumpur",
  Perth: "(GMT+08:00) Perth",
  Singapore: "(GMT+08:00) Singapore",
  Taipei: "(GMT+08:00) Taipei",
  Ulaanbaatar: "(GMT+08:00) Ulaanbaatar",
  Osaka: "(GMT+09:00) Osaka",
  Sapporo: "(GMT+09:00) Sapporo",
  Seoul: "(GMT+09:00) Seoul",
  Tokyo: "(GMT+09:00) Tokyo",
  Yakutsk: "(GMT+09:00) Yakutsk",
  Adelaide: "(GMT+09:30) Adelaide",
  Darwin: "(GMT+09:30) Darwin",
  Brisbane: "(GMT+10:00) Brisbane",
  Canberra: "(GMT+10:00) Canberra",
  Guam: "(GMT+10:00) Guam",
  Hobart: "(GMT+10:00) Hobart",
  Melbourne: "(GMT+10:00) Melbourne",
  "Port Moresby": "(GMT+10:00) Port Moresby",
  Sydney: "(GMT+10:00) Sydney",
  Vladivostok: "(GMT+10:00) Vladivostok",
  Magadan: "(GMT+11:00) Magadan",
  "New Caledonia": "(GMT+11:00) New Caledonia",
  "Solomon Is.": "(GMT+11:00) Solomon Is.",
  Srednekolymsk: "(GMT+11:00) Srednekolymsk",
  Auckland: "(GMT+12:00) Auckland",
  Fiji: "(GMT+12:00) Fiji",
  Kamchatka: "(GMT+12:00) Kamchatka",
  "Marshall Is.": "(GMT+12:00) Marshall Is.",
  Wellington: "(GMT+12:00) Wellington",
  "Chatham Is.": "(GMT+12:45) Chatham Is.",
  "Nuku'alofa": "(GMT+13:00) Nuku'alofa",
  Samoa: "(GMT+13:00) Samoa",
  "Tokelau Is.": "(GMT+13:00) Tokelau Is.",
};

export const COUNTRIES = [
  {
    id: 1,
    code: "US",
    name: "United States",
    states: [
      { code: "MD", name: "Maryland" },
      { code: "MA", name: "Massachusetts" },
      { code: "MN", name: "Minnesota" },
      { code: "MO", name: "Missouri" },
      { code: "MT", name: "Montana" },
      { code: "NE", name: "Nebraska" },
      { code: "NV", name: "Nevada" },
      { code: "NH", name: "New Hampshire" },
      { code: "NJ", name: "New Jersey" },
      { code: "NM", name: "New Mexico" },
      { code: "NY", name: "New York" },
      { code: "NC", name: "North Carolina" },
      { code: "ND", name: "North Dakota" },
      { code: "OH", name: "Ohio" },
      { code: "OK", name: "Oklahoma" },
      { code: "OR", name: "Oregon" },
      { code: "PA", name: "Pennsylvania" },
      { code: "RI", name: "Rhode Island" },
      { code: "SC", name: "South Carolina" },
      { code: "SD", name: "South Dakota" },
      { code: "TN", name: "Tennessee" },
      { code: "TX", name: "Texas" },
      { code: "UT", name: "Utah" },
      { code: "VT", name: "Vermont" },
      { code: "VA", name: "Virginia" },
      { code: "WA", name: "Washington" },
      { code: "DC", name: "District Of Columbia" },
      { code: "PR", name: "Puerto Rico" },
      { code: "VI", name: "Virgin Islands" },
      { code: "AL", name: "Alabama" },
      { code: "AK", name: "Alaska" },
      { code: "AZ", name: "Arizona" },
      { code: "AR", name: "Arkansas" },
      { code: "CA", name: "California" },
      { code: "CO", name: "Colorado" },
      { code: "CT", name: "Connecticut" },
      { code: "DE", name: "Delaware" },
      { code: "FL", name: "Florida" },
      { code: "GA", name: "Georgia" },
      { code: "HI", name: "Hawaii" },
      { code: "ID", name: "Idaho" },
      { code: "IL", name: "Illinois" },
      { code: "IN", name: "Indiana" },
      { code: "IA", name: "Iowa" },
      { code: "KS", name: "Kansas" },
      { code: "KY", name: "Kentucky" },
      { code: "LA", name: "Louisiana" },
      { code: "ME", name: "Maine" },
      { code: "MI", name: "Michigan" },
      { code: "MS", name: "Mississippi" },
      { code: "WV", name: "West Virginia" },
      { code: "WI", name: "Wisconsin" },
      { code: "WY", name: "Wyoming" },
      { code: "GU", name: "Guam" },
    ],
  },
  {
    id: 2,
    code: "CA",
    name: "Canada",
  },
  {
    id: 3,
    code: "MX",
    name: "Mexico",
  },
  {
    id: 4,
    code: "AI",
    name: "Anguilla",
  },
  {
    id: 5,
    code: "AG",
    name: "Antigua and Barbuda",
  },
  {
    id: 6,
    code: "AW",
    name: "Aruba",
  },
  {
    id: 7,
    code: "BS",
    name: "Bahamas",
  },
  {
    id: 8,
    code: "BB",
    name: "Barbados",
  },
  {
    id: 9,
    code: "BM",
    name: "Bermuda",
  },
  {
    id: 10,
    code: "KY",
    name: "Cayman Islands",
  },
  {
    id: 11,
    code: "DM",
    name: "Dominica",
  },
  {
    id: 12,
    code: "DO",
    name: "Dominican Republic",
  },
  {
    id: 13,
    code: "GD",
    name: "Grenada",
  },
  {
    id: 14,
    code: "GP",
    name: "Guadeloupe",
  },
  {
    id: 15,
    code: "HT",
    name: "Haiti",
  },
  {
    id: 16,
    code: "JM",
    name: "Jamaica",
  },
  {
    id: 17,
    code: "MQ",
    name: "Martinique",
  },
  {
    id: 18,
    code: "MS",
    name: "Montserrat",
  },
  {
    id: 19,
    code: "AN",
    name: "Netherlands Antilles",
  },
  {
    id: 20,
    code: "PR",
    name: "Puerto Rico",
  },
  {
    id: 21,
    code: "KN",
    name: "Saint Kitts and Nevis",
  },
  {
    id: 22,
    code: "LC",
    name: "Saint Lucia",
  },
  {
    id: 23,
    code: "VC",
    name: "Saint Vincent and The Grenadines",
  },
  {
    id: 24,
    code: "TT",
    name: "Trinidad and Tobago",
  },
  {
    id: 25,
    code: "TC",
    name: "Turks and Caicos Islands",
  },
  {
    id: 26,
    code: "VG",
    name: "Virgin Islands, British",
  },
  {
    id: 27,
    code: "VI",
    name: "Virgin Islands, U.S.",
  },
  {
    id: 28,
    code: "AD",
    name: "Andorra",
  },
  {
    id: 29,
    code: "AE",
    name: "United Arab Emirates",
  },
  {
    id: 30,
    code: "AF",
    name: "Afghanistan",
  },
  {
    id: 31,
    code: "AL",
    name: "Albania",
  },
  {
    id: 32,
    code: "AM",
    name: "Armenia",
  },
  {
    id: 33,
    code: "AO",
    name: "Angola",
  },
  {
    id: 34,
    code: "AR",
    name: "Argentina",
  },
  {
    id: 35,
    code: "AS",
    name: "American Samoa",
  },
  {
    id: 36,
    code: "AT",
    name: "Austria",
  },
  {
    id: 37,
    code: "AU",
    name: "Australia",
  },
  {
    id: 38,
    code: "AX",
    name: "Aland Islands",
  },
  {
    id: 39,
    code: "AZ",
    name: "Azerbaijan",
  },
  {
    id: 40,
    code: "BA",
    name: "Bosnia and Herzegovina",
  },
  {
    id: 41,
    code: "BD",
    name: "Bangladesh",
  },
  {
    id: 42,
    code: "BE",
    name: "Belgium",
  },
  {
    id: 43,
    code: "BF",
    name: "Burkina Faso",
  },
  {
    id: 44,
    code: "BG",
    name: "Bulgaria",
  },
  {
    id: 45,
    code: "BH",
    name: "Bahrain",
  },
  {
    id: 46,
    code: "BI",
    name: "Burundi",
  },
  {
    id: 47,
    code: "BJ",
    name: "Benin",
  },
  {
    id: 48,
    code: "BL",
    name: "Saint Barthélemy",
  },
  {
    id: 49,
    code: "BN",
    name: "Brunei Darussalam",
  },
  {
    id: 50,
    code: "BO",
    name: "Bolivia",
  },
  {
    id: 51,
    code: "BR",
    name: "Brazil",
  },
  {
    id: 52,
    code: "BT",
    name: "Bhutan",
  },
  {
    id: 53,
    code: "BU",
    name: "Burma",
  },
  {
    id: 54,
    code: "BW",
    name: "Botswana",
  },
  {
    id: 55,
    code: "BY",
    name: "Belarus",
  },
  {
    id: 56,
    code: "BZ",
    name: "Belize",
  },
  {
    id: 57,
    code: "CD",
    name: "Democratic Republic of the Congo",
  },
  {
    id: 58,
    code: "CF",
    name: "Central African Republic",
  },
  {
    id: 59,
    code: "CG",
    name: "Congo",
  },
  {
    id: 60,
    code: "CH",
    name: "Switzerland",
  },
  {
    id: 61,
    code: "CI",
    name: "Côte d'Ivoire",
  },
  {
    id: 62,
    code: "CK",
    name: "Cook Islands",
  },
  {
    id: 63,
    code: "CL",
    name: "Chile",
  },
  {
    id: 64,
    code: "CM",
    name: "Cameroon",
  },
  {
    id: 65,
    code: "CN",
    name: "China",
  },
  {
    id: 66,
    code: "CO",
    name: "Colombia",
  },
  {
    id: 67,
    code: "CR",
    name: "Costa Rica",
  },
  {
    id: 68,
    code: "CU",
    name: "Cuba",
  },
  {
    id: 69,
    code: "CV",
    name: "Cape Verde",
  },
  {
    id: 70,
    code: "CY",
    name: "Cyprus",
  },
  {
    id: 71,
    code: "CZ",
    name: "Czech Republic",
  },
  {
    id: 72,
    code: "DD",
    name: "German Democratic Republic",
  },
  {
    id: 73,
    code: "DE",
    name: "Germany",
  },
  {
    id: 74,
    code: "DJ",
    name: "Djibouti",
  },
  {
    id: 75,
    code: "DK",
    name: "Denmark",
  },
  {
    id: 76,
    code: "DZ",
    name: "Algeria",
  },
  {
    id: 77,
    code: "EC",
    name: "Ecuador",
  },
  {
    id: 78,
    code: "EE",
    name: "Estonia",
  },
  {
    id: 79,
    code: "EG",
    name: "Egypt",
  },
  {
    id: 80,
    code: "EH",
    name: "Western Sahara*",
  },
  {
    id: 81,
    code: "ER",
    name: "Eritrea",
  },
  {
    id: 82,
    code: "ES",
    name: "Spain",
  },
  {
    id: 83,
    code: "ET",
    name: "Ethiopia",
  },
  {
    id: 84,
    code: "FI",
    name: "Finland",
  },
  {
    id: 85,
    code: "FJ",
    name: "Fiji",
  },
  {
    id: 86,
    code: "FK",
    name: "Falkland Islands (Malvinas)",
  },
  {
    id: 87,
    code: "FM",
    name: "Micronesia",
  },
  {
    id: 88,
    code: "FO",
    name: "Faroe Islands",
  },
  {
    id: 89,
    code: "FR",
    name: "France",
  },
  {
    id: 90,
    code: "FX",
    name: "France, Metropolitan",
  },
  {
    id: 91,
    code: "GA",
    name: "Gabon",
  },
  {
    id: 92,
    code: "GB",
    name: "United Kingdom",
  },
  {
    id: 93,
    code: "GE",
    name: "Georgia",
  },
  {
    id: 94,
    code: "GF",
    name: "French Guiana",
  },
  {
    id: 95,
    code: "GG",
    name: "Guernsey",
  },
  {
    id: 96,
    code: "GH",
    name: "Ghana",
  },
  {
    id: 97,
    code: "GI",
    name: "Gibraltar",
  },
  {
    id: 98,
    code: "GL",
    name: "Greenland",
  },
  {
    id: 99,
    code: "GM",
    name: "Gambia",
  },
  {
    id: 100,
    code: "GN",
    name: "Guinea",
  },
  {
    id: 101,
    code: "GQ",
    name: "Equatorial Guinea",
  },
  {
    id: 102,
    code: "GR",
    name: "Greece",
  },
  {
    id: 103,
    code: "GT",
    name: "Guatemala",
  },
  {
    id: 104,
    code: "GU",
    name: "Guam",
  },
  {
    id: 105,
    code: "GW",
    name: "Guinea Bissau",
  },
  {
    id: 106,
    code: "GY",
    name: "Guyana",
  },
  {
    id: 107,
    code: "HK",
    name: "Hong Kong",
  },
  {
    id: 108,
    code: "HN",
    name: "Honduras",
  },
  {
    id: 109,
    code: "HR",
    name: "Croatia",
  },
  {
    id: 110,
    code: "HU",
    name: "Hungary",
  },
  {
    id: 111,
    code: "ID",
    name: "Indonesia",
  },
  {
    id: 112,
    code: "IE",
    name: "Ireland",
  },
  {
    id: 113,
    code: "IL",
    name: "Israel",
  },
  {
    id: 114,
    code: "IM",
    name: "Isle of Man",
  },
  {
    id: 115,
    code: "IN",
    name: "India",
  },
  {
    id: 116,
    code: "IQ",
    name: "Iraq",
  },
  {
    id: 117,
    code: "IR",
    name: "Iran",
  },
  {
    id: 118,
    code: "IS",
    name: "Iceland",
  },
  {
    id: 119,
    code: "IT",
    name: "Italy",
  },
  {
    id: 120,
    code: "JE",
    name: "Jersey",
  },
  {
    id: 121,
    code: "JO",
    name: "Jordan",
  },
  {
    id: 122,
    code: "JP",
    name: "Japan",
  },
  {
    id: 123,
    code: "KE",
    name: "Kenya",
  },
  {
    id: 124,
    code: "KG",
    name: "Kyrgyzstan",
  },
  {
    id: 125,
    code: "KH",
    name: "Cambodia",
  },
  {
    id: 126,
    code: "KI",
    name: "Kiribati",
  },
  {
    id: 127,
    code: "KM",
    name: "Comoros",
  },
  {
    id: 128,
    code: "KP",
    name: "Korea (the Democratic People's Republic of)",
  },
  {
    id: 129,
    code: "KR",
    name: "Korea",
  },
  {
    id: 130,
    code: "KW",
    name: "Kuwait",
  },
  {
    id: 131,
    code: "KZ",
    name: "Kazakhstan",
  },
  {
    id: 132,
    code: "LA",
    name: "Laos",
  },
  {
    id: 133,
    code: "LB",
    name: "Lebanon",
  },
  {
    id: 134,
    code: "LI",
    name: "Liechtenstein",
  },
  {
    id: 135,
    code: "LK",
    name: "Sri Lanka",
  },
  {
    id: 136,
    code: "LR",
    name: "Liberia",
  },
  {
    id: 137,
    code: "LS",
    name: "Lesotho",
  },
  {
    id: 138,
    code: "LT",
    name: "Lithuania",
  },
  {
    id: 139,
    code: "LU",
    name: "Luxembourg",
  },
  {
    id: 140,
    code: "LV",
    name: "Latvia",
  },
  {
    id: 141,
    code: "LY",
    name: "Libya",
  },
  {
    id: 142,
    code: "MA",
    name: "Morocco",
  },
  {
    id: 143,
    code: "MC",
    name: "Monaco",
  },
  {
    id: 144,
    code: "MD",
    name: "Moldova",
  },
  {
    id: 145,
    code: "ME",
    name: "Montenegro",
  },
  {
    id: 146,
    code: "MF",
    name: "Saint Martin",
  },
  {
    id: 147,
    code: "MG",
    name: "Madagascar",
  },
  {
    id: 148,
    code: "MH",
    name: "Marshall Islands",
  },
  {
    id: 149,
    code: "MK",
    name: "North Macedonia",
  },
  {
    id: 150,
    code: "ML",
    name: "Mali",
  },
  {
    id: 151,
    code: "MM",
    name: "Myanmar",
  },
  {
    id: 152,
    code: "MN",
    name: "Mongolia",
  },
  {
    id: 153,
    code: "MO",
    name: "Macao",
  },
  {
    id: 154,
    code: "MP",
    name: "Northern Mariana Islands",
  },
  {
    id: 155,
    code: "MR",
    name: "Mauritania",
  },
  {
    id: 157,
    code: "MU",
    name: "Mauritius",
  },
  {
    id: 158,
    code: "MV",
    name: "Maldives",
  },
  {
    id: 159,
    code: "MW",
    name: "Malawi",
  },
  {
    id: 160,
    code: "MY",
    name: "Malaysia",
  },
  {
    id: 161,
    code: "MZ",
    name: "Mozambique",
  },
  {
    id: 162,
    code: "NA",
    name: "Namibia",
  },
  {
    id: 163,
    code: "NC",
    name: "New Caledonia",
  },
  {
    id: 164,
    code: "NE",
    name: "Niger",
  },
  {
    id: 165,
    code: "NF",
    name: "Norfolk Island",
  },
  {
    id: 166,
    code: "NG",
    name: "Nigeria",
  },
  {
    id: 167,
    code: "NI",
    name: "Nicaragua",
  },
  {
    id: 168,
    code: "NL",
    name: "Netherlands",
  },
  {
    id: 169,
    code: "NO",
    name: "Norway",
  },
  {
    id: 170,
    code: "NP",
    name: "Nepal",
  },
  {
    id: 171,
    code: "NR",
    name: "Nauru",
  },
  {
    id: 172,
    code: "NU",
    name: "Niue",
  },
  {
    id: 173,
    code: "NZ",
    name: "New Zealand",
  },
  {
    id: 174,
    code: "OM",
    name: "Oman",
  },
  {
    id: 175,
    code: "PA",
    name: "Panama",
  },
  {
    id: 176,
    code: "PE",
    name: "Peru",
  },
  {
    id: 177,
    code: "PF",
    name: "French Polynesia",
  },
  {
    id: 178,
    code: "PG",
    name: "Papua New Guinea",
  },
  {
    id: 179,
    code: "PH",
    name: "Philippines",
  },
  {
    id: 180,
    code: "PK",
    name: "Pakistan",
  },
  {
    id: 181,
    code: "PL",
    name: "Poland",
  },
  {
    id: 182,
    code: "PM",
    name: "Saint Pierre and Miquelon",
  },
  {
    id: 183,
    code: "PN",
    name: "Pitcairn",
  },
  {
    id: 184,
    code: "PS",
    name: "Palestine",
  },
  {
    id: 185,
    code: "PT",
    name: "Portugal",
  },
  {
    id: 186,
    code: "PW",
    name: "Palau",
  },
  {
    id: 187,
    code: "PY",
    name: "Paraguay",
  },
  {
    id: 188,
    code: "QA",
    name: "Qatar",
  },
  {
    id: 189,
    code: "RE",
    name: "Reunion",
  },
  {
    id: 190,
    code: "RO",
    name: "Romania",
  },
  {
    id: 191,
    code: "RS",
    name: "Serbia",
  },
  {
    id: 192,
    code: "RU",
    name: "Russia",
  },
  {
    id: 193,
    code: "RW",
    name: "Rwanda",
  },
  {
    id: 194,
    code: "SA",
    name: "Saudi Arabia",
  },
  {
    id: 195,
    code: "SB",
    name: "Solomon Islands",
  },
  {
    id: 196,
    code: "SC",
    name: "Seychelles",
  },
  {
    id: 197,
    code: "SD",
    name: "Sudan",
  },
  {
    id: 198,
    code: "SE",
    name: "Sweden",
  },
  {
    id: 199,
    code: "SG",
    name: "Singapore",
  },
  {
    id: 200,
    code: "SH",
    name: "Saint Helena, Ascension and Tristan Da Cunha",
  },
  {
    id: 201,
    code: "SI",
    name: "Slovenia",
  },
  {
    id: 202,
    code: "SJ",
    name: "Svalbard and Jan Mayen",
  },
  {
    id: 203,
    code: "SK",
    name: "Slovakia",
  },
  {
    id: 204,
    code: "SL",
    name: "Sierra Leone",
  },
  {
    id: 205,
    code: "SM",
    name: "San Marino",
  },
  {
    id: 206,
    code: "SN",
    name: "Senegal",
  },
  {
    id: 207,
    code: "SO",
    name: "Somalia",
  },
  {
    id: 208,
    code: "SR",
    name: "Suriname",
  },
  {
    id: 209,
    code: "SS",
    name: "South Sudan",
  },
  {
    id: 210,
    code: "ST",
    name: "Sao Tome and Principe",
  },
  {
    id: 211,
    code: "SU",
    name: "USSR",
  },
  {
    id: 212,
    code: "SV",
    name: "El Salvador",
  },
  {
    id: 213,
    code: "SY",
    name: "Syria",
  },
  {
    id: 214,
    code: "SZ",
    name: "Eswatini",
  },
  {
    id: 215,
    code: "TD",
    name: "Chad",
  },
  {
    id: 216,
    code: "TG",
    name: "Togo",
  },
  {
    id: 217,
    code: "TH",
    name: "Thailand",
  },
  {
    id: 218,
    code: "TJ",
    name: "Tajikistan",
  },
  {
    id: 219,
    code: "TK",
    name: "Tokelau",
  },
  {
    id: 220,
    code: "TL",
    name: "Timor Leste",
  },
  {
    id: 221,
    code: "TM",
    name: "Turkmenistan",
  },
  {
    id: 222,
    code: "TN",
    name: "Tunisia",
  },
  {
    id: 223,
    code: "TO",
    name: "Tonga",
  },
  {
    id: 224,
    code: "TP",
    name: "East Timor",
  },
  {
    id: 225,
    code: "TR",
    name: "Turkey",
  },
  {
    id: 226,
    code: "TV",
    name: "Tuvalu",
  },
  {
    id: 227,
    code: "TW",
    name: "Taiwan",
  },
  {
    id: 228,
    code: "TZ",
    name: "Tanzania",
  },
  {
    id: 229,
    code: "UA",
    name: "Ukraine",
  },
  {
    id: 230,
    code: "UG",
    name: "Uganda",
  },
  {
    id: 231,
    code: "UY",
    name: "Uruguay",
  },
  {
    id: 232,
    code: "UZ",
    name: "Uzbekistan",
  },
  {
    id: 233,
    code: "VA",
    name: "Vatican City State",
  },
  {
    id: 234,
    code: "VE",
    name: "Venezuela",
  },
  {
    id: 235,
    code: "VN",
    name: "Vietnam",
  },
  {
    id: 236,
    code: "VU",
    name: "Vanuatu",
  },
  {
    id: 237,
    code: "WF",
    name: "Wallis and Futuna",
  },
  {
    id: 238,
    code: "WS",
    name: "Samoa",
  },
  {
    id: 239,
    code: "XK",
    name: "Kosovo",
  },
  {
    id: 240,
    code: "YE",
    name: "Yemen",
  },
  {
    id: 241,
    code: "YT",
    name: "Mayotte",
  },
  {
    id: 242,
    code: "ZA",
    name: "South Africa",
  },
  {
    id: 243,
    code: "ZM",
    name: "Zambia",
  },
  {
    id: 244,
    code: "ZW",
    name: "Zimbabwe",
  },
  {
    id: 245,
    code: "BQ",
    name: "Bonaire, Sint Eustatius and Saba",
  },
  {
    id: 246,
    code: "CC",
    name: "Cocos (Keeling) Islands",
  },
  {
    id: 247,
    code: "CX",
    name: "Christmas Island",
  },
  {
    id: 248,
    code: "CW",
    name: "Curaçao",
  },
  {
    id: 249,
    code: "GS",
    name: "South Georgia and the South Sandwich Islands",
  },
  {
    id: 250,
    code: "SX",
    name: "Sint Maarten (Dutch part)",
  },
  {
    id: 251,
    code: "UM",
    name: "United States Minor Outlying Islands",
  },
  {
    id: 252,
    code: "MT",
    name: "Malta",
  },
  {
    id: 254,
    code: "AQ",
    name: "Antarctica",
  },
  {
    id: 257,
    code: "IO",
    name: "British Indian Ocean Territory",
  },
  {
    id: 260,
    code: "TF",
    name: "French Southern Territories",
  },
  {
    id: 263,
    code: "HM",
    name: "Heard Island and McDonald Islands",
  },
];
