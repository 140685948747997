import authFetch from "../auth_fetch";
import setSelectedProductFilters from "../set_selected_product_filters";

const fetchBidFilters = () => async (dispatch, getState) => {
  const {
    ppl: { selectedProduct },
  } = getState();

  if (!selectedProduct.productId) return;

  const response = await authFetch(
    `/products/${selectedProduct.productId}/filters`,
  );
  if (!response.ok) throw new Error(response.statusText);

  const responseData = await response.json();

  responseData.industries.sort((indA, indB) =>
    indA.name.localeCompare(indB.name),
  );

  dispatch(setSelectedProductFilters(responseData));
};

export default fetchBidFilters;
