import { authAxios } from "~/utils";

const BASE_PATH = "/vp/api/ppl";

export default async function authFetch(url, opts = {}) {
  const { method, body } = opts;

  let res = {};
  try {
    res = await authAxios({ url: BASE_PATH + url, method, data: body });
  } catch (error) {
    res = (error.response && error.response) || {};
  }
  return Promise.resolve({
    ok: res.status >= 200 && res.status < 300,
    json: () => Promise.resolve(res.data),
    data: res.data,
    status: res.status,
  });
}
