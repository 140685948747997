import cx from "classnames";
import PropTypes from "prop-types";
import { Link, useNavigate } from "@remix-run/react";
import s from "../connected/pre_purchase.module.scss";
import { Modal } from "@capterra/arubaito";
import { useTranslation, Trans } from "~/utils";
import { anchorCSS } from "~/components/Text";
import { Button, Stack } from "~/components/vendorUI";
import { css } from "ui/css";

const isOpenType = {
  SUCCESS: "SUCCESS",
  UNAVAILABLE: "UNAVAILABLE",
  ERROR: "ERROR",
  CLOSED: "CLOSED",
};

const PurchaseModal = ({ type, onClosed = () => {}, leadID = "" }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const CLASSES = {
    modal: cx(s.modal, { [s.error]: type === isOpenType.ERROR }),
    header: cx(
      type !== isOpenType.ERROR ? "gdm-subtitle" : "gdm-heading-lg",
      s.header,
    ),
  };

  return (
    <Modal open={type !== isOpenType.CLOSED} onClosed={onClosed}>
      {(close) =>
        type !== isOpenType.CLOSED && (
          <div className={CLASSES.modal}>
            <Modal.Header>
              <h2 className={CLASSES.header}>
                <Trans
                  i18nKey={`PPL_EXPANSION-LEADS_PURCHASE-${type}_TITLE`}
                  components={{
                    icon: (
                      <span className="gdm-icon gdm-icon-alert-error gdm-icon-md gdm-align-bottom gdm-m-right-xs" />
                    ),
                  }}
                />
              </h2>
            </Modal.Header>
            <Modal.Body>
              <p className="gdm-paragraph-sm">
                {t(`PPL_EXPANSION-LEADS_PURCHASE-${type}_DESCRIPTION`)}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Stack align="end">
                <Button onClick={close}>
                  <Link
                    className={css({ textDecoration: "none", color: "white" })}
                    to={
                      type === isOpenType.SUCCESS
                        ? `/ppl/leads/${leadID}`
                        : "/ppl/expansionleads"
                    }
                  >
                    {t(`PPL_EXPANSION-LEADS_PURCHASE-${type}_ACTION`)}
                  </Link>
                </Button>
                {type === isOpenType.SUCCESS && (
                  <button
                    type="button"
                    className={anchorCSS({ variant: "dark" })}
                    variant="quarternary"
                    onClick={() => navigate(-1)}
                  >
                    {t("ACTION_BACK")}
                  </button>
                )}
              </Stack>
            </Modal.Footer>
          </div>
        )
      }
    </Modal>
  );
};

PurchaseModal.propTypes = {
  type: PropTypes.oneOf(Object.values(isOpenType)).isRequired,
  onClosed: PropTypes.func,
  leadID: PropTypes.string, //   only available once you purchase the lead
};

export default PurchaseModal;
