import { SET_SELECTED_MARKET } from "~/pages/PPL/reducers/selected_market_reducer";

export default function setSelectedMarket(market) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_MARKET,
      payload: market,
    });
  };
}
