import axios from "axios";
import { catchError } from "~/utils/catchError";

const saveMyProfile = async (profileData) => {
  try {
    const requestBody = JSON.stringify(profileData);

    await axios.put("/api/users/me", requestBody);
    return true;
  } catch (err) {
    const errorMessage = `MyProfile: ${err.response.status} - ${err.response.data.errors}`;
    catchError(err, { reason: errorMessage });

    throw new Error(errorMessage);
  }
};

export default saveMyProfile;
