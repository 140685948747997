import { RESET_VENDOR } from "./vendor_reducer";

export const SET_EXP_LEAD_DETAILS = "SET_EXP_LEAD_DETAILS";

const expansionLeadReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_EXP_LEAD_DETAILS:
      const { expansionLeadID, details } = action.payload;
      return { ...state, [expansionLeadID]: details };
    case RESET_VENDOR:
      return {};
    default:
      return state;
  }
};

export default expansionLeadReducer;
