import { Prompt, useHistory } from "react-router-dom";
import { useConfirmation } from "~/context";

export const NavigationGuard = ({ hasPendingChanges, onConfirm }) => {
  const history = useHistory();
  const confirm = useConfirmation();

  const handleBlockedNavigation = (nextLocation) => {
    confirm({
      open: true,
      type: "error",
    })
      .then(() => onConfirm().then(() => history.push(nextLocation)))
      .catch(() => null);
    return false;
  };

  return <Prompt when={hasPendingChanges} message={handleBlockedNavigation} />;
};
