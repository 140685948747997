import { memo } from "react";
import { Button, Inline, Stack, Text, Drawer } from "~/components/vendorUI";
import { useConfirmation } from "~/context";
import { useTranslation } from "~/utils";
import { css } from "ui/css";
import { anchorCSS } from "../Text";

const drawerHeaderCSS = css({
  padding: "8px",
  height: "106px",
  justifyContent: "center",
});

const DrawerWrapper = ({
  show,
  onCancel,
  onSubmit,
  isLoading,
  dataTrackingId = "",
}) => {
  const confirm = useConfirmation();
  const { t } = useTranslation();

  const cancel = () => {
    confirm({
      open: true,
      type: "error",
    })
      .then(() => onCancel())
      .catch(() => {});
  };

  return (
    <Drawer show={show}>
      <Inline className={drawerHeaderCSS} align="center" distribute="between">
        <Inline gap="16px" align="center">
          <Text size="20px">{t("DRAWER_PENDING_TITLE")}</Text>
          <Text size="14px">{t("DRAWER_PENDING_DESCRIPTION")}</Text>
        </Inline>
        <Stack align="start">
          <Button
            variant="primary"
            onClick={onSubmit}
            disabled={isLoading}
            data-tracking={dataTrackingId}
          >
            {t("DRAWER_ACTION_SAVE")}
          </Button>

          <button
            type="submit"
            onClick={cancel}
            disabled={isLoading}
            className={anchorCSS({ variant: "inverted" })}
          >
            {t("DRAWER_ACTION_CANCEL-BACK")}
          </button>
        </Stack>
      </Inline>
    </Drawer>
  );
};

const MemoizedDrawerWrapper = memo(
  DrawerWrapper,
  (prevProps, nextProps) => nextProps.show === prevProps.show,
);

export default MemoizedDrawerWrapper;
