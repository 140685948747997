import { SET_MARKETS } from "~/pages/PPL/reducers/markets_reducer";
import { SET_SELECTED_MARKET } from "~/pages/PPL/reducers/selected_market_reducer";
import { SET_SELECTED_PRODUCT } from "~/pages/PPL/reducers/selected_product_reducer";
import authFetch from "./auth_fetch";

const marketsSort = (m1, m2) =>
  m1.name.toLowerCase() < m2.name.toLowerCase() ? -1 : 1;

const fetchMarkets =
  (shouldRefresh = false) =>
  async (dispatch, getState) => {
    const {
      ppl: { products, markets },
    } = getState();
    if (!shouldRefresh && markets && markets.length > 0) return markets;

    const response = await authFetch("/markets");
    if (!response.ok) {
      dispatch({
        type: SET_MARKETS,
        payload: [],
      });
      throw new Error(response.statusText);
    }

    const marketList = (await response.json()) || [];
    const filteredMarkets = marketList.filter((m) =>
      products.some((p) => Number(p.marketId) === m.marketId),
    );
    filteredMarkets.sort(marketsSort);

    dispatch({
      type: SET_MARKETS,
      payload: filteredMarkets,
    });

    const activeMarket = filteredMarkets.length > 0 ? filteredMarkets[0] : {};

    dispatch({
      type: SET_SELECTED_MARKET,
      payload: activeMarket,
    });

    const activeProduct = products.find(
      (p) => Number(p.marketId) === Number(activeMarket.marketId),
    );

    dispatch({
      type: SET_SELECTED_PRODUCT,
      payload: activeProduct,
    });

    return null;
  };

export default fetchMarkets;
