import axios from "axios";

const fetchNewAccessToken = async (currentRefreshToken) => {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${currentRefreshToken}`,
    },
  };
  const response = await axios.put("/api/sessions", "", requestOptions);
  const { session } = response.data || {};

  const refresh_token = session?.refresh_token || currentRefreshToken;

  return { ...session, refresh_token };
};

export default fetchNewAccessToken;
