import { createSelector } from "reselect";

export function getAuctionBidsData(selectedBid, auctionBids) {
  return { selectedBid, auctionBids };
}

export default createSelector(
  (state) => state.ppl.selectedBid,
  (state) => state.ppl.auctionBids,
  getAuctionBidsData,
);
