import { startTransition, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Inline, Text } from "~/components/vendorUI";
import { useCurrentUser } from "~/app/shared/contexts/currentUserContext";
import { useCurrentCampaign } from "~/context/CurrentCampaignContext";
import { useCampaignSwitcher } from "~/context/CampaignSwitcher";
import { useTranslation, gtmTracking } from "~/utils";
import { DropdownSwitcher } from "~/components";

export const CampaignSwitcher = () => {
  const { t } = useTranslation();
  const { switcherConfig } = useCurrentCampaign();
  const { updateSelectedResource } = useCurrentUser();
  const location = useLocation();
  const history = useHistory();
  const { isDisabled } = useCampaignSwitcher();

  const changeSelectedCampaign = useCallback(
    (selected) => {
      updateSelectedResource(switcherConfig.type, Number(selected));
      if (
        location.pathname === "/ppc/manage-bids" ||
        location.pathname === "/invoices"
      ) {
        startTransition(() => {
          history.replace({
            search: "",
          });
        });
      }
      if (switcherConfig.type === "ppc") {
        gtmTracking("ppc_payment_campaign_switcher", {
          new_value: switcherConfig?.selected?.name,
        });
      }
    },
    [
      updateSelectedResource,
      switcherConfig.type,
      location.pathname,
      history,
      switcherConfig.selected?.name,
    ],
  );

  if (!switcherConfig.enabled) return null;

  if (switcherConfig.type === "ppl")
    return (
      <SwitcherWrapper>
        <DropdownSwitcher
          items={switcherConfig.options}
          selectedItem={switcherConfig.selected}
          onSelect={({ id }) => changeSelectedCampaign(id)}
          contentAlign="start"
          disabled={isDisabled}
          by="id"
        />
      </SwitcherWrapper>
    );

  const TRANSLATION_KEY_MAP = {
    0: t("CAMPAIGN-SWITCHER_ACTIVE-CAMPAIGNS_LABEL"),
    1: t("CAMPAIGN-SWITCHER_PAUSED-CAMPAIGNS_LABEL"),
    2: t("CAMPAIGN-SWITCHER_DRAFT-CAMPAIGNS_LABEL"),
  };

  const items = switcherConfig.options
    .map((item) => ({
      ...item,
      groupOrder: item.paused ? 1 : item.program === "draft" ? 2 : 0,
    }))
    .sort((i1, i2) => i1.groupOrder - i2.groupOrder)
    .map(({ groupOrder, ...item }) => ({
      ...item,
      groupName: TRANSLATION_KEY_MAP[groupOrder],
    }));

  return (
    <SwitcherWrapper>
      <DropdownSwitcher
        items={items}
        groupByKey={"groupName"}
        selectedItem={switcherConfig.selected}
        onSelect={({ id }) => changeSelectedCampaign(id)}
        contentAlign="start"
        disabled={isDisabled}
        by="id"
      />
    </SwitcherWrapper>
  );
};

const SwitcherWrapper = ({ children }) => {
  const { t } = useTranslation();
  return (
    <Inline
      align="center"
      distribute="end"
      gap="16px"
      id="campaign-switcher-container"
    >
      <Text color="brand" weight="regular" size="14px">
        {t("CAMPAIGN-SWITCHER_CAMPAIGN_LABEL")}
      </Text>
      {children}
    </Inline>
  );
};

export default CampaignSwitcher;
