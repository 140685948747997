import authFetch from "../auth_fetch";

const fetchExpansionLeads = async () => {
  try {
    const response = await authFetch(`/expansion_leads`);

    if (response.ok) {
      return response.json();
    }
    return { expansion_leads: [] };
  } catch (err) {
    console.error(err);
    return { expansion_leads: [] };
  }
};

export default fetchExpansionLeads;
