import { SET_PRODUCTS } from "~/pages/PPL/reducers/products_reducer";

export default function setProducts(products) {
  return (dispatch) => {
    dispatch({
      type: SET_PRODUCTS,
      payload: products,
    });
  };
}
