import { createSelector } from "reselect";

export function getSelectedProduct(products, productId) {
  return products.find((p) => Number(p.productId) === Number(productId));
}

export default createSelector(
  (state) => state.ppl.products,
  (_, productId) => productId,
  getSelectedProduct,
);
