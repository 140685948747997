import { SET_SELECTED_PRODUCT } from "~/pages/PPL/reducers/selected_product_reducer";

export default function setSelectedProduct(product) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_PRODUCT,
      payload: product,
    });
  };
}
