import {
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
} from "react";
import type { ReactNode } from "react";
import { useCurrentUser } from "~/app/shared/contexts/currentUserContext";
import type {
  CurrentUserHookType,
  SwitcherType,
  SwitcherConfigType,
  CampaignContextType,
} from "./types";

const isTypeValid = (type: SwitcherType) =>
  ["ppc", "ppl"].includes(String(type));

const INITIAL_STATE: SwitcherConfigType = {
  enabled: false,
  type: null,
  options: [],
  selected: null,
};

const CurrentCampaignContext = createContext<CampaignContextType>({
  switcherConfig: INITIAL_STATE,
  enableSwitcher: () => {},
  disableSwitcher: () => {},
});

const CurrentCampaignProvider = ({ children }: { children: ReactNode }) => {
  const [switcherType, setSwitcherType] = useState<SwitcherType>(null);
  const [switcherConfig, setSwitcherConfig] =
    useState<SwitcherConfigType>(INITIAL_STATE);

  const {
    selectedPPCCampaign,
    selectedPPLCampaign,
    visiblePPCCampaigns,
    visiblePPLCampaigns,
  } = useCurrentUser() as CurrentUserHookType;

  const enableSwitcher = (type: SwitcherType) => setSwitcherType(type);
  const disableSwitcher = () => setSwitcherType(null);

  const updateSwitcherConfig = useCallback(
    (type: SwitcherType) => {
      if (isTypeValid(type)) {
        setSwitcherConfig({
          enabled:
            (type === "ppc" && visiblePPCCampaigns.length > 1) ||
            (type === "ppl" && visiblePPLCampaigns.length > 1),
          selected: type === "ppc" ? selectedPPCCampaign : selectedPPLCampaign,
          options: type === "ppc" ? visiblePPCCampaigns : visiblePPLCampaigns,
          type,
        });
      } else {
        setSwitcherConfig(INITIAL_STATE);
      }
    },

    [
      switcherType,
      selectedPPCCampaign?.id,
      selectedPPLCampaign?.id,
      visiblePPCCampaigns,
      visiblePPLCampaigns,
    ],
  );

  useEffect(() => {
    updateSwitcherConfig(switcherType);
  }, [
    switcherType,
    selectedPPCCampaign?.id,
    selectedPPLCampaign?.id,
    visiblePPCCampaigns,
    visiblePPLCampaigns,
  ]);

  return (
    <CurrentCampaignContext.Provider
      value={{ switcherConfig, enableSwitcher, disableSwitcher }}
    >
      {children}
    </CurrentCampaignContext.Provider>
  );
};

const useCurrentCampaign = () => useContext(CurrentCampaignContext);

export { useCurrentCampaign, CurrentCampaignProvider };
