import authFetch from "../auth_fetch";

const purchaseExpansionLead = async (leadID) => {
  try {
    const response = await authFetch(`/expansion_leads/${leadID}/purchase`, {
      method: "PATCH",
    });

    if (response.ok) {
      return {
        errors: [],
        data: await response.json(),
      };
    }
    return {
      errors: Array.isArray(response.data.errors)
        ? response.data.errors
        : ["Unexpected Error"],
      data: {},
      isInvalid: response.status === 400,
    };
  } catch (err) {
    console.error(err);
    return {
      errors: ["Unexpected Error"],
      data: {},
    };
  }
};

export default purchaseExpansionLead;
