import cloneDeep from "lodash.clonedeep";

const REGIONS = [
  {
    id: 23,
    name: "Top Locations",
  },
  {
    id: 4,
    name: "Asia",
  },
  {
    id: 3,
    name: "Europe",
  },
  {
    id: 6,
    name: "Africa",
  },
  {
    id: 9,
    name: "Oceania",
  },
  {
    id: 21,
    name: "North America",
  },
  {
    id: 22,
    name: "South America",
  },
];

const regionByIDs = REGIONS.reduce((total, curr) => {
  curr.countries = [];
  curr.id = String(curr.id);
  total[curr.id] = curr;
  return total;
}, {});

function groupCountries(countries) {
  const regionByID1 = cloneDeep(regionByIDs);
  countries.forEach(({ id, name, is_active, region_ids }) => {
    region_ids.forEach((rid) => {
      const temp = regionByID1[rid];
      temp.countries = [
        ...temp.countries,
        {
          id: String(id),
          name,
          isActive: is_active,
          otherParentIDs: region_ids
            .filter((curId) => curId !== rid)
            .map((r) => String(r)),
        },
      ];
    });
  });

  return Object.values(regionByID1).sort(
    (c1, c2) => Number(c2.id) - Number(c1.id),
  );
}

export default groupCountries;
