/* istanbul ignore file */
import { Suspense, lazy } from "react";
import { useCurrentUser } from "~/app/shared/contexts/currentUserContext";
import { LoadingOverlay } from "~/components";
import { useUserData } from "~/context/UserData";
const UpgradedClickReport = lazy(() => import("./ClickReport"));
const BasicClickReport = lazy(() => import("./BasicClickReport"));

export const ClickReport = () => {
  const { selectedPPCCampaign } = useCurrentUser();
  const { campaignSummary } = useUserData();

  if (!campaignSummary) return <LoadingOverlay />;

  return (
    <Suspense fallback={<LoadingOverlay />}>
      {selectedPPCCampaign?.program === "upgraded" ||
      campaignSummary.formerlyUpgraded ? (
        <UpgradedClickReport />
      ) : (
        <BasicClickReport />
      )}
    </Suspense>
  );
};
