import { RESET_VENDOR } from "./vendor_reducer";

export const SET_LEADS_LIST = "SET_LEADS_LIST";

const leadsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_LEADS_LIST:
      return action.payload;
    case RESET_VENDOR:
      return [];
    default:
      return state;
  }
};
export default leadsReducer;
