import { Redirect } from "react-router-dom";

type RenderIfProps = {
  condition: boolean;
  children?: React.ReactNode;
  elseRedirectTo404?: boolean;
  runOnFail?: () => React.ReactNode;
};

const RenderIf = ({
  children = <></>,
  condition = false,
  elseRedirectTo404 = false,
  runOnFail = () => null,
}: RenderIfProps) => {
  if (condition) return <>{children}</>;
  if (elseRedirectTo404) return <Redirect to="/404" />;
  if (typeof runOnFail === "function") return runOnFail();
  return null;
};

export { RenderIf };
