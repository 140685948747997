import { useEffect, useMemo, useReducer } from "react";
import { LoadingOverlay } from "~/components";
import { ConfirmationServiceProvider } from "~/context";
import { useCurrentUser } from "~/app/shared/contexts/currentUserContext";
import saveMyProfile from "./actions/save_my_profile";
import { AlertModal, Drawer } from "~/components";
import ProfileForm from "./components/ProfileForm";
import PasswordForm from "./components/PasswordForm";
import myProfileReducer, {
  INITIAL_STATE,
  INITIALIZE,
  CHANGE_FIELD,
  CLEAN,
  SET_ALERT,
  SET_ERROR,
  SET_LOADING,
} from "./reducers/my_profile_reducer";
import {
  TIMEZONES_OPTIONS,
  checkPasswordValidity,
  getDepartmentIds,
  getJobLevelHash,
  getTimeZoneHash,
} from "./utils/my_profile_utils";
import {
  CURRENT_PASSWORD_ERROR_MATCHER,
  DEPARTMENT,
  FIELD_NAMES,
  PASSWORD_FIELD_MATCHER,
  REQUIRED_PROFILE_FIELDS,
  ERROR_ALERT_TYPE,
  SUCCESS_ALERT_TYPE,
} from "./config/constants";
import { MESSAGES, TITLES } from "./config/copy";
import { useTranslation } from "~/utils";

const MyProfile = () => {
  const { t } = useTranslation();

  const {
    user: { email, profile },
    updateProfile,
  } = useCurrentUser();

  const [form, formDispatch] = useReducer(myProfileReducer, INITIAL_STATE);
  const {
    firstName,
    lastName,
    jobLevel,
    timezone,
    departments,
    customDepartment,
    currentPassword,
    newPassword,
    confirmPassword,
    alert,
    isLoading,
    hasChanged,
  } = form;

  useEffect(() => {
    if (profile && Object.keys(profile).length > 0) {
      const initialProfile = {
        firstName: profile?.firstName || "",
        lastName: profile?.lastName || "",
        jobLevel: getJobLevelHash(profile?.jobLevel),
        timezone: getTimeZoneHash(profile?.timeZone),
        departments: getDepartmentIds(profile?.departments),
        customDepartment: profile?.customDepartment || "",
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      };

      formDispatch({ type: INITIALIZE, initialProfile });
    }
  }, [profile]);

  const errorKeys = useMemo(() => {
    const keys = Object.keys(form);
    return keys.filter(
      (key) => typeof form[key] === "object" && form[key].error,
    );
  }, [form]);

  const selectedDepartments = useMemo(
    () =>
      departments.current &&
      departments.current.map((si) => DEPARTMENT[si - 1].value),
    [departments],
  );

  const isFormValid = useMemo(() => {
    const isProfileValid =
      firstName.current.length > 0 &&
      lastName.current.length > 0 &&
      Object.keys(jobLevel.current).length > 0 &&
      selectedDepartments.length > 0 &&
      errorKeys.length === 0;

    const isPasswordValid =
      newPassword.current.length > 0
        ? confirmPassword.current.length > 0 &&
          currentPassword.current.length > 0 &&
          newPassword.current === confirmPassword.current &&
          checkPasswordValidity(newPassword.current)
        : true;

    return isProfileValid && isPasswordValid;
  }, [form, errorKeys]);

  const getErrorMessage = (error) => {
    const hasCurrentPasswordError =
      (error.message || "")
        .toLowerCase()
        .indexOf(CURRENT_PASSWORD_ERROR_MATCHER) >= 0;

    if (hasCurrentPasswordError)
      return `${t(MESSAGES.profileUpdateError)}: ${t(
        MESSAGES.incorrectCurrentPassword,
      )}`;

    return t(MESSAGES.profileUpdateError);
  };

  const hasError = (formKey) => errorKeys.includes(formKey);

  const handleBlur = (e) => {
    const { name, value } = e.target;

    if (value.length === 0 && name.indexOf(PASSWORD_FIELD_MATCHER) === -1) {
      formDispatch({ type: SET_ERROR, field: name, error: false });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    formDispatch({ type: CHANGE_FIELD, field: name, value });

    if (value.length === 0 && REQUIRED_PROFILE_FIELDS.includes(name)) {
      formDispatch({ type: SET_ERROR, field: name, error: true });
    } else {
      formDispatch({ type: SET_ERROR, field: name, error: false });
    }
  };

  const handleListChange = (field, value) => {
    formDispatch({
      type: CHANGE_FIELD,
      field,
      value,
    });
  };

  const handleFormReset = () => {
    formDispatch({ type: SET_LOADING, isLoading: true });
    formDispatch({ type: CLEAN });
    formDispatch({ type: SET_LOADING, isLoading: false });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;

    if (isFormValid) {
      const submitPayload = {
        user: {
          first_name: firstName.current,
          last_name: lastName.current,
          current_password: currentPassword.current,
          new_password: newPassword.current,
          profile: {
            time_zone: timezone.current?.value,
            job_level: jobLevel.current?.value,
            departments: selectedDepartments,
            custom_department: customDepartment.current,
            language: profile.language,
          },
        },
      };

      formDispatch({ type: SET_LOADING, isLoading: true });
      try {
        await saveMyProfile(submitPayload);
        formDispatch({ type: SET_LOADING, isLoading: false });
        formDispatch({
          type: CHANGE_FIELD,
          field: FIELD_NAMES.currentPassword,
          value: "",
        });
        formDispatch({
          type: CHANGE_FIELD,
          field: FIELD_NAMES.newPassword,
          value: "",
        });
        formDispatch({
          type: CHANGE_FIELD,
          field: FIELD_NAMES.confirmPassword,
          value: "",
        });
        formDispatch({
          type: SET_ALERT,
          visible: true,
          alertType: SUCCESS_ALERT_TYPE,
          message: t(MESSAGES.profileUpdateSuccess),
        });

        updateProfile({
          firstName: submitPayload.user.first_name,
          lastName: submitPayload.user.last_name,
          departments: submitPayload.user.profile.departments,
          customDepartment: submitPayload.user.profile.custom_department,
          timeZone: submitPayload.user.profile.time_zone,
          jobLevel: submitPayload.user.profile.job_level,
        });
      } catch (e) {
        formDispatch({ type: SET_LOADING, isLoading: false });
        formDispatch({
          type: SET_ALERT,
          visible: true,
          alertType: ERROR_ALERT_TYPE,
          message: getErrorMessage(e),
        });
      }
    } else {
      formDispatch({
        type: SET_ALERT,
        visible: true,
        alertType: ERROR_ALERT_TYPE,
        message: t(MESSAGES.validationError),
      });
    }
  };

  return (
    <ConfirmationServiceProvider>
      <div className="gdm-grid">
        {isLoading && <LoadingOverlay />}
        <h1 className="gdm-title gdm-m-bottom-lg">{t(TITLES.myProfile)}</h1>
        <p className="gdm-heading-lg gdm-m-bottom-md">{email}</p>
        <AlertModal
          open={alert.visible}
          closeOnBackdropClick={alert.type !== ERROR_ALERT_TYPE}
          type={alert.type}
          errorTitle={t("ALERT-TITLE_ERROR")}
          errorMessage={alert.message}
          successTitle={t("ALERT-TITLE_SUCCESS")}
          successMessage={alert.message}
          onAccept={() =>
            formDispatch({
              type: SET_ALERT,
              visible: false,
              alertType: "",
              message: "",
            })
          }
        />
        <ProfileForm
          firstName={firstName.current || ""}
          lastName={lastName.current || ""}
          jobLevel={jobLevel.current || ""}
          timezone={timezone.current || ""}
          timezoneOptions={TIMEZONES_OPTIONS}
          selectedDepartments={departments.current || []}
          customDepartment={customDepartment.current || ""}
          handleChange={handleChange}
          handleListChange={handleListChange}
          hasError={hasError}
          handleBlur={handleBlur}
          isLoading={isLoading}
        />
        <hr />
        <PasswordForm
          currentPassword={currentPassword.current || ""}
          newPassword={newPassword.current || ""}
          confirmPassword={confirmPassword.current || ""}
          handleChange={handleChange}
          hasError={hasError}
          handleBlur={handleBlur}
          dispatch={formDispatch}
        />
        <Drawer
          show={hasChanged}
          onCancel={handleFormReset}
          onSubmit={handleFormSubmit}
          isSubmitting={isLoading}
        />
      </div>
    </ConfirmationServiceProvider>
  );
};

export default MyProfile;
