import saveChangedSizeBid from "~/pages/PPL/actions/edit_bids/save_changed_size_bid";
import saveChangedSegmentBid from "~/pages/PPL/actions/edit_bids/save_changed_segment_bid";
import fetchProductsSizes from "~/pages/PPL/actions/fetch_products_sizes";
import fetchSegmentsData from "~/pages/PPL/actions/fetch_segments";
import fetchAuctionBidsData from "~/pages/PPL/actions/edit_bids/fetch_auction_bids_data";
import { CLEAR_CHANGED_BID } from "~/pages/PPL/reducers/changed_bids_reducer";
import pushAlert from "~/pages/PPL/actions/edit_bids/push_alert";
import refreshSelectedBid from "~/pages/PPL/actions/edit_bids/refresh_selected_bid";
import setIsLoading from "~/pages/PPL/actions/set_is_loading";
import SaveBidModal from "~/pages/PPL/pages/changed_bids/components/save_bid_modal";
import ErrorBidModal from "~/pages/PPL/pages/changed_bids/components/error_bid_modal";
import { gtmTracking } from "~/utils";

const mapChangedBids = (changedBids = {}) => {
  const changedBidList = Object.values(changedBids);
  const changedSizes = [];
  const changedSegments = [];
  const changedSegmentData = {};

  changedBidList.forEach((bid) => {
    if (bid.type === "size") {
      changedSizes.push({
        bidAmount: bid.newValues.bidAmount,
        sizeId: bid.id,
      });
    } else {
      changedSegments.push({
        bidAmount: bid.newValues.bidAmount,
        segmentId: bid.id,
        sizeId: bid.sizeId,
      });
    }
  });
  /**
   * Iterate over changedSegments which has all the changed segments bids, arrange them in an object
   * i.e., changedSegmentData, with keeping key as sizeId and value as array of changed segment bids.
   */
  changedSegments.forEach((changedSegmentBid) => {
    const bid = {
      bidAmount: changedSegmentBid.bidAmount,
      segmentId: changedSegmentBid.segmentId,
    };
    changedSegmentData[changedSegmentBid.sizeId] = changedSegmentData[
      changedSegmentBid.sizeId
    ]
      ? [...changedSegmentData[changedSegmentBid.sizeId], bid]
      : [bid];
  });

  return {
    changedSizeData: changedSizes.length > 0 ? changedSizes : null,
    changedSegmentData:
      Object.keys(changedSegmentData).length > 0 ? changedSegmentData : null,
  };
};

const saveBids = async (changedSizeData, changedSegmentData, dispatch) => {
  const promiseList = [];
  let isSaveBidSuccess = false;

  if (changedSizeData) {
    promiseList.push(dispatch(saveChangedSizeBid(changedSizeData)));
  }
  if (changedSegmentData) {
    promiseList.push(dispatch(saveChangedSegmentBid(changedSegmentData)));
  }

  try {
    let responseList = await Promise.all(promiseList);
    responseList = responseList.flat();
    isSaveBidSuccess =
      responseList.length > 0
        ? responseList.every((response) => response.ok)
        : false;
  } catch (err) {
    isSaveBidSuccess = false;
  }
  return isSaveBidSuccess;
};

const triggerGTM = (selectedBid) => {
  const { oldValues, newValues } = selectedBid;
  gtmTracking("ppl_bidding_edit_bid", {
    previous_bid: oldValues.bidAmount,
    new_bid: newValues.bidAmount,
    recommended_bid: oldValues.maxCost,
  });
};

const saveChangedBids = (productId) => async (dispatch, getState) => {
  const {
    ppl: { changedBids },
  } = getState();
  const { changedSizeData, changedSegmentData } = mapChangedBids(changedBids);

  try {
    dispatch(setIsLoading(true));
    const isSaveSuccess = await saveBids(
      changedSizeData,
      changedSegmentData,
      dispatch,
    );

    if (isSaveSuccess) {
      Object.values(changedBids).forEach((selectedBid) => {
        triggerGTM(selectedBid);
      });
      /** refresh right side table data after successful size bid change */
      if (changedSizeData) {
        // TODO: after save, show loader on right side table when all-bids api gets called
        dispatch(fetchAuctionBidsData(productId, true));
        await Promise.all([
          dispatch(
            fetchSegmentsData(
              productId,
              changedSizeData.map((s) => s.sizeId),
              true,
            ),
          ),
          dispatch(fetchProductsSizes([productId], true)),
        ]);
      }

      /** fetch segments data if segments got updated  */
      if (changedSegmentData) {
        await dispatch(
          fetchSegmentsData(productId, Object.keys(changedSegmentData), true),
        );
      }

      dispatch(pushAlert(<SaveBidModal />));
      dispatch({ type: CLEAR_CHANGED_BID });
      dispatch(refreshSelectedBid());
      dispatch(setIsLoading(false));
    } else {
      dispatch(pushAlert(<ErrorBidModal />));
      dispatch(setIsLoading(false));
    }
  } catch (e) {
    dispatch(setIsLoading(false));
    console.log(e);
  }
};

export default saveChangedBids;
