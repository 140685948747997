import { css } from "ui/css";

const styles = {
  "modal-wrapper": css({
    border: "4px solid red",
    "& .heading-label": {
      marginLeft: "4px",
      verticalAlign: "text-bottom",
      color: "red",
    },
  }),
  "footer-wrapper": css({
    display: "inline-block",
    textAlign: "center",
  }),
};

export default styles;
