import { SET_SELECTED_BID } from "~/pages/PPL/reducers/selected_bid_reducer";

export default function setSelectedBid(bid) {
  return (dispatch, getState) => {
    const state = getState();
    const payload = {
      type: bid.type,
      productId: bid.productId,
      changedBidKey: bid.changedBidKey,
    };
    if (bid.type === "size") {
      payload.bid = state.ppl.sizes[bid.productId].find(
        (sizeband) => sizeband.id === bid.id,
      );
    } else {
      payload.bid = state.ppl.segments[bid.productId][bid.sizeId].find(
        (segment) => segment.id === bid.id,
      );
      payload.sizeBid = state.ppl.sizes[bid.productId].find(
        (sizeband) => sizeband.id === bid.sizeId,
      );
    }
    dispatch({ type: SET_SELECTED_BID, payload });
  };
}
