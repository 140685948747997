import { SET_SEGMENTS } from "~/pages/PPL/reducers/segments_reducer";
import authFetch from "./auth_fetch";

const fetchSegments =
  (productId, sizeIdList = [], refresh = false) =>
  async (dispatch, getState) => {
    const {
      ppl: { segments, products = [] },
    } = getState();
    const isProductBiddable = (
      products.find((p) => Number(p.productId) === Number(productId)) || {}
    ).advancedBiddingAvailable;

    if (
      !isProductBiddable ||
      (!refresh &&
        sizeIdList.length === 1 &&
        segments[productId] &&
        segments[productId][sizeIdList[0]])
    )
      return null;
    const promiseList = sizeIdList.map((sizeId) =>
      authFetch(`/products/${productId}/sizes/${sizeId}/segments`)
        .then((response) => {
          if (!response.ok) throw new Error(response.statusText);
          return response.json();
        })
        .then((segmentData) => ({
          [sizeId]: segmentData,
        }))
        .catch(() => false),
    );

    try {
      let segmentResponses = await Promise.all(promiseList);
      segmentResponses = segmentResponses.filter(Boolean);
      if (segmentResponses.length === 0) throw new Error();

      const {
        ppl: { segments: newSegments },
      } = getState();

      dispatch({
        type: SET_SEGMENTS,
        payload: {
          ...newSegments,
          [productId]: { ...newSegments[productId], ...segmentResponses[0] },
        },
      });

      return null;
    } catch (error) {
      throw new Error(error);
    }
  };

export default fetchSegments;
