import { memo, useContext } from "react";
import cx from "classnames";
import { useTranslation, gtmTracking } from "~/utils";
import { AccountsContext } from "~/pages/AccountSettings/index";
import { Checkbox, FormControlLabel } from "@capterra/arubaito";
import s from "../../AccountSettings/settings.module.scss";

const PPCOptIns = ({ handleChange, state }) => {
  const { handleTouched } = useContext(AccountsContext);
  const { t } = useTranslation();

  const ALL_OPTINS = [
    { id: 47175, code: "GA", name: t("GETAPP") },
    { id: 3566, code: "SA", name: t("SOFTWAREADVICE") },
  ];

  return (
    <div className={s.w9}>
      <h3 className={cx("gdm-heading-lg gdm-m-bottom-sm", s.h3)}>
        {t("ACCOUNT-SETTINGS_PPC-FORM_OPTINS-TITLE")}
      </h3>
      <p className="gdm-paragraph-sm">
        {t("ACCOUNT-SETTINGS_PPC-FORM_OPTINS-DESCRIPTION")}
      </p>
      <p className="gdm-paragraph-sm">
        {t("ACCOUNT-SETTINGS_PPC-FORM_OPTINS-NOTE")}
      </p>
      {ALL_OPTINS.map(({ id, code, name }) => (
        <div className={s.radioCb} key={id}>
          <FormControlLabel
            label={t("ACCOUNT-SETTINGS_PPC-FORM_OPTINS_CHECKBOX", {
              channel: name,
            })}
            control={
              <Checkbox
                name={name}
                value={code}
                checked={state.includes(code)}
                onChange={() => {
                  handleChange(code);
                  handleTouched("ppcForm", "channels", true);
                  if (!state.includes(code)) {
                    gtmTracking("ppc_payment_update_channels", {
                      selected_channel: code,
                    });
                  }
                }}
              />
            }
          />
        </div>
      ))}
    </div>
  );
};

const MemoizedPPCOptIns = memo(
  PPCOptIns,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.state) === JSON.stringify(nextProps.state),
);

export default MemoizedPPCOptIns;
