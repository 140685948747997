import {
  useContext,
  useReducer,
  createContext,
  useEffect,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { useCurrentUser } from "~/app/shared/contexts/currentUserContext";
import fetchFlashMessages from "~/app/actions/fetch_flash_messages";

const initialAppState = {
  flashMessages: [],
  error: null,
};

export const TYPES = {
  SET_FLASH_MESSAGES: "SET_FLASH_MESSAGES",
  ADD_FLASH_MSG: "ADD_FLASH_MSG",
  REMOVE_FLASH_MSG: "REMOVE_FLASH_MSG", // for timer based flash messages
  SET_FLASH_ERROR: "SET_FLASH_ERROR",
};

const FlashMessageContext = createContext();

const FlashMessageProvider = ({ children }) => {
  const { user, selectedPPCCampaign, selectedPPLCampaign, initialized } =
    useCurrentUser();

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case TYPES.SET_FLASH_MESSAGES: {
        return {
          error: null,
          flashMessages: action.payload,
        };
      }
      case TYPES.ADD_FLASH_MSG: {
        const msg = {
          ...action.payload,
          id: action.payload.id ? action.payload.id : crypto.randomUUID(),
        };
        return {
          error: null,
          flashMessages: [...state.flashMessages, msg],
        };
      }
      case TYPES.REMOVE_FLASH_MSG: {
        return {
          error: null,
          flashMessages: state.flashMessages.filter(
            ({ id }) => id !== action.payload,
          ),
        };
      }
      case TYPES.SET_FLASH_ERROR: {
        return {
          ...state,
          error: action.payload,
        };
      }
      default:
        return state;
    }
  }, initialAppState);

  const setFlashMessages = (messages) => {
    dispatch({
      type: TYPES.SET_FLASH_MESSAGES,
      payload: messages.map((msg) => ({
        ...msg,
        id: msg.id ? msg.id : crypto.randomUUID(),
      })),
    });
  };

  const resetFlashMessages = () =>
    dispatch({ type: TYPES.SET_FLASH_MESSAGES, payload: [] });

  const addFlashMessage = ({ copy, type, id, ...pathKeys }) => {
    if (typeof copy !== "string")
      dispatch({
        type: TYPES.SET_FLASH_ERROR,
        payload: 'You need to pass an object with "copy" as a string',
      });
    if (typeof type !== "string")
      dispatch({
        type: TYPES.SET_FLASH_ERROR,
        payload:
          'You need to pass an object with "type" as a string. Types can be "notification", "success" or "error"',
      });

    const uuid = id || crypto.randomUUID();
    const extendedMessage = { copy, type, id: uuid, ...pathKeys };
    dispatch({
      type: TYPES.ADD_FLASH_MSG,
      payload: extendedMessage,
    });

    return id;
  };

  const removeFlashMessage = (id) => {
    if (typeof id !== "string")
      dispatch({
        type: TYPES.SET_FLASH_ERROR,
        payload:
          'You need to pass the "id" of the message you would like to remove. The "id" is being returned when you call "addMessage"',
      });

    dispatch({ type: TYPES.REMOVE_FLASH_MSG, payload: id });
  };

  const loadFlashMessages = () => fetchFlashMessages().then(setFlashMessages);

  const messagesCacheId = useMemo(() => {
    if (!initialized) {
      return undefined;
    }

    return `${user.userId}:${user.isLoggedIn}:${selectedPPCCampaign?.id}:${selectedPPLCampaign?.id}`;
  }, [
    user.userId,
    user.isLoggedIn,
    selectedPPCCampaign,
    selectedPPLCampaign,
    initialized,
  ]);

  useEffect(() => {
    if (initialized) {
      loadFlashMessages();
    } else {
      resetFlashMessages();
    }
  }, [messagesCacheId]);

  return (
    <FlashMessageContext.Provider
      value={{
        ...state,
        loadFlashMessages,
        resetFlashMessages,
        addFlashMessage,
        removeFlashMessage,
      }}
    >
      {children}
    </FlashMessageContext.Provider>
  );
};

const useFlashMessage = () => useContext(FlashMessageContext);

export { useFlashMessage, FlashMessageProvider };

FlashMessageProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
