/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import HostedFields from "braintree-web/hosted-fields";
import cx from "classnames";
import s from "../../settings.module.scss";

import { LoadingOverlay } from "~/components";
import { useTranslation } from "~/utils";
import BillingAddress from "./BillingAddress";
import BraintreeCreditCardForm from "./BraintreeCreditCardForm";
import { StripeCreditCardForm, StripeCreditCardFormModal } from "./Stripe";
import { CARD_TYPES_MAPPINGS } from "../../assets/static";
import { getBraintreeClient } from "../../actions/get_braintree_client";
import { useUserData } from "~/context/UserData";
import { catchError } from "~/utils/catchError";

const PayByCreditCard = ({
  id,
  state,
  handleChange,
  errors,
  handleErrors,
  touched,
  handleTouched,
  creditCardForm,
  handleCreditCard,
  token,
  paymentGateway,
}) => {
  const { t } = useTranslation();
  const [isFormLoading, setIsFormLoading] = useState(true);
  const { campaignSummary } = useUserData();
  const ppcCampaignProfileAttributes =
    campaignSummary?.ppcCampaignProfileAttributes;

  const shouldShowStripeModal = ["IN", "JP"].includes(
    ppcCampaignProfileAttributes?.billingTaxCountryCode,
  );

  const cardTypeOptions = [
    {
      label: t("ACCOUNT-SETTINGS_BILLING-FORM_DROPDOWN_CREDIT-CARD-TYPE-NONE"),
      value: false,
    },
    {
      label: t("ACCOUNT-SETTINGS_BILLING-FORM_DROPDOWN_CREDIT-CARD-TYPE-AMEX"),
      value: "amex",
    },
    {
      label: t(
        "ACCOUNT-SETTINGS_BILLING-FORM_DROPDOWN_CREDIT-CARD-TYPE-MASTERCARD",
      ),
      value: "mc",
    },
    {
      label: t("ACCOUNT-SETTINGS_BILLING-FORM_DROPDOWN_CREDIT-CARD-TYPE-VISA"),
      value: "visa",
    },
    {
      label: t("ACCOUNT-SETTINGS_BILLING-FORM_DROPDOWN_CREDIT-CARD-TYPE-JCB"),
      value: "jcb",
    },
    {
      label: t(
        "ACCOUNT-SETTINGS_BILLING-FORM_DROPDOWN_CREDIT-CARD-TYPE-DISCOVER",
      ),
      value: "discover",
    },
  ];

  useEffect(() => {
    async function initBrainTree() {
      try {
        const { client, card } = await getBraintreeClient(token);

        if (!!card) {
          handleCreditCard("paymentMethod", card);
          handleCreditCard("disableCardEdit", true);
        }
        if (!!client) {
          const hostedFieldsInstance = await HostedFields.create({
            client,
            styles: {
              input: { "font-size": "14px" },
            },
            fields: {
              cardholderName: {
                selector: `.cc${id}Name`,
              },
              number: {
                selector: `.cc${id}Number`,
                placeholder: "4111 1111 1111 1111",
              },
              cvv: { selector: `.cc${id}CVV`, placeholder: "•••" },
              expirationDate: {
                selector: `.cc${id}Expiration`,
                placeholder: "MM / YY",
              },
            },
          });

          hostedFieldsInstance.on("cardTypeChange", ({ cards }) => {
            handleCreditCard(
              "creditCardType",
              cards.length === 1 && CARD_TYPES_MAPPINGS[cards[0].type],
            );
          });

          const validateFields = () => {
            const { fields } = hostedFieldsInstance.getState();
            const formValid = Object.values(fields).every(
              (field) => field.isValid,
            );

            const cardErrors = Object.fromEntries(
              Object.entries(fields).map(([k, v]) => [
                k,
                v.isEmpty || !v.isValid,
              ]),
            );

            handleCreditCard("cardValidation", {
              card_holder_name: cardErrors.cardholderName,
              card_number: cardErrors.number,
              cvv: cardErrors.cvv,
              expiration_date: cardErrors.expirationDate,
            });
            handleCreditCard("saveButtonDisabled", !formValid);
          };

          if (!card) validateFields();
          hostedFieldsInstance.on("validityChange", validateFields);
          handleCreditCard("fieldsInstance", hostedFieldsInstance);
        }
      } catch (err) {
        catchError(err, { reason: "Error initBrainTree" });
      } finally {
        setIsFormLoading(false);
      }
    }

    if (paymentGateway === "braintree") {
      initBrainTree();
    } else {
      setIsFormLoading(false);
    }

    return () => {
      // TODO: cancel calls to braintree
    };
  }, []);

  useEffect(
    () => handleCreditCard("disableCardEdit", !!creditCardForm.paymentMethod),
    [JSON.stringify(creditCardForm.paymentMethod)],
  );

  return (
    <>
      <h3 className={cx("gdm-heading-lg gdm-m-top-lg gdm-m-bottom-sm", s.h3)}>
        {t("ACCOUNT-SETTINGS_BILLING-FORM_ADDRESS_TITLE")}
      </h3>
      <BillingAddress
        id={id}
        handleChange={handleChange}
        state={state}
        errors={errors}
        handleErrors={handleErrors}
        touched={touched}
        handleTouched={handleTouched}
      />
      <h3 className={cx("gdm-heading-lg gdm-m-top-md gdm-m-bottom-sm", s.h3)}>
        {t("ACCOUNT-SETTINGS_BILLING-FORM_CREDIT-CARD_TITLE")}
      </h3>
      <div className="gdm-relative">
        {isFormLoading && <LoadingOverlay fullPage={false} />}
        {paymentGateway === "braintree" && (
          <BraintreeCreditCardForm
            id={id}
            cardTypeOptions={cardTypeOptions}
            savedCCType={
              CARD_TYPES_MAPPINGS[
                creditCardForm.paymentMethod?.details?.cardType
              ] || false
            }
            creditCardForm={creditCardForm}
          />
        )}
        {paymentGateway === "stripe" &&
          (shouldShowStripeModal ? (
            <>
              <hr className={s.separator} />
              <StripeCreditCardFormModal
                cardTypeOptions={cardTypeOptions}
                stripeKey={token}
              />
              <hr className={s.separator} />
            </>
          ) : (
            <StripeCreditCardForm
              cardTypeOptions={cardTypeOptions}
              stripeKey={token}
            />
          ))}
      </div>
    </>
  );
};

export default PayByCreditCard;
