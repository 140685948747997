import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { SHIFT_ALERT } from "~/pages/PPL/reducers/alerts";
import { Modal } from "@capterra/arubaito";
import { Button } from "~/components/vendorUI";

const ContactAdvisorModal = ({ email = "" }) => {
  const dispatch = useDispatch();
  return (
    <Modal open onClosed={() => dispatch({ type: SHIFT_ALERT })}>
      {(close) => (
        <div>
          <Modal.Header>
            <span className="gdm-heading-lg">Contact Your Advisor</span>
            <Modal.Close onClick={close} />
          </Modal.Header>
          <Modal.Body>
            <a href={`mailto: ${email}`} className="gdm-paragraph-lg">
              {email}
            </a>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={close}>
              OK
            </Button>
          </Modal.Footer>
        </div>
      )}
    </Modal>
  );
};

ContactAdvisorModal.propTypes = {
  email: PropTypes.string,
};

export default ContactAdvisorModal;
