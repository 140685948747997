import { axios } from "~/utils/newVendorPortalAxios";

const fetchFlashMessages = async () => {
  try {
    const response = await axios.get("/flash_messages");
    const { flashMessages = [] } = response.data || {};

    return flashMessages;
  } catch (err) {
    return [];
  }
};

export default fetchFlashMessages;
