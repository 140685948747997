import { memo, Fragment } from "react";
import cx from "classnames";
import { InputField } from "@capterra/arubaito";
import { useTranslation } from "~/utils";
import { validateEmail } from "../../utils";
import { Text } from "~/components/vendorUI";
import s from "../../settings.module.scss";

const PayByInvoice = ({
  email,
  handleEmail,
  touched,
  handleTouched,
  errors,
  handleErrors,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Text size="24px" color="brand" weight="medium" asChild>
        <h3>{t("ACCOUNT-SETTINGS_BILLING-FORM_INVOICE_TITLE")}</h3>
      </Text>
      <form className={cx(s.flex, s.form)}>
        <InputField
          status={touched && errors ? "error" : ""}
          className={s.w9}
          render={(id, status) => (
            <Fragment>
              <InputField.Label htmlFor={id}>
                {t("ACCOUNT-SETTINGS_BILLING-FORM_LABEL_EMAIL") + " "}
                <span className="gdm-icon gdm-icon-xs gdm-icon-required" />
              </InputField.Label>
              <InputField.Input
                status={status}
                id={id}
                value={email}
                type="email"
                name="email"
                onChange={(e) => {
                  const { isValid, value } = validateEmail(e.target.value);
                  handleEmail(value);
                  handleErrors(!isValid);
                  handleTouched(true);
                }}
                placeholder="email@email.com"
                onBlur={() => {
                  handleErrors(!validateEmail(email).isValid);
                  handleTouched(true);
                }}
              />
              <InputField.AlertMessage status={status}>
                {t("ERROR_INPUT-MUST-BE-VALID", {
                  input: t("LABEL_EMAIL"),
                })}
              </InputField.AlertMessage>
            </Fragment>
          )}
        />
      </form>
    </div>
  );
};

const MemoizedPayByInvoice = memo(PayByInvoice);

export default MemoizedPayByInvoice;
